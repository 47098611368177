import { useMemo } from 'react';
import { Button } from '@mui/material';
import { Add as AddIcon, GetApp as GetAppIcon } from '@mui/icons-material';
import { waterlineItemReport } from './WaterlineReports';
import { useAppSelector } from '../../../redux/store';
import { selectCorporateView, selectPracticeDetails } from '../../../redux/customer';
import { selectChildPracticeDetails } from '../../../redux/childCustomer';
import constants from '../../../constants';
import { Room, WaterlineItem } from './types';
import WaterlineInventoryTable from './WaterlineInventoryTable';

interface WaterlineInventoryProps {
  inventoryItems: WaterlineItem[];
  rooms: Room[];
  onAlert: (message: string) => void;
  onRefresh: () => Promise<void>;
}

const WaterlineInventory: React.FC<WaterlineInventoryProps> = ({ inventoryItems, rooms, onAlert, onRefresh }) => {
  const isCorpView = useAppSelector(selectCorporateView);
  const practiceDetails = useAppSelector(selectPracticeDetails);
  const childPracticeDetails = useAppSelector(selectChildPracticeDetails);
  const currentPracticeDetails = isCorpView ? childPracticeDetails : practiceDetails;

  const waterlineStraws = useMemo(() => {
    return inventoryItems.filter((item) => {
      return item.subcategory?.toLowerCase() === 'straws';
    });
  }, [inventoryItems]);

  const waterlineTreatment = useMemo(() => {
    return inventoryItems.filter((item) => {
      return item.subcategory?.toLowerCase() === 'treatment' || item.subcategory?.toLowerCase() === 'tablets';
    });
  }, [inventoryItems]);

  const waterlineTesting = useMemo(() => {
    return inventoryItems.filter((item) => {
      return item.subcategory?.toLowerCase() === 'testing' || item.subcategory?.toLowerCase() === 'officetest';
    });
  }, [inventoryItems]);

  return (
    <>
      <div className="flex flex-end m-b-16">
        <Button
          href={`${constants.ONTRAQ_WEB_URL}/webapp/#/practice/auto-renewals`}
          startIcon={<AddIcon />}
          variant="contained"
          style={{ marginRight: '16px' }}
        >
          Enroll in Auto Shipments
        </Button>
        <Button onClick={() => waterlineItemReport(inventoryItems, currentPracticeDetails, true)} startIcon={<GetAppIcon />} variant="contained">
          Export All
        </Button>
      </div>
      {waterlineStraws.length > 0 && <WaterlineInventoryTable customerDetails={currentPracticeDetails} name={"Water Straws"} rooms={rooms} items={waterlineStraws} onAlert={onAlert} onRefresh={onRefresh} />}
      {waterlineTreatment.length > 0 && <WaterlineInventoryTable customerDetails={currentPracticeDetails} name={"Water Treatment"} rooms={rooms} items={waterlineTreatment} onAlert={onAlert} onRefresh={onRefresh} />}
      {waterlineTesting.length > 0 && <WaterlineInventoryTable customerDetails={currentPracticeDetails} name={"Water Testing"} rooms={rooms} items={waterlineTesting} onAlert={onAlert} onRefresh={onRefresh} />}
    </>
  );
};

export default WaterlineInventory;
