import { useEffect, useState, Dispatch, SetStateAction, ChangeEvent } from "react";
import { Box, Checkbox, List, ListItemButton, ListItemIcon, ListItemText, Paper, TextField } from "@mui/material";
import { PickListOption } from "../../../types";

type GroupPickListProps = {
  items: PickListOption[],
  checked: PickListOption[],
  groupChecked: PickListOption[],
  setChecked: Dispatch<SetStateAction<PickListOption[]>>,
  label: string,
  groupName: string,
}

const getListMessage = (label: string, groupName: string, items: PickListOption[], groupChecked: PickListOption[]) => {
  let listMessage = ''

  const selected = groupChecked.length ? `${groupChecked.length} selected of ` : ''
  const practice = items.length === 1 ? 'practice' : 'practices'

  if (label.toLowerCase() === 'available') {
    listMessage = `${selected}${items.length} available ${practice}`
  } else if (label.toLowerCase() === 'members') {
    listMessage = `${selected}${items.length} ${practice} in ${groupName} group`
  }

  return listMessage
}

const getIsChecked = (checked: PickListOption[], option: PickListOption) => {
  const checkedCustomerNumbers = new Set()
  checked.forEach(checkedOption => checkedCustomerNumbers.add(checkedOption.customerNumber))
  return checkedCustomerNumbers.has(option.customerNumber)
}

const GroupPickList = (props: GroupPickListProps) => {
  const { items, checked, setChecked, label, groupChecked, groupName } = props
  const listMessage = getListMessage(label, groupName, items, groupChecked)

  const [search, setSearch] = useState('')
  const [searchItems, setSearchItems] = useState<PickListOption[]>([])

  const handleToggle = (pickListOption: PickListOption) => () => {
    const nextChecked = [...checked]
    const currentIndex = checked.findIndex(checkedOption => {
      return checkedOption.customerNumber === pickListOption.customerNumber
    })
    
    if (currentIndex === -1) {
      nextChecked.push(pickListOption)
    } else {
      nextChecked.splice(currentIndex, 1)
    }
    
    setChecked(nextChecked)
  }

  const handleSearch = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearch(event.target.value)
  }

  useEffect(() => {
    if (search) {
      const nextListItems = items.filter(option => {
        const cleanSearch = search.toLowerCase()
        const cleanValue = option.value.toLowerCase()

        return cleanValue.includes(cleanSearch)
      })

      setSearchItems(nextListItems)
    }
  }, [search, items])

  return (
    <>
      <Box sx={{ width: '100%' }} className="flex flex-space-between flex-align-center">
        <h4>{label}</h4>
        <TextField sx={{ minWidth: 200 }} size="small" label="Search" onChange={handleSearch}/>
      </Box>
      <Paper elevation={1} sx={{ width: '100%', height: 'calc(50vh)', overflow: 'auto' }}>
        <List dense component="div" role="list">
          {
            (search ? searchItems : items).map((option: PickListOption, index) => {
              const labelId = `transfer-list-item-${option.customerNumber}-label`;
              const key = `${index}-${option.customerNumber}`
              const isChecked = getIsChecked(checked, option)

              return (
                <ListItemButton
                  key={key}
                  role="listitem"
                  onClick={handleToggle(option)}
                  disableRipple
                >
                  <ListItemIcon sx={{ minWidth: '32px' }}>
                    <Checkbox
                      checked={isChecked}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{
                        'aria-labelledby': labelId,
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={option.value} />
                </ListItemButton>
              );
            })    
          }
        </List>
      </Paper>
      <div className="f-s-13 m-t-8">
        {listMessage}
      </div>    
    </>
  )
}

export default GroupPickList
