import { Program } from './views/practice/dentalenhancements/types';

export interface ChildCustomerDetails {
  customer_number: string;
  customerService: CustomerService[];
  doctor_name: string;
  email: string;
  name: string;
  phone: string;
  city: string;
  state: string;
  street: string;
  street2: string;
  zip: string;
  customer_osha_hipaa_metadata?: CustomerOshaHipaaMetadata[];
  teamMembers: TeamMember[];
}

export interface ChildPracticeMetadata {
  customer_number: string;
  inactive: boolean;
  parent_customer_number: number;
  name: string;
  city: string;
  state: string;
  street: string;
  zip: string;
  phone: string;
  email: string;                                                                                                                                                                                                                              
  doctor_name: string;
  status: number;
  bio_compliance_message: string;
  bio_compliance_status: number;
  bio_message_short: string;
  bio_message_long: string;
  device_message_short: string;
  device_message_long: string;
  device_compliance_status: number;
  kit_message_short: string;
  kit_message_long: string;
  kit_compliance_status: number;
  mwaste_message_short: string;
  mwaste_message_long: string;
  mwaste_compliance_status: number;
  amalgam_message_short: string;
  amalgam_message_long: string;
  amalgam_compliance_status: number;
  pharma_message_short: string;
  pharma_message_long: string;
  pharma_compliance_status: number;
  waterline_message_short: string;
  waterline_message_long: string;
  waterline_compliance_status: number;
  amalgam_status: number;
  amalgam_available_containers: number;
  amalgam_destroyed_containers: number;
  amalgam_last_destroyed_date: string;
  pharma_status: number;
  pharma_available_containers: number;
  pharma_processed_containers: number;
  pharma_last_processed_date: string;
  mwaste_status: number;
  mwaste_available_containers: number;
  mwaste_destroyed_containers: number;
  mwaste_last_destroyed_date: string;
  medication_status: number;
  last_connection_date: string;
  active_med_kits: number;
  active_device_kits: number;
  device_status: number;
  compliant_aed_devices: number;
  aed_devices: number;
  expired_aed_devices: number;
  expired_devices: number;
  expiring_devices: number;
  expired_meds: number;
  expiring_meds: number;
  bio_last_status: string;
  bio_last_test_date: string;
  bio_num_active_autoclaves: number;
  ot_account: string;
  training_message_short: string;
  training_message_long: string;
  training_compliance_status: number;
  training_past_due_count: number;
  training_due_soon_count: number;
  training_completed_count: number;
  osha_hipaa_message_short: string;
  osha_hipaa_message_long: string;
  osha_hipaa_compliance_status: number;
  members: Members[]
}

export interface Compliance {
  category: string;
  compliance_code: string;
  compliance_status: ComplianceStatus;
  message_long: string;
  message_short: string;
  priority: number;
}

export interface CustomerDetails {
  city: string;
  corporate_parent: boolean;
  customer_number: string;
  customerService: CustomerService[];
  doctor_name: string;
  email: string;
  groups: Groups[];
  name: string;
  phone?: string;
  state: string;
  street: string;
  street2?: string;
  zip: string;
}

export interface CustomerOshaHipaaMetadata {
  id: number;
  compliance_code: string;
  customer_number: string;
  hipaa_compliance_status: ComplianceStatus;
  osha_compliance_status: ComplianceStatus;
  training_compliance_status: ComplianceStatus;
  percentage_complete: number;
  updated_date: string;
  compliance: Compliance;
  year: number;
}

export interface CustomerService {
  id: string;
  isActive: boolean;
  serviceType: string;
  customerNumber: string;
}

export interface CustomerTraining {
  activation_date: string;
  created_date: string;
  customer_number: string;
  expiration_date: string;
  id: number;
  invoice_number: string;
  is_active: boolean;
  training: Training;
  training_id: number;
  updated_date: string;
}

export interface CustomerTrainingMetadata {
  compliance?: Compliance;
  customer_number: string;
  due_soon_count: number;
  id: number;
  past_due_count: string;
  training_compliance_message: string;
  training_compliance_status: number;
}

export interface Group {
  user_id: string;
  group_id: number;
  name: GroupName;
}

export interface Groups {
  group_id: number;
  name: string;
  customer_number: string;
  members: Members[];
}

export interface GroupName {
  group_id: number;
  name: string;
  customer_number: string;
}

export interface Members {
  group_id: number;
  customer_number: string;
}

export interface RefItem {
  category?: string;
  images?: string;
  is_backordered: boolean;
  is_controlled_substance: boolean;
  is_discontinued: boolean;
  is_refillable: boolean;
  is_sds: boolean;
  item_number: string;
  manufacturer_name?: string;
  name: string;
  price: number;
  subcategory?: string;
  type?: string;
}

export interface TeamMember {
  key_: number;
  user_id: string;
  customer_number: string;
  loc_id: number;
  firstname?: string;
  middlename?: string;
  lastname?: string;
  email: string;
  access_level: string;
  account_active: boolean;
  cellphone?: string;
  position?: string;
  login_user_id: string;
  last_connection_date?: string;
  verified: boolean;
  email_verified: boolean;
  groups?: Group[];
}

export interface Training {
  active_period: number;
  ce_credits: number;
  course_id: number;
  created_date: string;
  credit_eligible: boolean;
  days_to_complete: number;
  id: number;
  is_active: boolean;
  item_number: string;
  length: string;
  name: string;
  ref_item?: RefItem;
  userTrainings?: UserTraining[];
  year: number;
}

export interface TrainingInvoiceItem {
  customer_number: string;
  invoice_item_id: number;
  sku: string;
  year: number;
}

export interface UserTraining {
  completion_date: string;
  compliance_status: number;
  course_id: string;
  created_date: string;
  customer_number: string;
  deleted_date: string;
  due_date: string;
  enrollment_date: string;
  id: number;
  is_active: boolean;
  lms_user_id: string;
  start_date: string;
  updated_date: string;
  user_id: string;
  teamMember?: TeamMember;
}

export interface UserTrainingMetadata {
  compliance: Compliance;
  completed_count: number;
  created_date: string;
  customer_number: string;
  due_soon_count: number;
  overall_compliance_status: string;
  past_due_count: number;
  update_date: string;
  user_id: string;
}

export enum ComplianceStatus {
  Complete = 1,
  Ok = 1,
  Passed = 1,
  InProgress = 2,
  Expiring = 2,
  RetestPassed = 2,
  NotStarted = 3,
  Expired = 3,
  Failed = 3,
  NotApplicable = 4,
  Inactive = 4,
  Unassigned = 4
}

declare global {
  interface Window {
    LC_API: {
      open_chat_window: () => void
    }
  }
}

export type OpenGroupAlert = {
  open: boolean,
  type: string,
  message: string,
}

export type PickListOption = {
  customerNumber: string,
  value: string,
}

export type PickList = {
  available: PickListOption[],
  members: PickListOption[],
}

export type OpenGroupModal = {
  open: boolean,
  type: string,
}
