import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, Card, Snackbar, Alert, useTheme, CircularProgress } from "@mui/material"
import { trackPromise, usePromiseTracker } from "react-promise-tracker"
import { selectChildPractices, selectCustomerNumber, selectGroups, getChildPractices } from '../../../redux/customer'
import PageHeader from "../../../components/PageHeader"
import GroupSelect from './GroupSelect'
import NoGroups from './NoGroups'
import GroupTransferWrapper from './GroupTransferWrapper'
import GroupActions from './GroupActions'
import GroupDialogs from './GroupDialogs'
import { Groups, OpenGroupModal, OpenGroupAlert } from '../../../types'
import { useAppDispatch } from "../../../redux/store"
import { ErrorNotify } from '../../../components/Notify.styled'
import './Groups.css'

const GroupsWrapper = () => {
  const [selectedGroup, setSelectedGroup] = useState<Groups | null>(null)
  const [groupId, setGroupId] = useState<number | null>(null)
  const [openAlert, setOpenAlert] = useState<OpenGroupAlert>({ open: false, type: '', message: '' })
  const [openModal, setOpenModal] = useState<OpenGroupModal>({ open: false, type: '' })

  const groups = useSelector(selectGroups)
  const customerNumber = useSelector(selectCustomerNumber)
  const childPractices = useSelector(selectChildPractices)
  
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const { promiseInProgress } = usePromiseTracker()

  const handleAlertClose = () => setOpenAlert({ open: false, type: '', message: '' })

  const subHeaderClass = promiseInProgress
    ? ' flex-center' 
    : selectedGroup ? ' flex-space-between' : ' c-g-16'

  useEffect(() => {
    if (customerNumber && childPractices?.length === 0) {
      trackPromise(dispatch(getChildPractices(customerNumber)))
    }
  }, [customerNumber, childPractices])

  useEffect(() => {
    if (groupId) {
      const group = groups?.find(group => group.group_id === groupId) ?? null
      setSelectedGroup(group)
    }
  }, [groupId, groups])

  return (
    <>
      <PageHeader iconName="users" headerName="Groups" />
      <Box sx={{ width: '100%', p: 2, color: theme.palette.color50555b.main }} className="groups-container">
        <Card sx={{ width: "100%" }} variant="outlined">
          <div className="box-header">Manage Groups</div>
          <div className={`table-subheader${subHeaderClass}`} style={{ flexWrap: 'wrap'}}>
            {
              promiseInProgress ? <CircularProgress size="3rem" /> : null
            }
            {
              !promiseInProgress && customerNumber && groups.length 
                ?
                  <>
                    <GroupSelect
                      groups={groups}
                      groupId={groupId}
                      setGroupId={setGroupId}
                    />
                    <GroupActions
                      setOpenModal={setOpenModal}
                      selectedGroup={selectedGroup}
                    />
                  </>
                : null
            }
            {
              !promiseInProgress && customerNumber && !groups.length 
                ? <NoGroups setOpenModal={setOpenModal} />
                : null
            }
            {
              Boolean(selectedGroup)
                ?
                  <GroupTransferWrapper
                    setOpenAlert={setOpenAlert}
                    selectedGroup={selectedGroup}
                    childPractices={childPractices}
                    customerNumber={customerNumber}
                  />
                : null
            }
          </div>
        </Card>
      </Box>
      <Snackbar
        open={openAlert.open && openAlert.type === 'success'}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert>{openAlert.type === 'success' ? openAlert.message : ''}</Alert>
      </Snackbar>
      <ErrorNotify open={openAlert.open && openAlert.type === 'error'} onClose={handleAlertClose} />
      <GroupDialogs
        openModal={openModal}
        customerNumber={customerNumber}
        selectedGroup={selectedGroup}
        setOpenModal={setOpenModal}
        setOpenAlert={setOpenAlert}
        setGroupId={setGroupId}
        setSelectedGroup={setSelectedGroup}
      />
    </>
  )
}

export default GroupsWrapper