import { Box, Divider, useTheme } from "@mui/material";


const Terms = () => {
  const theme = useTheme();

  return (
    <>
      <Box sx={{ color: theme.palette.color50555b.main }} className="m-16">
        <Box sx={{ fontSize: "45px" }} className="color-primary">OnTraq Software as a Service ("SaaS") Agreement</Box>
        <div className="f-s-13">
          <p>Please read this agreement carefully before using the services. By accessing or using all or any portion of the services or by clicking the "I Accept" button at the end of the agreement, you acknowledge your acceptance of the agreement and represent that you are authorized to enter into the agreement on behalf of customer. You agree that this agreement is enforceable like any written negotiated agreement signed by you. If you do not agree to these terms and conditions, vendor does not grant you the right to use the services and you should click on the "I do not Accept" button and cancel operation of the services.</p>
          <p>The terms and conditions of this agreement apply to any and all use of the services by you and you agree to be bound by these terms and conditions regardless of the type of use of the services by you.</p>
          <p>This OnTraq SaaS Agreement, as amended from time to time, (the "<strong>Agreement</strong>") is between you or the entity you represent ("<strong>Customer</strong>") and HF Acquisition Co. LLC (d/b/a HealthFirst) with its principal place of business at 11629 49th Place West, Mukilteo, WA ("<strong>Vendor</strong>").</p>
          <h2 className="f-s-26 f-w-500 m-b-8">1. Definitions</h2>
          <p className="m-t-0">As used in this Agreement, the following terms shall have the following meaning:</p>
          <p>1.1 "<strong>Content</strong>" means the audio and visual information, documents, software, products, and services made available to Customer in the course of using the Services.</p>
          <p>1.2 "<strong>Customer Data</strong>" means any data, information or material provided, input or submitted by Customer or its Users to the Services in the course of using the Services.</p>
          <p>1.3 "<strong>Effective Date</strong>" means the date upon which the Customer clicks an "I Accept" button at the end of the Agreement or, if earlier, when Customer accesses or uses any of the Services.</p>
          <p>1.4 "<strong>Intellectual Property Rights</strong>" means any unpatented inventions, patent applications, patents, design rights, copyrights, trademarks, service marks, trade names, domain name rights, mask work rights, know-how and other trade secret rights, and all other intellectual property rights, derivatives thereof, and forms of protection of a similar nature anywhere in the world.</p>
          <p>1.5 "<strong>Services</strong>" means Vendor's online tracking software services, developed by Vendor, accessible via <a href="http://www.healthfirst.com/ontraq">OnTraq</a> or ancillary online or offline products and services provided to Customer by Vendor, to which Customer is being granted access under this Agreement, including the Vendor Technology and the Content.</p>
          <p>1.6 "<strong>Third Party Vendor(s)</strong>" means any third party provider of products or services to Customer.</p>
          <p>1.7 "<strong>Third Party Vendor Data</strong>" means any data, information or material provided or submitted to Vendor by a Third Party Vendor.</p>
          <p>1.8 "<strong>User(s)</strong>" means Customer employees, representatives, consultants, contractors, or agents who are authorized to use the Services and have been supplied user identifications and passwords by Customer (or by Vendor at Customer's request).</p>
          <p>1.9 "<strong>Vendor Technology</strong>" means all of Vendor's proprietary technology (including software, hardware, products, processes, algorithms, user interfaces, know-how, techniques, designs, and other tangible or intangible technical material or information) made available to Customer by Vendor in providing the Services.</p>
          <h2 className="f-s-26 f-w-500 m-b-8">2. Use of the Services</h2>
          <p className="m-t-0">2.1 Subject to the terms and conditions of this Agreement, Vendor hereby grants Customer a nonexclusive, nontransferable, nonsublicenseable, revocable, limited right to access and use the Services, solely as part of, and in connection with OnTraq. All rights not expressly granted to Customer herein are reserved by Vendor and its licensors.</p>
          <p>2.2 Customer may not (i) license, sublicense, sell, resell, transfer, assign, distribute, or otherwise commercially exploit or make available to any third party the Services or the Content in any way; (ii) modify, alter, tamper with, repair or make derivative works based upon the Services or the Content; (iii) create Internet "links" to the Services or "frame" or "mirror" any Content on any other server or wireless or Internet-based device; (iv) reverse engineer, disassemble, decompile, translate or reproduce the Services; or (v) access the Services in order to (a) build a competitive product or service, (b) build a product using similar ideas, features, functions or graphics of the Services, or (c) copy any ideas, features, functions or graphics of the Services.</p>
          <p>2.3 Customer may use the Services only as part of, and in connection with OnTraq and shall not: (i) send spam or otherwise duplicative or unsolicited messages in violation of applicable laws; (ii) send or store infringing, obscene, threatening, libelous, or otherwise unlawful or tortious material, including material harmful to children or violative of third party privacy rights; (iii) send or store material containing software viruses, worms, Trojan horses, or other harmful computer code, files, scripts, agents, or programs; (iv) interfere with or disrupt the integrity or performance of the Services or the data contained therein; or (v) attempt to gain unauthorized access to the Services or its related systems or networks.</p>
          <p>2.4 Vendor will not provide any support or services to Customer pursuant to this Agreement.</p>
          <h2 className="f-s-26 f-w-500 m-b-8">3. Customer Responsibilities</h2>
          <p className="m-t-0">3.1 Customer is responsible for all activity occurring under Customer's User accounts and shall abide by all applicable local, state, national, and foreign, laws, treaties and regulations in connection with Customer's use of the Services, including those related to data privacy, data security and the transmission of technical or personal data.</p>
          <p>3.2 Customer is solely responsible for the development, content, operation, maintenance, and use of Customer Data, including but not limited to: (i) the technical operation of Customer Data; (ii) compliance of Customer Data with the law; (iii) any claims relating to Customer Data; and (iv) properly handling and processing notices sent to Customer (or any of Customer's affiliates) by any person claiming that Customer Data violate such person's rights.</p>
          <p>3.3 Customer is responsible for properly using the Services and taking steps to maintain appropriate security, protection and backup of Customer Data, which may include the use of encryption technology to protect Customer Data from unauthorized access and routine archiving Customer Data. Customer shall: (i) safeguard all passwords relating to the Services, keep all such passwords strictly confidential and provide such passwords only to authorized employees or personnel who need to know such information for the purpose of using the Services; (ii) notify Vendor immediately of any unauthorized use of any password or account or any other known or suspected breach of security; (iii) report to Vendor immediately and use reasonable efforts to stop immediately any copying or distribution of Content that is known or suspected by Customer or Customer Users; and (iv) not impersonate another Vendor user or provide false identity information to gain access to or use the Services.</p>
          <p>3.4 Any breach of Customer's obligations under this Agreement will be deemed a material breach.</p>
          <h2 className="f-s-26 f-w-500 m-b-8">4. Account Information and Data</h2>
          <p className="m-t-0">Vendor does not own any Customer Data or Third Party Vendor Data. Customer consents to Vendor's use of Customer Data to provide the Services to Customer and Vendor's use of aggregated Customer Data for marketing and data analysis purposes. Customer, not Vendor, shall have sole responsibility for the accuracy, quality, integrity, legality, reliability, appropriateness, and intellectual property ownership or right to use of all Customer Data or Third Party Vendor Data, and Vendor shall not be responsible or liable for the deletion, correction, destruction, damage, loss or failure to store any Customer Data or Third Party Vendor Data. Customer understands and agrees that Vendor cannot and does not guarantee the Services shall be free of breaches or unauthorized access. To the extent Customer participates in Vendor's Keep Me Current Automatic program (or any similar successor program), Customer shall continue to be notified under such program of any expiration dates of products purchased by Customer from Vendor. Vendor may disclose Customer Data and Third Party Vendor Data to provide the Services to Customer or to comply with any request of a governmental or regulatory body (including subpoenas or court orders), and Vendor may disclose aggregated Customer Data and Third Party Vendor Data for marketing and data analysis purposes. Upon termination, Customer's right to access or use Customer Data through the Services immediately ceases, and Vendor shall have no obligation to maintain or forward any Customer Data and may delete such Customer Data immediately upon termination. Any post-termination data retrieval assistance from Vendor is subject to agreement by Vendor and Customer.</p>
          <h2 className="f-s-26 f-w-500 m-b-8">5. Intellectual Property Ownership</h2>
          <p className="m-t-0">This Agreement is not a sale and does not convey to Customer any rights of ownership in or related to the Content, the Services, the Vendor Technology or the Intellectual Property Rights owned by Vendor. Vendor's name, Vendor's logo, and the product names associated with the Services are trademarks of Vendor or third parties (collectively, the "<strong>Vendor Trademarks</strong>"), Customer agrees not to use the Vendor Trademarks for any purpose or to take any actions which are harmful to or inconsistent with Vendor's rights in and to the Vendor Trademarks. Customer acknowledges and agrees that Vendor (and its licensors, where applicable) shall exclusively own and retain all right, title, and interest, including but not limited to all Intellectual Property Rights, in and to the Vendor Technology, the Content and the Services and other suggestions, ideas, enhancement requests, feedback, recommendations, and other information provided by Customer or any other party relating to the Services. To the extent any Intellectual Property Rights in or to any of the foregoing vest in Customer, Customer does hereby assign all right, title and interest therein to Vendor, irrevocably and for valid consideration, which is hereby acknowledged and received.</p>
          <h2 className="f-s-26 f-w-500 m-b-8">6. Third Party Interactions</h2>
          <p className="m-t-0">6.1 During use of the Services, Customer may enter into correspondence with, purchase goods and/or services from, or participate in promotions of Third Party Vendors showing their goods and/or services through the Services. Any such activity, and any terms, conditions, warranties, or representations associated with such activity, is solely between Customer and the applicable Third Party Vendor. Vendor shall have no liability, obligation, or responsibility for any such correspondence, purchase, or promotion between Customer and any such Third Party Vendor. Customer shall have sole responsibility for the accuracy, quality, integrity, legality, reliability, appropriateness, and intellectual property ownership or right to use of all Third Party Vendor Data, and Vendor shall not be responsible or liable for the deletion, correction, destruction, damage, loss or failure to store any Third Party Vendor Data. Vendor does not endorse any sites on the Internet that are linked through the Services. Vendor provides these links to Customer only as a matter of convenience, and in no event shall Vendor or its licensors be responsible for any content, products, or other materials on or available from such sites. Vendor provides the Services to Customer pursuant to the terms and conditions of this Agreement. Customer recognizes, however, that certain Third Party Vendors may provide ancillary software, hardware, or services that may require Customer's agreement to additional or different license or other terms prior to Customer's use of or access to such software, hardware or services.</p>
          <p>6.2 A portion of the Services may be provided by a third party. Vendor will not be responsible for the actions or omissions of any third party.</p>
          <h2 className="f-s-26 f-w-500 m-b-8">7. Term; Termination</h2>
          <p className="m-t-0">This Agreement may be terminated at any time by Vendor. Vendor, in its sole discretion, may terminate Customer's password, account or use of the Services at any time. Customer agrees and acknowledges that Vendor has no obligation to retain the Customer Data, and may delete such Customer Data immediately upon termination. Any post-termination data retrieval assistance from Vendor is subject to mutual agreement between Vendor and Customer; provided, however, the parties are in no way obligated to come to such an agreement.</p>
          <h2 className="f-s-26 f-w-500 m-b-8">8. Confidentiality</h2>
          <p className="m-t-0">The Services and the Content are confidential and proprietary to Vendor and its licensor's. Customer agrees not to disclose any part of the Services, the Content or any information relating to the Services to any third party without Vendor's prior written permission. Customer acknowledges and agrees that unauthorized use or disclosure of such information would cause irreparable harm and significant injury to Vendor that would be difficult to ascertain; accordingly Customer agrees that Vendor shall have the right to obtain injunctive or other equitable relief to enforce obligations under this Agreement without limiting any other rights or remedies.</p>
          <h2 className="f-s-26 f-w-500 m-b-8">9. Representations and Warranties</h2>
          <p className="m-t-0">Each party represents and warrants that it has the legal power and authority to enter into this Agreement.</p>
          <h2 className="f-s-26 f-w-500 m-b-8">10. Indemnification</h2>
          <p className="m-t-0">Customer will defend, indemnify, and hold harmless Vendor, Vendor's affiliates and licensors, and each of their respective employees, officers, directors, and representatives from and against any claims, damages, losses, liabilities, costs, and expenses (including reasonable attorneys fees) arising out of or relating to any third party claim concerning: (a) Customer's or any User's use of the Services; (b) breach of this Agreement or violation of applicable law by Customer or any User; or (c) allegations that the use of the Customer Data or Third Party Vendor Data infringes the rights of, or has caused harm to, a third party. If Vendor or Vendor's affiliates are obligated to respond to a third party subpoena or other compulsory legal order or process described above, Customer will also reimburse Vendor for reasonable attorneys fees, as well as our employees and contractors time and materials spent responding to the third party subpoena or other compulsory legal order or process.</p>
          <h2 className="f-s-26 f-w-500 m-b-8">11. Disclaimer of Warranties</h2>
          <p className="m-t-0">11.1 Vendor does not support the services or the content. Vendor and its licensors make no representation, warranty, or guaranty as to the reliability, timeliness, quality, suitability, availability, accuracy, or completeness of the services or any content. The services and all content are provided to customer strictly on an "as is" basis. All conditions, representations and warranties, whether express, implied, statutory or otherwise, including, without limitation, any implied warranty of merchantability, fitness for a particular purpose, and non-infringement of third party rights, are hereby disclaimed to the maximum extent permitted by applicable law by vendor and its licensors.</p>
          <p>11.2 Licensee acknowledges and agrees that: (a) the services are not in final form, have not been released for sale by the company and may contain defects, errors, design flaws or other problems which cannot or will not be corrected; (b) the use of the services may not be secure, timely, uninterrupted, or error-free or operate in combination with any other hardware, software, system, or data; (c) any stored data may not be accurate or reliable; (d) the services or the quality of any products, services, information, or other material purchased or obtained by customer through the services may not meet customer's requirements or expectations; (e) the services or the server(s) that make the services available may not be free of viruses or other harmful components; or (f) the services may be subject to limitations, delays, and other problems inherent in the use of the internet and electronic communications.</p>
          <h2 className="f-s-26 f-w-500 m-b-8">12. Limitation of Liability</h2>
          <p className="m-t-0">in no event shall vendor or vendor's licensors be liable for any damages whatsoever, including but not limited to any direct, indirect, punitive, special, exemplary, incidental, consequential, or other damages of any type or kind arising out of or in any way connected with (a) this agreement; (b) the services, including but not limited to the use or inability to use the services; (c) the content; or (d), any interruption, inaccuracy, error, or omission, regardless of cause in the services or content; even if vendor or vendor's licensors have been previously advised of the possibility of such damages.</p>
          <h2 className="f-s-26 f-w-500 m-b-8">13. Territory/No Export</h2>
          <p className="m-t-0">13.1 Customer acknowledges and agrees that the software and Services shall not be used, and none of the underlying information may be transferred or otherwise exported outside the United States (the "<strong>Territory</strong>"). By using the Services, Customer represents and warrants that Customer's use of the Services is located solely in the Territory.</p>
          <p>13.2 Vendor and its licensors make no representation that the Services are appropriate or available for use outside of the Territory. Any use of the Services outside of the Territory or diversion of the Services, Content or Customer Data contrary to United States law is prohibited. Customer is solely responsible for compliance with all applicable laws, including without limitation export and import regulations of other countries.</p>
          <h2 className="f-s-26 f-w-500 m-b-8">14. Modification to Terms</h2>
          <p className="m-t-0">Vendor reserves the right to modify the terms and conditions of this Agreement or its policies relating to the Services at any time, effective upon posting of an updated version of this Agreement on the Services. Customer is responsible for regularly reviewing this Agreement. Continued use of the Services after any such changes shall constitute Customer's consent to such changes.</p>
          <h2 className="f-s-26 f-w-500 m-b-8">15. Assignment</h2>
          <p className="m-t-0">This Agreement may not be assigned by Customer without the prior written approval of Vendor.</p>
          <h2 className="f-s-26 f-w-500 m-b-8">16. General</h2>
          <p className="m-t-0">16.1 This Agreement shall be governed by the laws of the State of New York, without reference to conflict of laws principles. The parties irrevocably submit to the jurisdiction of the federal courts sitting in the Eastern and Southern Districts of New York or any New York state courts in the counties of Nassau and Suffolk, for the purpose of any suit, action or proceeding arising out of this Agreement. The parties hereby irrevocably waive the defense of an inconvenient forum to the maintenance of any such suit, action or proceeding.</p>
          <p>16.2 Vendor may provide Customer with notice, including notice regarding changes to the terms of this Agreement, by email, by regular mail or by postings on the Services. Notices provided by Vendor by posting on the Services will be effective upon posting, and notices sent by email or regular mail will be effective when sent by Vendor. Customer may give notice to Vendor at any time by personal delivery, overnight courier or registered or certified mail delivered to Vendor at the following address: Attention General Counsel, Henry Schein, Inc., 135 Duryea Road, Melville, NY 11747. Notices provided by Customer shall be deemed effective when received by Vendor.</p>
          <p>16.3 No text or information set forth on any other document shall add to or vary the terms and conditions of this Agreement. If any provision of this Agreement is held by a court of competent jurisdiction to be invalid or unenforceable, then such provision(s) shall be construed, as nearly as possible, to reflect the intentions of the invalid or unenforceable provision(s), with all other provisions remaining in full force and effect. No joint venture, partnership, employment, or agency relationship exists between Customer and Vendor as a result of this Agreement or the use of the Services. The failure of Vendor to enforce any right or provision in this Agreement shall not constitute a waiver of such right or provision unless acknowledged and agreed to by Vendor in writing. This Agreement comprises the entire agreement between Customer and Vendor and supersedes all prior or contemporaneous negotiations, discussions or agreements, whether written or oral, between the parties regarding the subject matter contained herein.</p>
        </div>
      </Box>
    </>
  )
}

export default Terms;