import { ButtonGroup, Button, Box } from "@mui/material"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Dispatch, SetStateAction } from "react"
import { Groups, OpenGroupModal } from "../../../types"

type GroupActionProps = {
  setOpenModal: Dispatch<SetStateAction<OpenGroupModal>>,
  selectedGroup: Groups | null,
}

const GroupActions = (props: GroupActionProps) => {
  const { setOpenModal, selectedGroup } = props

  const handleOpenCreate = () => {
    setOpenModal({ open: true, type: 'create' })
  }

  const handleOpenEdit = () => {
    setOpenModal({ open: true, type: 'edit' })
  }

  const handleOpenDelete = () => {
    setOpenModal({ open: true, type: 'delete' })
  }

  const EditButton = () => {
    return (
      <Button onClick={handleOpenEdit}>
        <FontAwesomeIcon icon="pen" size="1x" />
      </Button>
    )
  }

  const DeleteButton = () => {
    return (
      <Button onClick={handleOpenDelete}>
        <FontAwesomeIcon icon="trash-can" size="1x" />
      </Button>
    )
  }

  const AddButton = () => {
    return (
      <Button onClick={handleOpenCreate}>
        <FontAwesomeIcon icon="plus" size="1x" />
      </Button>
    )
  }

  const buttons = selectedGroup
  ? [EditButton, DeleteButton, AddButton]
  : [AddButton]

  return (
    <>
      <Box>
        <ButtonGroup variant="contained" size="large">
          {
            buttons.map((GroupButton, index) => {
              return <GroupButton key={index} />
            })
          }
        </ButtonGroup>
      </Box>
    </>
  )
}

export default GroupActions
