import axios from 'axios';
import constants from '../constants';
import { PracticeInfo, Program, TrainingChecklist, TrainingProgramForm, TrainingProgramFormExemption, TrainingProgramFormUserPayload } from '../views/practice/dentalenhancements/types';
import { CustomerOshaHipaaMetadata } from '../types';

const getProgramsAndChecklists = async (
  customerNumber: string
): Promise<{
  training_checklists: TrainingChecklist[];
  training_programs: Program[];
  training_program_form_exemptions: TrainingProgramFormExemption[];
  customer_osha_hipaa_metadata: CustomerOshaHipaaMetadata[];
  practice_info?: PracticeInfo;
  training_program_forms: TrainingProgramForm[];
}> => {
  return await axios
    .get(`${constants.ONTRAQ_API_URL}/api/Customers/${customerNumber}?filter={"meta":["training_programs","training_checklists","training_program_forms", "training_program_form_exemptions"], "include": [{"relation": "customer_osha_hipaa_metadata"}]}`)
    .then((res) => {
      const {
        training_checklists,
        training_programs,
        training_program_forms,
        training_program_form_exemptions,
        customer_osha_hipaa_metadata,
        name,
        doctor_name,
        street,
        street2,
        city,
        state,
        zip,
        phone
      } = res.data;

      return {
        training_checklists,
        training_programs,
        training_program_form_exemptions,
        customer_osha_hipaa_metadata,
        training_program_forms,
        practice_info: { name, doctor_name, street, street2, city, state, zip, phone }
      };
    })
    .catch((error) => {
      console.error(error);
      return { training_checklists: [], training_programs: [], training_program_forms: [], customer_osha_hipaa_metadata: [], training_program_form_exemptions: [] };
    });
};

const getTaskCompliances = async (customerNumber: string) => {
  return await axios
    .get(`${constants.ONTRAQ_API_URL}/api/Customers/${customerNumber}/trainingCompliance/checklistTasks`)
    .then((res) => {
      const training_checklist_task_compliance = res.data;

      return training_checklist_task_compliance;
    })
    .catch((error) => {
      console.error(error);
      return [];
    });
};

const setTaskMetadata = async (customerNumber: string, task_id: number, status: number) => {
  const payload = {
    checklistTaskId: task_id,
    status
  };
  return await axios
    .post(`${constants.ONTRAQ_API_URL}/api/Customers/${customerNumber}/trainingCompliance/checklistTasks/${task_id}`, { ...payload })
    .then((res) => {
      const data = res.data;

      return data;
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
};

const setCustomerNotesForTask = async (customerNumber: string, taskId: number, notes: string) => {
  const url = `${constants.ONTRAQ_API_URL}/api/Customers/${customerNumber}/checklistTaskNotes/${taskId}`;
  const payload = { notes };

  try {
    const { data } = await axios.post(url, payload);
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

const deleteUploadedForm = async (formId: number | undefined, customerNumber: string): Promise<{ training_program_forms: TrainingProgramForm[] }> => {
  try {
    const res = await axios.delete(`${constants.ONTRAQ_API_URL}/api2/training-program-forms/${formId}/uploaded-form`, {
      data: {
        customer_number: customerNumber
      }
    });
    const { training_program_forms } = res.data;
    return { training_program_forms };
  } catch(error) {
    console.error(error);
    return { training_program_forms: [] };
  }
}

const deleteTrainingProgramForm = async (formId: number | undefined, customerNumber: string): Promise<{ training_program_forms: TrainingProgramForm[] }> => {
  try {
    const res = await axios.delete(`${constants.ONTRAQ_API_URL}/api2/training-program-forms/${formId}`, {
      data: {
        customer_number: customerNumber
      }
    });
    const { training_program_forms } = res.data;
    return { training_program_forms };
  } catch(error) {
    console.error(error);
    return { training_program_forms: [] };
  }
}

const uploadForm = async (
  formId: number,
  customerNumber: string,
  file: File,
  trainingFormId: number | null,
  sectionId: number,
  programId?: number
): Promise<{ training_program_forms: TrainingProgramForm[], success: boolean }> => {
  try {
    let data = new FormData();
    data.append('file', file);
    data.append('customer_number', customerNumber);
    data.append('form_id', formId.toString());
    data.append('section_id', sectionId.toString());

    if(trainingFormId) data.append('training_form_id', trainingFormId.toString());
    if(programId) data.append('program_id', programId.toString());

    const res = await axios.post(`${constants.ONTRAQ_API_URL}/api2/training-program-forms/upload-form`, 
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );
    const { training_program_forms } = res.data;
    return { training_program_forms, success: true };
  } catch(error) {
    console.error(error);
    return { training_program_forms: [], success: false };
  }
}

const updateTrainingProgramFormUsers = async (formId: number, customerNumber: string, trainingProgramForms: TrainingProgramFormUserPayload[]): Promise<{ training_program_forms: TrainingProgramForm[], success: boolean }> => {
  try {
    const res = await axios.put(`${constants.ONTRAQ_API_URL}/api2/training-program-forms/${formId}/users`, {
      customer_number: customerNumber,
      training_program_forms: trainingProgramForms
    });
    const { training_program_forms } = res.data;
    return { training_program_forms, success: true };
  } catch(error) {
    console.error(error);
    return { training_program_forms: [], success: false };
  }
}

const addTrainingProgramForm = async(formId: number, customerNumber: string, sectionId: number, programId?: number): Promise<{
  training_program_forms: TrainingProgramForm[],
  success: boolean
}> => {
  try {
    const res = await axios.post(`${constants.ONTRAQ_API_URL}/api2/training-program-forms`, 
      {
        customer_number: customerNumber,
        form_id: formId,
        program_id: programId,
        section_id: sectionId
      }
    );
    const { training_program_forms } = res.data;
    return { training_program_forms, success: true };
  } catch(error) {
    console.error(error);
    return { training_program_forms: [], success: false}
  }
}

const updateExemptProgramForm = async (
  formId: number,
  customerNumber: string,
  exempt: boolean,
  sectionId: number,
  programId?: number
): Promise<{
  training_program_form_exemptions: TrainingProgramFormExemption[],
  success: boolean
}> => {
  try {
    const res = await axios.put(`${constants.ONTRAQ_API_URL}/api2/training-program-form-exemptions/${formId}/exempt`, {
      customer_number: customerNumber,
      exempt,
      program_id: programId,
      section_id: sectionId
    });
    const { training_program_form_exemptions } = res.data;
    return { training_program_form_exemptions, success: true };
  } catch(error) {
    console.error(error);
    return { training_program_form_exemptions: [], success: false };
  }
}

const updateExemptTrainingProgramForm = async (
  trainingProgramFormId: number,
  customerNumber: string,
  exempt: boolean,
  programId: number,
  sectionId: number
): Promise<{
  training_program_forms: TrainingProgramForm[],
  success: boolean
}> => {
  try {
    const res = await axios.put(`${constants.ONTRAQ_API_URL}/api2/training-program-forms/${trainingProgramFormId}/exempt`, {
      customer_number: customerNumber,
      exempt,
      program_id: programId,
      section_id: sectionId
    });
    const { training_program_forms } = res.data;
    return { training_program_forms, success: true };
  } catch(error) {
    console.error(error);
    return { training_program_forms: [], success: false };
  }
}

export {
  getProgramsAndChecklists,
  getTaskCompliances,
  setTaskMetadata,
  setCustomerNotesForTask,
  deleteUploadedForm,
  uploadForm,
  updateTrainingProgramFormUsers,
  addTrainingProgramForm,
  updateExemptProgramForm,
  deleteTrainingProgramForm,
  updateExemptTrainingProgramForm
};
