import { Checkbox, TableCell } from "@mui/material"
import { ProgramForm, TrainingProgramForm, TrainingProgramFormExemption } from "../../types"
import { selectTrainingProgramFormExemptions, updateExemptProgramForm, updateExemptTrainingProgramForm } from "../../slice"
import { useAppDispatch, useAppSelector } from "../../../../../redux/store"
import { selectCurrentCustomerNumber } from "../../../../../redux/customer"
import { useMemo } from "react"

const ExemptCell = (props: {
  form: ProgramForm
  isCollapsedCell: boolean,
  tableCellStyles: any,
  colIndex: number,
  colWidths: number[],
  isAdminOrOwner: boolean,
  trainingProgramForm: TrainingProgramForm,
  programId?: number;
  sectionId: number;
}) => {

  const {
    isCollapsedCell,
    tableCellStyles,
    colIndex,
    colWidths,
    form,
    isAdminOrOwner,
    trainingProgramForm,
    programId,
    sectionId
  } = props

  const dispatch = useAppDispatch();
  const customerNumber = useAppSelector(selectCurrentCustomerNumber);
  const formExemptions = useAppSelector(selectTrainingProgramFormExemptions);
  const sx = isCollapsedCell || (form.is_practice_only && !form.is_multiple_uploads ) ? {...tableCellStyles, width: colWidths[colIndex]} : tableCellStyles;

  const formExemption = useMemo(() => {
    return formExemptions?.find(e => e.form_id === form.id && e.program_id === programId && e.section_id === sectionId);
  }, [formExemptions, form]);

  const exempt = useMemo(() => {
    if(form.is_multiple_uploads) return trainingProgramForm?.exempt ?? false;
    return formExemption?.exempt ?? false;
  }, [formExemption, trainingProgramForm]);

  const showExempt = useMemo(() => {
    return ((isCollapsedCell && form.is_multiple_uploads) || (!isCollapsedCell && !form.is_multiple_uploads)) &&
      isAdminOrOwner && form.form_type != 'Upload Required'
  }, [isCollapsedCell, form])

  const handleChange = async (exempt: boolean) => {
    if(!form.is_multiple_uploads) {
      const action = updateExemptProgramForm({ formId: form.id, customerNumber, exempt, programId, sectionId });
      await dispatch(action);
    }
    if(form.is_multiple_uploads) {
      const action = updateExemptTrainingProgramForm({ trainingFormId: trainingProgramForm.id, customerNumber, exempt, programId: trainingProgramForm.program_id, sectionId });
      await dispatch(action);
    }
  }

  return (
    <TableCell
      sx={sx}
      align="center"
    >
      {showExempt ? 
       <Checkbox
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleChange(event.target.checked)}
          checked={exempt}
        />
      : null}
    </TableCell>
  )
}

export default ExemptCell