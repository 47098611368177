import { SyntheticEvent, useMemo, useState } from 'react';
import { Table, TableBody, TableHead, TableRow, Box, Card, IconButton, Typography, Button } from '@mui/material';
import { GetApp as GetAppIcon, Delete as DeleteIcon, Warning as WarningIcon } from '@mui/icons-material';
import { groupBy, prop, reduce } from 'remeda';
import CompliancePlip from '../../../../components/CompliancePlip';
import NextStepsAccordion from './NextStepsAccordion';
import MarkAsAddressedButton from './MarkAsAddressedButton';
import MarkAsAddressedModal from './MarkAsAddressedModal';
import { StyledTableCell, StyledTableRow } from '../../../../components/Table.styled';
import { getFullNameOrEmail, selectCurrentUser } from '../../../../redux/user';
import { useAppSelector } from '../../../../redux/store';
import LogInOfficeResultModal from './LogInOfficeResultModal';
import { WaterlineTestReport } from '../WaterlineReports';
import { ChildCustomerDetails, ComplianceStatus, CustomerDetails, TeamMember } from '../../../../types';
import { DisplayResultsBy, Room, WaterlineTestResult } from '../types';
import DeleteTestResultModal from './DeleteTestResultModal';
import ReassignRoomModal from './ReassignRoomModal';
import { formatStandardDate, getWaterLinePermissions } from '../utils';

interface Compliance {
  testType: string;
  status: number;
  message: string;
}

const TestResultTable = (props: {
  testResults: WaterlineTestResult[];
  displayResultsBy: DisplayResultsBy;
  getTestResults: () => void;
  teamMembersIndex: { [Identifier: number]: TeamMember }
  rooms: Room[];
  handleOpenAlert: (message: string) => void;
  getCompliance: () => void;
  customerDetails: Partial<ChildCustomerDetails> | Partial<CustomerDetails>;
  unmatchedResults?: boolean;
}) => {

  const { testResults, displayResultsBy, getTestResults, teamMembersIndex, rooms, handleOpenAlert, getCompliance, customerDetails, unmatchedResults } = props;

  const [selectedTestResult, setSelectedTestResult] = useState<WaterlineTestResult | null>(null);
  const [markAsAddressedModalIsOpen, setMarkAsAddressedModalIsOpen] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const currentUser = useAppSelector(selectCurrentUser);
  const waterLinePermissions = getWaterLinePermissions(currentUser);

  const compliance: Compliance = reduce(testResults, (compliance, result) => {
    compliance.testType = result.retest ? 'Retest' : 'Test';

    if (compliance.status === ComplianceStatus.Failed) return compliance;
    if (result.is_pass === false) {
      compliance.status = ComplianceStatus.Failed;
      compliance.message = 'Test Failed';
    } else if (result.retest === true) {
      compliance.status = ComplianceStatus.RetestPassed;
      compliance.message = 'Retest Passed';
    } else {
      compliance.status = ComplianceStatus.Passed;
      compliance.message = 'Test Passed';
    }

    return compliance;
  }, { testType: '', status: 0, message: '' });

  const hasNextSteps = useMemo(() => {
    for (let i = 0; i < testResults.length; i++) {
      if (testResults[i].next_steps !== null) return true;
    }
    return false;
  }, [testResults]);

  const markAsAddressed = (testResult: WaterlineTestResult) => {
    setSelectedTestResult(testResult);
    setMarkAsAddressedModalIsOpen(true);
  }

  const handleCloseModal = () => {
    setMarkAsAddressedModalIsOpen(false);
    setSelectedTestResult(null);
  }

  const refresh = (r: boolean) => {
    if (r) {
      getTestResults();
      getCompliance();
    }
  }

  return (
    <div className='m-b-16'>
      <Card variant="outlined" style={{ marginBottom: '15px' }}>
        <div className="box-header flex flex-align-center flex-space-between">
          <div className="flex flex-align-center">
            <CompliancePlip className="m-r-16" status={compliance.status} message={compliance.message} />
            {
              displayResultsBy === 'roomChair' ?
                <Box>Room/Chair: {testResults[0]?.room?.name ?? testResults[0]?.location}</Box>
                :
                <Box>Test Date: {formatStandardDate(testResults[0].test_date)}</Box>
            }
          </div>
          <div className="flex" style={{ alignItems: 'end' }}>
            {
              unmatchedResults
              && waterLinePermissions.roomChair.assign
              && (
                <ReassignRoomModal
                  rooms={rooms}
                  testResults={testResults}
                  handleAlert={handleOpenAlert}
                  onClose={refresh}
                />
              )
            }
            {testResults.length > 0 && (
              <div onClick={() => WaterlineTestReport(testResults, customerDetails)} className="download-box">
                <GetAppIcon fontSize="small" />
              </div>
            )}
          </div>
        </div>
        {hasNextSteps ? <NextStepsAccordion testResults={testResults} /> : null}
        <Table size="small" sx={{ tableLayout: 'fixed' }}>
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ width: '3%' }} align="center"></StyledTableCell>
              <StyledTableCell sx={{ width: '5%' }} align="center">Result</StyledTableCell>
              <StyledTableCell sx={{ width: '9%' }} align="center">In-Office/Mail-In</StyledTableCell>
              <StyledTableCell sx={{ width: '8%' }} align="center">Test/Retest</StyledTableCell>
              <StyledTableCell sx={{ width: '5%' }} align="center">Sample</StyledTableCell>
              <StyledTableCell sx={{ width: '7%' }} align="center">Test Type</StyledTableCell>
              {displayResultsBy === 'roomChair' && <StyledTableCell sx={{ width: '7%' }} align="center">Test Date</StyledTableCell>}
              <StyledTableCell sx={{ width: '6%' }} align="center">Room/Chair</StyledTableCell>
              <StyledTableCell sx={{ width: '8%' }} align="center">Daily Treatment Method</StyledTableCell>
              <StyledTableCell sx={{ width: '8%' }} align="center">Description</StyledTableCell>
              <StyledTableCell sx={{ width: '8%' }} align="center">Team Member/Lab Tech</StyledTableCell>
              <StyledTableCell sx={{ width: '4%' }} align="center">pH</StyledTableCell>
              <StyledTableCell sx={{ width: '4%' }} align="center">TDS</StyledTableCell>
              <StyledTableCell sx={{ width: '6%' }} align="center">CFU/ML</StyledTableCell>
              <StyledTableCell sx={{ width: '8%' }} align="center">Corrective Action</StyledTableCell>
              <StyledTableCell sx={{ width: '6%' }} align="center"></StyledTableCell>
              <StyledTableCell sx={{ width: '3%' }} align="center"></StyledTableCell>
              <StyledTableCell sx={{ width: '4%' }} align="center"></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {testResults.sort((a, b) => {
              if (displayResultsBy === 'roomChair') {
                return new Date(b.test_date).valueOf() - new Date(a.test_date).valueOf();
              }

              return (a.location ?? a?.room?.name ?? '').localeCompare(b.location ?? b?.room?.name ?? '');
            })
              .map(testResult => (
                <StyledTableRow key={testResult.test_result_id}>
                  <StyledTableCell align="center">
                    <CompliancePlip className="m-r-8" status={testResult.is_pass ? ComplianceStatus.Passed : ComplianceStatus.Failed} message={`${testResult.retest ? 'Retest' : ''} ${testResult.is_pass ? 'Passed' : 'Failed'}`} />
                  </StyledTableCell>
                  <StyledTableCell align="center">{testResult.is_pass ? 'Pass' : 'Fail'}</StyledTableCell>
                  <StyledTableCell align="center">{testResult.in_office_test ? 'In-Office' : 'Mail-In'}</StyledTableCell>
                  <StyledTableCell align="center">{testResult.retest ? 'Retest' : 'Test'}</StyledTableCell>
                  <StyledTableCell align="center">{testResult.sample_number}</StyledTableCell>
                  <StyledTableCell align="center">{testResult.hpc_method}</StyledTableCell>
                  {displayResultsBy === 'roomChair' && <StyledTableCell align="center">{formatStandardDate(testResult.test_date)}</StyledTableCell>}
                  <StyledTableCell align="center">{testResult.location ?? testResult?.room?.name}</StyledTableCell>
                  <StyledTableCell align="center">{testResult.product}</StyledTableCell>
                  <StyledTableCell align="center">{testResult.result_description}</StyledTableCell>
                  <StyledTableCell sx={{ wordWrap: 'break-word' }} align="center">{testResult.tested_by ? getFullNameOrEmail(teamMembersIndex[testResult.tested_by]) : testResult.test_operator}</StyledTableCell>
                  <StyledTableCell align="center">{testResult.pH}</StyledTableCell>
                  <StyledTableCell align="center">{testResult.TDS}</StyledTableCell>
                  <StyledTableCell align="center">{testResult.in_office_test ? (Number(testResult.cfu) === 499 ? 'Pass (<500 CFUs)' : 'Fail (>=500 CFUs)') : testResult.cfu}</StyledTableCell>
                  <StyledTableCell align="center">{testResult.addressed_note}</StyledTableCell>
                  <StyledTableCell>
                    {!testResult.is_pass ? <MarkAsAddressedButton testResult={testResult} markAsAddressed={markAsAddressed} /> : null}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {
                      testResult.in_office_test
                      && waterLinePermissions.inOffice.edit
                      && (
                        <LogInOfficeResultModal
                          rooms={rooms}
                          handleOpenAlert={handleOpenAlert}
                          getTestResults={getTestResults}
                          testResult={testResult}
                          displayBy={displayResultsBy}
                          getCompliance={getCompliance}
                        />
                      )
                    }
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {
                      testResult.in_office_test
                      && waterLinePermissions.inOffice.delete
                      && (
                        <IconButton
                          onClick={() => {
                            setShowDeleteModal(true);
                            setSelectedTestResult(testResult);
                          }}
                          sx={{
                            width: '16px',
                            height: '16px'
                          }}
                        >
                          <DeleteIcon
                            fontSize="small"
                            sx={{
                              width: '16px'
                            }}
                          />
                        </IconButton>
                      )
                    }
                    {!testResult.in_office_test && testResult?.room?.name && (
                      <ReassignRoomModal
                        rooms={rooms}
                        testResults={[testResult]}
                        handleAlert={handleOpenAlert}
                        onClose={refresh}
                        currentRoom={testResult.room}
                        icon={true}
                      />
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
        <MarkAsAddressedModal
          testResult={selectedTestResult}
          open={markAsAddressedModalIsOpen}
          handleClose={handleCloseModal}
          getTestResults={getTestResults}
        />
        <DeleteTestResultModal
          onClose={(refresh) => {
            if (refresh) {
              getTestResults();
              getCompliance();
            }
            setShowDeleteModal(false);
          }}
          isOpen={showDeleteModal}
          testResult={selectedTestResult}
          onAlert={handleOpenAlert}
        />
      </Card>
    </div>
  );
}

const TestResultsTableWrapper = (props: {
  testResults: WaterlineTestResult[];
  displayResultsBy: DisplayResultsBy;
  getTestResults: () => void;
  teamMembersIndex: { [Identifier: number]: TeamMember }
  rooms: Room[];
  handleOpenAlert: (message: string) => void;
  getCompliance: () => void;
  customerDetails: Partial<ChildCustomerDetails> | Partial<CustomerDetails>;
  setTab: (_event: SyntheticEvent, newValue: number) => void;
}) => {

  const { testResults, displayResultsBy, getTestResults, teamMembersIndex, rooms, handleOpenAlert, getCompliance, customerDetails, setTab } = props;

  const hasUnmatchedResults = displayResultsBy === 'roomChair' && !testResults[0]?.room;
  const unmatchedResults = useMemo(() => {
    const resultsCopy = testResults.map(result => ({ ...result, location: result.location ?? 'No Room/Chair' }));
    if (hasUnmatchedResults) return groupBy(resultsCopy, prop('location'));
    return {};
  }, [hasUnmatchedResults]);

  return (
    <>
      {
        hasUnmatchedResults ?
          <div className='m-b-16'>
            <Card variant="outlined" style={{ marginBottom: '15px' }}>
              <div className="box-header flex flex-align-center flex-space-between">
                <div className="flex flex-align-center">
                  <WarningIcon sx={{ color: '#f4c972' }} />
                  <Box>Unmatched Results</Box>
                </div>
              </div>
              <Box sx={{ backgroundColor: '#f2dede', padding: '10px' }}>
                <Typography sx={{ color: '#c86148', fontSize: '13px' }}>These results did not match any of the chairs set up for your practice.
                  Please assign each result to a chair. To select a room/chair combination,
                  please add a room/chair under <Button onClick={(event) => setTab(event, 2)} variant="text" sx={{ color: '#69c4e8', fontSize: '13px', padding: 0 }}>Manage Room/Chair section.</Button>
                </Typography>
              </Box>
              {
                Object.values(unmatchedResults)
                  .sort((a, b) => (a[0].location ?? '').localeCompare(b[0].location ?? ''))
                  .map(results => (
                    <TestResultTable
                      key={results[0].test_result_id}
                      testResults={results}
                      displayResultsBy={displayResultsBy}
                      getTestResults={getTestResults}
                      teamMembersIndex={teamMembersIndex}
                      rooms={rooms}
                      handleOpenAlert={handleOpenAlert}
                      getCompliance={getCompliance}
                      customerDetails={customerDetails}
                      unmatchedResults={hasUnmatchedResults}
                    />
                  ))
              }
            </Card>
          </div>
          :
          <TestResultTable
            testResults={testResults}
            displayResultsBy={displayResultsBy}
            getTestResults={getTestResults}
            teamMembersIndex={teamMembersIndex}
            rooms={rooms}
            handleOpenAlert={handleOpenAlert}
            getCompliance={getCompliance}
            customerDetails={customerDetails}
          />
      }
    </>
  );
};

export default TestResultsTableWrapper;