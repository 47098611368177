import axios from 'axios';
import constants from '../constants';
import { ChildCustomerDetails, CustomerDetails } from '../types';

const getCustomer = async (currentUser: { customer_number: string }) => {
  const query = {
    include: [
      {
        relation: 'customerService'
      },
      {
        relation: 'groups',
        scope: {
          include: {
            relation: 'members'
          }
        }
      }
    ]
  };

  return await axios
    .get(`${constants.ONTRAQ_API_URL}/api/Customers/${currentUser.customer_number}?filter=${JSON.stringify(query)}`)
    .then((res) => {
      const { city, corporate_parent, customer_number, customerService, doctor_name, email, groups, name, phone, state, street, street2, zip } = res.data;
      const customerDetails: CustomerDetails = {
        city: city.trim(),
        corporate_parent,
        customer_number: customer_number.trim(),
        customerService,
        doctor_name: doctor_name.trim(),
        email: email.trim(),
        groups,
        name: name.trim(),
        phone: phone?.trim() ?? '',
        state: state.trim(),
        street: street.trim(),
        street2: street2?.trim() ?? '',
        zip: zip.trim()
      };

      return customerDetails;
    })
    .catch((error) => {
      console.error(error);
      return false;
    });
};

const getChildPractice = async (customerNumber: string) => {
  const teamMembers = await getTeamMembers(customerNumber);
  const query = {
    include: [
      {
        relation: 'customerService'
      }
    ]
  };

  return await axios
    .get(`${constants.ONTRAQ_API_URL}/api/Customers/${customerNumber}?filter=${JSON.stringify(query)}`)
    .then((res) => {
      const { customer_number, customerService, doctor_name, email, name, phone, city, state, street, street2, zip } = res.data;
      
      const childCustomerDetails: ChildCustomerDetails = {
        customer_number: customer_number.trim(),
        customerService,
        doctor_name: doctor_name.trim().toUpperCase(),
        email: email.trim().toUpperCase(),
        name: name.trim().toUpperCase(),
        phone: phone.trim(),
        city: city.trim(),
        state: state.trim().toUpperCase(),
        street: street.trim().toUpperCase(),
        street2: street2.trim().toUpperCase(),
        zip: zip.trim(),
        teamMembers
      };

      return childCustomerDetails;
    })
    .catch((error) => {
      console.error(error);
      return undefined;
    });
};

const getTeamMembers = async (customerNumber: string) => {
  const query = {
    include: [
      {
        relation: 'groups',
        scope: {
          include: 'name'
        }
      }
    ],
    where: {
      customer_number: customerNumber
    }
  };
  try {
    const response = await axios.get(`${constants.ONTRAQ_API_URL}/api/UsersPractices?filter=${JSON.stringify(query)}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const getGroups = async (customerNumber: string) => {
  const query = {
    where: { customer_number: customerNumber },
    include: [{ relation: 'members' }],
  }

  try {
    const response = await axios.get(`${constants.ONTRAQ_API_URL}/api/groups?filter=${JSON.stringify(query)}`)
    return response.data
  } catch (error) {
    console.error(error)
  }
}

export { getCustomer, getChildPractice, getTeamMembers, getGroups };
