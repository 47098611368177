const timezoneMap: Record<string, string> = {
  AST: 'America/Puerto_Rico',
  EST: 'America/New_York',
  CST: 'America/Chicago',
  MST: 'America/Denver',
  AZST: 'America/Phoenix',
  PST: 'America/Los_Angeles',
  AKST: 'America/Anchorage',
  HST: 'Pacific/Honolulu',
  SST: 'Pacific/Pago_Pago',
  ChST: 'Pacific/Guam'
};

export const getTimezoneLong = (timezoneShort: string): string => timezoneMap[timezoneShort] ?? timezoneMap['EST'];

export function standardDateFormat(dateString: string, timeZone?: string) {
  if (!dateString) {
    return '';
  }

  const formatter = new Intl.DateTimeFormat('en-US', {
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
    timeZone: timeZone ?? 'America/Los_Angeles'
  });

  return formatter.format(new Date(dateString));
}

export function longDateFormat(timeZone: string, dateString?: string) {
  if (!dateString) {
    return '';
  }

  const formatter = new Intl.DateTimeFormat('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'long',
    timeZone
  });

  return formatter.format(new Date(dateString));
}
