import { Box, Button, Dialog, FormControl, FormHelperText, IconButton, InputLabel, MenuItem, Select, Stack } from "@mui/material"
import { Shuffle as ShuffleIcon } from '@mui/icons-material';
import CloseModalButton from "../../../../components/CloseModalButton";
import { StyledDialogContent, StyledDialogFooter, StyledDialogTitle } from "../../../../components/Dialog.styled";
import { useEffect, useState } from "react";
import { Room, WaterlineTestResult } from "../types";
import axios from "axios";
import constants from "../../../../constants";

const initialFormDataState: { room_id: null | string } = {
  room_id: null
}

const ReassignRoomModal = (props: {

  currentRoom?: Room;
  icon?: boolean;
  handleAlert: (alert: string) => void;
  onClose: (refresh: boolean) => void;
  rooms: Room[];
  testResults: WaterlineTestResult[];
}) => {

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [formData, setFormData] = useState(initialFormDataState);
  const [errors, setErrors] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { currentRoom, handleAlert, icon, onClose, rooms, testResults } = props;

  useEffect(() => {
    if (currentRoom?.room_id) {
      setFormData({ room_id: String(currentRoom.room_id) })
    }
  }, []);

  const handleClose = (refresh: boolean) => {
    setIsOpen(false);
    setErrors(false);
    onClose(refresh);
  }

  const handleChange = (room_id: string) => {
    if (room_id) setErrors(false);
    setFormData({
      room_id
    });
  }

  const handleSubmit = async () => {
    if (formData.room_id === null) {
      setErrors(true);
      return;
    }

    const matchingRoom = rooms.find(({ room_id }) => room_id === Number(formData.room_id));
    const description = matchingRoom?.description ?? '';

    setLoading(true);

    try {
      const payload = testResults.map(result => ({ customer_number: result.customer_number, result_description: result?.result_description ?? description, test_result_id: result.test_result_id, room_id: formData.room_id }));
      await axios.put(`${constants.ONTRAQ_API_URL}/api/Waterline_test_results/reassignRoomChair`, payload);

      setFormData(initialFormDataState);
      handleClose(true);
    } catch (err) {
      console.error(err);
      handleAlert(`There was an error reassigning Room/Chair.`);
      setFormData(initialFormDataState);
      handleClose(false);
    }
  }

  return (
    <>
      <Box sx={{ marginRight: '10px' }}>
        {icon ? (
          <IconButton
            onClick={() => setIsOpen(true)}
            sx={{
              width: '16px',
              height: '16px'
            }}
          >
            <ShuffleIcon
              fontSize="small"
              sx={{
                width: '20px'
              }}
            />
          </IconButton>
        ) : (
          <Button
            sx={{
              backgroundColor: '#fff',
              color: '#50555b',
              boxShadow: '0px 1px 2px -1px rgba(0, 0, 0, 0.2), 0px 1px 3px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);',
              height: '32px',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                boxShadow: '0px 1px 2px -1px rgba(0, 0, 0, 0.2), 0px 1px 3px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);',
              }
            }}
            variant="contained"
            disableElevation
            onClick={() => setIsOpen(true)}
          >
            Reassign
          </Button>
        )}
      </Box>
      <Dialog open={isOpen} onClose={() => handleClose(false)} fullWidth>
        <CloseModalButton handleClose={() => handleClose(false)} />
        <StyledDialogTitle>Reassign Room/Chair</StyledDialogTitle>
        <StyledDialogContent>
          <Stack spacing={'16px'} marginTop={'16px'} marginBottom={'8px'}>
            <FormControl fullWidth size="small">
              <InputLabel id="room-id">Select a Room/Chair *</InputLabel>
              <Select
                id="room-id"
                value={formData?.room_id?.toString() ?? ''}
                name="room_id"
                label="Select a Room/Chair *"
                onChange={(event) => handleChange(event.target.value)}
                size="small"
                error={errors}
              >
                {rooms.map(({ room_id, name }) => (
                  <MenuItem key={room_id} value={room_id}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
              {errors && <FormHelperText error>Room/Chair is required</FormHelperText>}
            </FormControl>
          </Stack>
        </StyledDialogContent>
        <StyledDialogFooter>
          <Button onClick={() => handleClose(false)}>Cancel</Button>
          <Button variant="contained" onClick={handleSubmit} disabled={loading}>
            Save
          </Button>
        </StyledDialogFooter>
      </Dialog>
    </>
  );
}

export default ReassignRoomModal;