import React from 'react';
import deRoutes from './dentalenhancements/DERoutes';
import trainingRoutes from './training/TrainingRoutes';
import waterlineRoutes from './waterline/WaterlineRoutes';
import Contact from '../../views/common/Contact'
import Faq from '../../views/common/Faq';
import Terms from '../../views/common/Terms';
import Guides from '../../views/common/Guides';

const miscRoutes = [
  {
    path: '/practice/contact',
    element: <Contact />
  },
  {
    path: '/practice/faq',
    element: <Faq />
  },
  {
    path: '/practice/terms',
    element: <Terms />
  },
  {
    path: '/practice/guides',
    element: <Guides />
  }
];

const practiceRoutes = [...miscRoutes, ...deRoutes, ...trainingRoutes, ...waterlineRoutes];

export default practiceRoutes;
