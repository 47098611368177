import axios from "axios"
import { Dispatch, SetStateAction } from "react"
import constants from "../../../constants"
import GroupDialog from "./GroupDialog"
import { Groups, OpenGroupModal, OpenGroupAlert } from "../../../types"

type CreateGroupDialogProps = {
  open: boolean,
  customerNumber: string,
  setOpenModal: Dispatch<SetStateAction<OpenGroupModal>>,
  setOpenAlert: Dispatch<SetStateAction<OpenGroupAlert>>,
  setGroupId: Dispatch<SetStateAction<number | null>>,
}

const createCustomerGroup = async (groupName: string, customerNumber: string) => {
  const url = `${constants.ONTRAQ_API_URL}/api/groups`
  const payload = {
    name: groupName.trim(),
    customer_number: customerNumber,
  }
  const response = await axios.post(url, payload)
  
  return response.data
}

const CreateGroupDialog = (props: CreateGroupDialogProps) => {
  const { open, setOpenModal, customerNumber, setOpenAlert, setGroupId } = props

  const handleSuccess = (group: Groups) => {
    const groupId = group?.group_id ?? null
    const groupName = group?.name

    setGroupId(groupId)
    setOpenAlert({ open: true, type: 'success', message: `Group ${groupName} created`})
  }

  const handleError = () => {
    setOpenAlert({ open: true, type: 'error', message: ''})
  }

  const handleClose = () => {
    setOpenModal({ open: false, type: 'create' })
  }

  return (
    <GroupDialog
      open={open}
      customerNumber={customerNumber}
      title="Create Group"
      saveText="Create"
      defaultGroupName=""
      icon="plus"
      handleClose={handleClose}
      handleSuccess={handleSuccess}
      handleError={handleError}
      service={createCustomerGroup}
    />
  )
}

export default CreateGroupDialog
