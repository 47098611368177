import ManualWrapper from './ManualWrapper';
import { getOshaManual } from './services';
import DownloadFormButton from './DownloadFormButton';
import { useYearAndCustomerNumber } from './hooks';

const Osha = () => {
  const { year, customerNumber } = useYearAndCustomerNumber();

  return (
    <ManualWrapper>
      <h1>Required OSHA Manual</h1>
      <h2 className="center">Download and complete all items in your manual.</h2>
      <p className="center">
        Download one copy. If you have purchased a premium package, you will walk through this document with your trainer. Each Section in the manual
        must be completed.
      </p>
      <p className="center color-danger bold">To mark this status as Complete. Please go to the "Checklist" in your OSHA portion. Then check off all items you have completed in the Tab that says OSHA E-Manual.</p>
      <hr />
      <ul>
        <li>
          Federal Annual OSHA Manual
          <DownloadFormButton disabled={!customerNumber} getFileHandler={() => getOshaManual(customerNumber ?? '', year)} />
        </li>
        <hr />
      </ul>
    </ManualWrapper>
  );
};

export default Osha;
