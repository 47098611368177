import { Dispatch, SetStateAction, useState } from 'react'
import { Grid, Button } from '@mui/material'
import GroupPickList from './GroupPickList'
import { PickList, PickListOption } from '../../../types'

const not = (a: readonly PickListOption[], b: readonly PickListOption[]) => {
  const bSet = new Set()
  b.forEach(option => bSet.add(option.customerNumber))
  return a.filter(option => !bSet.has(option.customerNumber))
}

const intersection = (a: readonly PickListOption[], b: readonly PickListOption[]) => {
  const bSet = new Set()
  b.forEach(option => bSet.add(option.customerNumber))
  return a.filter(option => bSet.has(option.customerNumber))
}

type GroupTransferProps = {
  pickList: PickList,
  setPickList: Dispatch<SetStateAction<PickList>>,
  groupName: string,
}

const GroupTransfer = (props: GroupTransferProps) => {
  const { pickList, setPickList, groupName } = props

  const [checked, setChecked] = useState<PickListOption[]>([])

  const availableChecked = intersection(checked, pickList.available)
  const membersChecked = intersection(checked, pickList.members)

  const handleAllRight = () => {
    setPickList({
      available: [],
      members: pickList.members.concat(pickList.available),
    })
  }

  const handleCheckedRight = () => {
    setPickList({
      available: not(pickList.available, availableChecked),
      members: pickList.members.concat(availableChecked),
    })
    setChecked(not(checked, availableChecked))
  }

  const handleCheckedLeft = () => {
    setPickList({
      available: pickList.available.concat(membersChecked),
      members: not(pickList.members, membersChecked),
    })
    setChecked(not(checked, membersChecked))
  }

  const handleAllLeft = () => {
    setPickList({
      available: pickList.available.concat(pickList.members),
      members: [],
    })
  }

  return (
    <Grid
      id="group-transfer-container"
      container
      spacing={2}
    >
      <Grid id="group-transfer-available-container" item>
        <GroupPickList 
          items={pickList.available}
          checked={checked}
          setChecked={setChecked}
          label="Available"
          groupChecked={availableChecked}
          groupName={groupName}
        />
      </Grid>
      <Grid id="group-transfer-buttons-container" item>
        <Grid id="group-transfer-buttons" container direction="column">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleAllRight}
            disabled={pickList.available.length === 0}
            aria-label="move all right"
            title="Move All"
          >
            <span id="group-transfer-all-right">{">>"}</span>
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={availableChecked.length === 0}
            aria-label="move selected right"
            title="Move Selected"
          >
            <span id="group-transfer-right">{">"}</span>
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={membersChecked.length === 0}
            aria-label="move selected left"
            title="Move Selected"
          >
            <span id="group-transfer-left">{"<"}</span>
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleAllLeft}
            disabled={pickList.members.length === 0}
            aria-label="move all left"
            title="Move All"
          >
            <span id="group-transfer-all-left">{"<<"}</span>
          </Button>
        </Grid>
      </Grid>
      <Grid id="group-transfer-members-container" item>
        <GroupPickList 
          items={pickList.members}
          checked={checked}
          setChecked={setChecked}
          label="Members"
          groupChecked={membersChecked}
          groupName={groupName}
        />
      </Grid>
    </Grid>
  )
}

export default GroupTransfer
