import React, { useEffect, useMemo, useState } from "react";
import * as XLSX from 'xlsx';
import dayjs from "dayjs";
import { DataGrid } from '@mui/x-data-grid';
import PageHeader from "../../../components/PageHeader";
import { Box, Button, Card, Checkbox, createTheme, FormControl, InputLabel, Link, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent, TextField, ThemeProvider, useTheme } from "@mui/material";
import "./Practices.css";
import { useSelector } from "react-redux";
import { getChildPractices, selectChildPractices, selectCustomerError, selectCustomerNumber, selectGroups } from "../../../redux/customer";
import { useAppDispatch } from "../../../redux/store";
import constants from "../../../constants";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { ErrorNotify } from "../../../components/Notify.styled";

const tableTheme = createTheme({
  typography: {
    fontFamily: '"Open Sans", sans-serif',
    fontSize: 13
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: "bold"
        }
      }
    }
  }
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Practices = () => {
  const { promiseInProgress } = usePromiseTracker();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const groups = useSelector(selectGroups);
  const customerNumber = useSelector(selectCustomerNumber);
  const childPractices = useSelector(selectChildPractices);
  const errorMessage = useSelector(selectCustomerError);
  const [showError, setShowError] = useState<boolean>(false);

  const [groupFilter, setGroupFilter] = useState<number[]>([]);
  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    if (errorMessage !== null) {
      setShowError(true);
    }
  }, [errorMessage])

  useEffect(() => {
    if (customerNumber && childPractices?.length === 0) {
      trackPromise(dispatch(getChildPractices(customerNumber)))
    }
  }, [customerNumber, childPractices])

  const handleChange = (event: SelectChangeEvent<number[]>) => {
    const selectedIds = event.target.value as number[];

    setGroupFilter(selectedIds);
  };

  const filteredPractices = useMemo(() => {
    let practices = structuredClone(childPractices) ?? [];
    if (search.length > 0) {
      const cleanSearch = search.toLowerCase();
      practices = practices.filter(({ customer_number, name, doctor_name, street, phone, city, zip, state }) => {
        return customer_number.includes(cleanSearch)
          || name.toLowerCase().includes(cleanSearch)
          || doctor_name.toLowerCase().includes(cleanSearch)
          || street.toLowerCase().includes(cleanSearch)
          || phone.toLowerCase().includes(cleanSearch)
          || city.toLowerCase().includes(cleanSearch)
          || zip.toLowerCase().includes(cleanSearch)
          || state.toLowerCase().includes(cleanSearch)
      })
    }

    if (groupFilter.length > 0) {
      practices = practices.filter((practice) => practice.members.some(member => groupFilter.includes(member.group_id)))
    }

    return practices;
  }, [childPractices, groupFilter, search])

  const exportPractices = () => {
    const formattedChildPractices = filteredPractices
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(({ customer_number, name, doctor_name, street, city, state, zip, phone, last_connection_date }) => (
        {
          'Customer Number': customer_number,
          'Customer Name': name,
          'Doctor Name': doctor_name,
          Street: street,
          City: city,
          State: state,
          Zip: zip,
          Phone: phone,
          'Last Login': last_connection_date ? dayjs(last_connection_date).format('MM/DD/YYYY') : ''
        }
      ));

    const ws = XLSX.utils.json_to_sheet(formattedChildPractices);
    const wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, 'Practices');
    XLSX.writeFile(wb, 'Practice_List.xlsx');
  }

  return (
    <>
      <PageHeader iconName={'building'} headerName={'Practices'} />
      <Box sx={{ width: '100%', p: 2, color: theme.palette.color50555b.main }} className="training-container">
        <Card sx={{ width: "100%" }} variant="outlined">
          <div className="box-header flex flex-space-between flex-align-center">{filteredPractices.length} Practices</div>
          <div className="table-subheader flex-space-between">
            <div>
              <TextField sx={{ minWidth: 200 }} size="small" label="Search" onChange={(event) => setSearch(event.target.value)}></TextField>
              {groups.length > 0 && (
                <FormControl sx={{ ml: 2, minWidth: 200 }} size="small">
                  <InputLabel id="multiple-checkbox-label">Filter by Group</InputLabel>
                  <Select
                    labelId="multiple-checkbox-label"
                    id="multiple-checkbox"
                    multiple
                    value={groupFilter}
                    onChange={handleChange}
                    input={<OutlinedInput label="Filter by Group" />}
                    renderValue={(selected) =>
                      groups
                        .filter((group) => selected.includes(group.group_id))
                        .map((group) => group.name)
                        .join(", ")
                    }
                    MenuProps={MenuProps}
                  >
                    {groups.map((group) => (
                      <MenuItem key={group.group_id} value={group.group_id}>
                        <Checkbox
                          sx={{ mr: 1 }}
                          checked={groupFilter.some((group_id) => group_id === group.group_id)}
                        />
                        <ListItemText primary={group.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </div>
            <Button variant="contained" onClick={() => exportPractices()}>Export</Button>
          </div>
          <ThemeProvider theme={tableTheme}>
            <DataGrid
              sx={{ borderLeft: "none", borderRight: "none", borderBottom: "none", borderRadius: "0" }}
              rows={filteredPractices.map(practice => ({ ...practice, id: practice.customer_number }))}
              columns={[
                {
                  field: "customer_number",
                  headerName: "Customer #",
                  sortable: true,
                  width: 100,
                  renderCell: (params) => (
                    <Link sx={{ color: theme.palette.linkColor.main }} href={`${constants.ONTRAQ_WEB_URL}/webapp/#/corp/practice/${params.row.customer_number}/dashboard`}>
                      {params.row.customer_number.trim()}
                    </Link>
                  )
                },
                {
                  field: "name",
                  headerName: "Name",
                  width: 300,
                  renderCell: (params) => (
                    <Link sx={{ color: theme.palette.linkColor.main }} href={`${constants.ONTRAQ_WEB_URL}/webapp/#/corp/practice/${params.row.customer_number}/dashboard`}>
                      {params.row.name.trim()}
                    </Link>
                  )
                },
                { field: "doctor_name", headerName: "Doctor Name", width: 250 },
                { field: "street", headerName: "Street", width: 250 },
                { field: "city", headerName: "City", width: 150 },
                { field: "state", headerName: "State", width: 100 },
                { field: "zip", headerName: "Zip", width: 100 },
                { field: "phone", headerName: "Phone", width: 150 },
                {
                  field: "last_connection_date",
                  headerName: "Last Login",
                  width: 150,
                  renderCell: (params) => (
                    params.row.last_connection_date ? dayjs(params.row.last_connection_date).format('MM/DD/YYYY')
                      : <Link sx={{ color: theme.palette.linkColor.main }} href={`${constants.ONTRAQ_WEB_URL}/webapp/#/corp/practice/${params.row.customer_number}/team-members`}>
                        Set-up Required
                      </Link>
                  )
                }
              ]}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
                sorting: {
                  sortModel: [
                    {
                      field: 'name',
                      sort: 'asc'
                    }
                  ]
                }
              }}
              pageSizeOptions={[5, 10, 20, 25, 50, 100]}
              loading={promiseInProgress}
              autoHeight
              density="compact"
            />
          </ThemeProvider>
        </Card>
      </Box>
      <ErrorNotify
        open={showError}
        onClose={() => setShowError(false)}
      >
        {errorMessage}
      </ErrorNotify>
    </>
  );
}

export default Practices;