import axios from 'axios';
import { useMemo, useState } from 'react';
import { Button, Card, IconButton, Table, TableBody, TableCell, TableHead, TableRow, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Edit as EditIcon, GetApp as GetAppIcon } from '@mui/icons-material';
import CompliancePlip from '../../../components/CompliancePlip';
import { StyledTableCell, StyledTableRow } from '../../../components/Table.styled';
import { InventoryDate, Room, WaterlineItem } from './types';
import { formatStandardDate, formatUtcDate, getReplacementDate } from './utils';
import { waterlineItemReport } from './WaterlineReports';
import { ChildCustomerDetails, ComplianceStatus, CustomerDetails } from '../../../types';
import constants from '../../../constants';
import AssignRoomModal from './AssignEditItemRoomModal';
import { TableButton } from '../../../components/Buttons.styled';
import { getWaterLinePermissions } from './utils';
import { useAppSelector } from '../../../redux/store';
import { selectCurrentUser } from '../../../redux/user';
import { ToolTip } from '../dentalenhancements/components/ToolTip';

interface WaterlineInventoryTable {
  customerDetails: Partial<ChildCustomerDetails> | Partial<CustomerDetails>;
  name: 'Water Testing' | 'Water Treatment' | 'Water Straws';
  rooms: Room[];
  items: WaterlineItem[];
  onAlert: (message: string) => void;
  onRefresh: () => void;
}

const WaterlineInventoryTable: React.FC<WaterlineInventoryTable> = ({ customerDetails, name, rooms, items, onAlert, onRefresh }) => {
  const currentUser = useAppSelector(selectCurrentUser);
  const waterLinePermissions = getWaterLinePermissions(currentUser);

  const [isActive, setIsActive] = useState<boolean>(true);
  const [editItem, setEditItem] = useState<WaterlineItem | undefined>(undefined);
  const [loadingItemId, setLoadingItemId] = useState<number | null>(null);
  const [openAssignModal, setOpenAssignModal] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');

  const handleIsActive = (_: React.MouseEvent<HTMLElement>, value: boolean) => {
    setIsActive(value);
  }

  const filteredItems = useMemo(() => {
    const cleanSearch = search?.trim().toLowerCase() || '';

    return items.filter((item) => {
      const isActiveMatch = item?.active === isActive;

      const isSearchMatch = cleanSearch ? item.name?.toLowerCase().includes(cleanSearch) || String(item.item_number).includes(cleanSearch) : true;

      return isActiveMatch && isSearchMatch;
    });
  }, [items, isActive, search]);

  const complianceStatuses = useMemo(() => {
    const initialStatus = { 1: 0, 2: 0, 3: 0, 4: 0 };
    return items.reduce((acc: Record<number, number>, item) => {
      acc[item.status]++;
      return acc;
    }, initialStatus);
  }, [items]);

  const complianceMessage = (status: number): string => {
    const statusMessages: Record<number, string> = {
      1: 'Valid',
      2: 'Expiring Soon',
      3: 'Expired',
      4: 'Inactive',
    };

    return statusMessages[status] || 'Inactive';
  }

  const handleMarkAsUsedActive = async (item: WaterlineItem) => {
    const itemCopy = structuredClone(item);
    itemCopy.active = !itemCopy.active;

    try {
      setLoadingItemId(itemCopy.id);

      await axios.put(`${constants.ONTRAQ_API_URL}/api/WaterlineInventory/updateItem/${itemCopy.id}`, { data: itemCopy });
      await onRefresh();

      setLoadingItemId(null);
    } catch (error) {
      setLoadingItemId(null);
      const message = `There was an error updating the item`;
      console.error(message);
      onAlert(message);
    }
  };

  const handleCloseModal = (refresh?: boolean) => {
    setOpenAssignModal(false);
    setEditItem(undefined);
    if (refresh) {
      onRefresh();
    }
  };

  const handleOpenModal = (item: WaterlineItem) => {
    setEditItem(item);
    setOpenAssignModal(true);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearch(value);
  };

  return (
    <div className='m-b-16'>
      <Card variant="outlined">
        <div className="box-header flex flex-space-between flex-align-center">
          {name}
          <div className="flex flex-align-center">
            <div className="m-r-16">
              <CompliancePlip status={ComplianceStatus.Expired} message={complianceMessage(ComplianceStatus.Expired)} className="m-r-8" />
              <span className="m-r-12 muted-text">{complianceStatuses[ComplianceStatus.Expired]}</span>
              <CompliancePlip status={ComplianceStatus.Expiring} message={complianceMessage(ComplianceStatus.Expiring)} className="m-r-8" />
              <span className="m-r-12 muted-text">{complianceStatuses[ComplianceStatus.Expiring]}</span>
              <CompliancePlip status={ComplianceStatus.Ok} message={complianceMessage(ComplianceStatus.Ok)} className="m-r-8" />
              <span className="m-r-12 muted-text">{complianceStatuses[ComplianceStatus.Ok]}</span>
              <CompliancePlip status={ComplianceStatus.Inactive} message={complianceMessage(ComplianceStatus.Inactive)} className="m-r-8" />
              <span className="muted-text">{complianceStatuses[ComplianceStatus.Inactive]}</span>
            </div>
            {items.length > 0 && (
              <div onClick={() => waterlineItemReport(items, customerDetails, false)} className="download-box">
                <GetAppIcon fontSize="small" />
              </div>
            )}
          </div>
        </div>
        <div className="waterline-table-subheader">
          <TextField size="small" variant="outlined" label="Search" value={search} onChange={handleSearch} />
          <div>
            <ToggleButtonGroup size="small" color="primary" exclusive value={isActive} onChange={handleIsActive}>
              <ToggleButton value={true}>Active</ToggleButton>
              <ToggleButton value={false}>Inactive</ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>
        {filteredItems.length > 0 ? (
          <Table size="small">
            <TableHead>
              <TableRow>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell>Item Name</StyledTableCell>
                <StyledTableCell>Item Number</StyledTableCell>
                <StyledTableCell>Room/Chair</StyledTableCell>
                <StyledTableCell>{InventoryDate[name]}</StyledTableCell>
                <StyledTableCell>Ship Date</StyledTableCell>
                {
                  name === 'Water Straws' &&
                  <StyledTableCell>
                    <span className="m-r-8">Replacement Date</span>
                    <ToolTip icon='info' helpText='Replacement Date is the date the waterline needs a new straw installed.'/>
                  </StyledTableCell>
                }
                <StyledTableCell></StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredItems.map((item) => (
                <StyledTableRow key={item.id}>
                  <StyledTableCell>
                    <CompliancePlip status={item.status || 4} message={complianceMessage(item.status)} />
                  </StyledTableCell>
                  <StyledTableCell>{item.name}</StyledTableCell>
                  <StyledTableCell>{item.item_number}</StyledTableCell>
                  <StyledTableCell>{item?.room?.name}</StyledTableCell>
                  <StyledTableCell>{formatUtcDate(item?.activated_date)}</StyledTableCell>
                  <StyledTableCell>{formatStandardDate(item?.shipped_date)}</StyledTableCell>
                  {name === 'Water Straws' &&
                    <StyledTableCell>{getReplacementDate(formatUtcDate(item?.activated_date) || formatStandardDate(item?.shipped_date))}</StyledTableCell>
                  }
                  <StyledTableCell align="center">
                    {
                      waterLinePermissions.roomChair.edit
                      && item?.active
                      && item?.room
                      && (
                        <IconButton aria-label="edit" onClick={() => handleOpenModal(item)}>
                          <EditIcon fontSize="small" />
                        </IconButton>
                      )
                    }
                    {
                      waterLinePermissions.roomChair.assign
                      && item?.active
                      && !item?.room
                      && (
                        <TableButton size="small" variant="contained" onClick={() => handleOpenModal(item)}>
                          Assign Room/Chair
                        </TableButton>
                      )
                    }
                  </StyledTableCell>
                  <StyledTableCell>
                    <TableButton size="small" variant="contained" onClick={() => handleMarkAsUsedActive(item)} disabled={loadingItemId === item.id}>
                      Mark as {item.active ? 'Used' : 'Active'}
                    </TableButton>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>There are no {isActive ? 'active' : 'inactive'} {name}</TableCell>
              </TableRow>
            </TableHead>
          </Table>
        )}
      </Card>
      <AssignRoomModal rooms={rooms} item={editItem} isOpen={openAssignModal} onClose={handleCloseModal} onAlert={onAlert} inventoryType={name} />
    </div>
  );
};

export default WaterlineInventoryTable;
