import { useState } from "react";
import { Button, Card, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { Add as AddIcon, Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import { Room } from "./types";
import AddEditRoomModal from "./AddEditRoomModal";
import DeleteRoomModal from "./DeleteRoomModal";
import { StyledTableCell, StyledTableRow } from "../../../components/Table.styled";
import { getWaterLinePermissions } from "./utils";
import { useAppSelector } from "../../../redux/store";
import { selectCurrentUser } from "../../../redux/user";

interface WaterlineRoomProps {
  rooms: Room[];
  onRefresh: () => void;
  onRefreshAll: () => void;
  onAlert: (message: string) => void;
}

const WaterlineRooms: React.FC<WaterlineRoomProps> = ({ rooms, onRefresh, onRefreshAll, onAlert }) => {
  const currentUser = useAppSelector(selectCurrentUser);
  const waterLinePermissions = getWaterLinePermissions(currentUser);

  const [expandInstructions, setExpandInstructions] = useState<boolean>(false);
  const [openAddModal, setOpenAddModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [editRoom, setEditRoom] = useState<Room | undefined>(undefined);

  const handleCloseModal = (refresh?: boolean, refreshAll?: boolean) => {
    setOpenAddModal(false);
    setOpenDeleteModal(false);
    setEditRoom(undefined);

    if (refresh) {
      onRefresh();
    }

    if (refreshAll) {
      onRefreshAll();
    }
  }

  const handleOpenModal = (room?: Room) => {
    if (room) {
      setEditRoom(room)
    }
    setOpenAddModal(true);
  }

  const handleOpenDeleteModal = (room: Room) => {
    setEditRoom(room)
    setOpenDeleteModal(true);
  }

  return (
    <div>
      <div className="instructions-container">
        <span className="instructions">Instructions</span><br /><br />
        This Room/Chair tab lets you add a room/chair in OnTraq and display the added room/chair as an existing room/chair for you to choose a
        room/chair while logging in In-Office Test/Retest Results as well as reassigning Mail-In Test/Retest Results so that there is consistency in
        the description name of room/chair for both In-Office Test/Retest Results and Mail-In Test/Retest Results. {!expandInstructions && <span className='link' onClick={() => setExpandInstructions(true)}>show more...</span>}<br /><br />
        {expandInstructions && (
          <span>
            To add a room/chair, select the Add Room/Chair button and provide a Room/Chair Name (required) and the Waterline Device/Instrument
            (optional) for that room/chair in the modal. Upon clicking on OK, the added room/chair will display in the existing room/chair list.<br /><br /> To
            edit a room/chair, select the Edit icon to the right of the room/chair you want to edit and make the edit in the modal. Upon clicking on
            OK, the updated room/chair will display in the existing room/chair list. To delete a room/chair select the Delete icon to the right of
            the room / chair you no longer want.<br /><br /> Note: Any tests or treatments not associated with the added/existing room/chair will show up in the
            “Unmatched Results" section and will need to be reassigned to an added/existing room/chair. <span className='link' onClick={() => setExpandInstructions(false)}>show less...</span><br /><br />
          </span>
        )}
      </div>
      <Card variant="outlined">
        <div className="box-header">Manage Room/Chair</div>
        <div className="room-table-header">
          {
            waterLinePermissions.roomChair.add
            && (
              <Button
                startIcon={<AddIcon />}
                variant="contained"
                onClick={() => handleOpenModal()}
              >
                Add Room/Chair
              </Button>
            )
          }
        </div>
        {
          waterLinePermissions.roomChair.view
          && (
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: '40%' }}>Room/Chair</TableCell>
                  <TableCell sx={{ width: '50%' }}>Description</TableCell>
                  <TableCell sx={{ width: '5%' }}></TableCell>
                  <TableCell sx={{ width: '5%' }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rooms.length > 0 ? (
                  rooms.map((room) => (
                    <StyledTableRow key={room.room_id}>
                      <StyledTableCell>{room.name}</StyledTableCell>
                      <StyledTableCell>{room.description}</StyledTableCell>
                      <StyledTableCell>
                        {
                          waterLinePermissions.roomChair.edit
                          && (
                            <IconButton aria-label="edit" onClick={() => handleOpenModal(room)}>
                              <EditIcon
                                fontSize="small"
                                sx={{
                                  width: '16px'
                                }} />
                            </IconButton>
                          )
                        }
                      </StyledTableCell>
                      <TableCell>
                        {
                          waterLinePermissions.roomChair.delete
                          && (
                            <IconButton aria-label="edit" onClick={() => handleOpenDeleteModal(room)}>
                              <DeleteIcon
                                fontSize="small"
                                sx={{
                                  width: '16px'
                                }} />
                            </IconButton>
                          )
                        }
                      </TableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell>There are no rooms created yet</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          )
        }
      </Card>
      <AddEditRoomModal isOpen={openAddModal} onClose={handleCloseModal} onAlert={onAlert} room={editRoom} rooms={rooms} />
      <DeleteRoomModal isOpen={openDeleteModal} onClose={handleCloseModal} onAlert={onAlert} room={editRoom} />
    </div>
  )
}

export default WaterlineRooms;