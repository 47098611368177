
import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material'
import { Groups } from '../../../types'
import { Dispatch, SetStateAction } from 'react'

type GroupSelectProps = {
  groups: Groups[],
  groupId: number | null,
  setGroupId: Dispatch<SetStateAction<number | null>>,
}

const GroupSelect = (props: GroupSelectProps) => {
  const { groupId, groups, setGroupId } = props

  const handleSelect = (event: SelectChangeEvent<number>) => {
    const nextGroupId = Number(event.target.value)
    setGroupId(nextGroupId)
  }

  return (
    <>
      <FormControl sx={{ width: '42%' }} size="small">
        <InputLabel id="group-select-label">Select a group</InputLabel>
        <Select<number>
          id="group-select"
          label="Select a group"
          labelId="group-select-label"
          value={groupId ?? ''}
          onChange={handleSelect}
        >
          {
            groups.map((group: Groups) => (
              <MenuItem key={`${group.group_id}-${group.name}`} value={group.group_id}>
                {group.name}
              </MenuItem>
            ))
          }
        </Select>
      </FormControl>      
    </>
  )
}

export default GroupSelect
