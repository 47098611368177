import { Box, Divider, useTheme } from "@mui/material";
import PageHeader from "../../components/PageHeader";
import "./Guides.css";

const Guides = () => {
  const theme = useTheme();

  const videoGuides = [
    {
      url: "https://www.youtube.com/embed/-bJAPdxQE_w?showinfo=0&amp;controls=1&amp;modestbranding=1&amp;rel=0",
      description: "OnTraq Registration"
    },
    {
      url: "https://www.youtube.com/embed/ibagWMLo3Rk?showinfo=0&amp;controls=1&amp;modestbranding=1&amp;rel=0",
      description: "OnTraq Account Settings and Homepage Navigation"
    },
    {
      url: "https://www.youtube.com/embed/oOA1fqVKyDk?showinfo=0&amp;controls=1&amp;modestbranding=1&amp;rel=0",
      description: "OnTraq Medications and Devices"
    },
    {
      url: "https://www.youtube.com/embed/-YaCBCSDQ5Y?showinfo=0&amp;controls=1&amp;modestbranding=1&amp;rel=0",
      description: "OnTraq Biological Monitoring"
    },
    {
      url: "https://www.youtube.com/embed/izWpceUpf5E?showinfo=0&amp;controls=1&amp;modestbranding=1&amp;rel=0",
      description: "OnTraq Waste Management"
    },
    {
      url: "https://www.youtube.com/embed/ljQY0RrHne0?showinfo=0&amp;controls=1&amp;modestbranding=1&amp;rel=0",
      description: "OnTraq Task Management"
    }
  ];

  return (
    <>
      <PageHeader iconName={'film'} headerName={'Video Guides'} />
      <Box sx={{ color: theme.palette.color50555b.main }} className="m-16">
        <Box sx={{ textAlign: "center", fontSize: "45px", fontWeight: "300", marginBottom: "60px" }}>How To Use OnTraq</Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <div className="video-guides-container">
            {videoGuides.map(({ url, description }) => (
              <div className="video-container">
                <div className="video">
                  <iframe itemProp="video" src={url} allowFullScreen frameBorder="0"></iframe>
                </div>
                <p className="bold center">{description}</p>
              </div>
            ))}
          </div>
        </Box>
      </Box>
    </>
  )
}
export default Guides;