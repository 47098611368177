import { Box, Divider, useTheme } from "@mui/material";
import PageHeader from "../../components/PageHeader";

const Faq = () => {
  const theme = useTheme();

  return (
    <>
      <PageHeader iconName={'circle-question'} headerName={'FAQ'} />
      <Box sx={{ color: theme.palette.color50555b.main }} className="m-16">
        <ul className="f-s-13" id="top">
          <li><a href="#general" className="link">General</a></li>
          <li><a href="#upcomingShipments" target="_self" className="link">Bill Pay and Upcoming Shipments</a></li>
          <li><a href="#meds" className="link">Medications</a></li>
          <li><a href="#otrs" className="link">Refill Service</a></li>
          <li><a href="#medicalDevices" className="link">Devices</a></li>
          <li><a href="#biological" className="link">Biological Monitoring</a></li>
          <li><a href="#unusedmeds" className="link">Unused Medications Recovery</a></li>
          <li><a href="#mwaste" className="link">Sharps & Medical Waste</a></li>
          <li><a href="#amalgam" className="link">Amalgam Waste</a></li>
          <li><a href="#team" className="link">Team Members</a></li>
          <li><a href="#tasks" className="link">Tasks</a></li>
          <li><a href="#other" className="link">Other</a></li>
        </ul>
        <Divider />
        <h4 id="general" className="m-b-8 f-w-500">General</h4>
        <div className="f-s-13">
          <strong>1. What Is OnTraq?</strong>
          <p className="m-t-0">OnTraq is a suite of compliance management tools designed with busy dentists like you in mind. It&rsquo;s an easy-to-use solution
            for any practice that wants to remain efficient and compliant, no matter where they are.
            Stop worrying and create the perfect tracking system, reminder system and audit trail today.
          </p>
          <strong>2. How Do I Associate My HealthFirst Account with OnTraq?</strong>
          <p className="m-t-0">On the homepage, there will be a message to &quot;Associate Your Account&quot;.
            Click on the button to get started.  You will need your HealthFirst customer number and the zip code of your practice to do this.
            Your customer number can be found on your invoice.  If you need assistance finding your customer number, please call our compliance counselors at 1-800-331-1984.
          </p>
          <strong>3. Why do I need an email address to have an OnTraq account?</strong>
          <p className="m-t-0">Unique email addresses are required for an OnTraq account.  This ensures that communications intended for a specific user are only
            seen by that user.  Additionally, this helps insure the compliance of your practice by making sure Task related emails are received
            and answered by the intended user.  This also protects your security by limiting the "reset password" feature to the owner of the
            email account.
          </p>
          <strong>4. What can I do if I don't have an email address?</strong>
          <p className="m-t-0">If you do not have an email address, you can create an email account for free at <a target="_blank" className="link" href="http://www.gmail.com">www.gmail.com</a>.
            You will be asked to select an email address, enter a password and a phone number. You may also be asked to type in a word or
            two to verify you are a live person and not a machine creating an email address.  If you prefer, there are other sites besides
            gmail on the internet where you can create a free email account.  Once you have created this account, you can use it to create
            your user account in OnTraq.
          </p>
        </div>
        <Divider />
        <div className="flex flex-end">
          <a href="#top" className="f-s-13 link">Back to Top</a>
        </div>

        <h4 id="upcomingShipments" className="m-b-8 m-t-8 f-w-500">Bill Pay and Upcoming Shipments</h4>
        <div className="f-s-13">
          <strong>1. How Do I Pay My Bill Online?</strong>
          <p className="m-t-0">In the left hand navigation, there is a link to “Pay Your Balance”.  Simply, click this link to go to our Bill Pay section.
            All outstanding invoices will be listed on the page.  To view the details of an invoice, click on the invoice number.
          </p>
          <p className="m-t-0">
            To pay an invoice “in full” simply check the box(es) on the right hand side and then click the “Pay” button at the bottom of the page.
            On the next page, you will need to enter in your credit card information and click “Submit”
            To partially pay an invoice, enter in the amount for the invoice(s), then click on “Pay” at the bottom of the page.
            On the next page, you will need to enter in your credit card information and click “Submit”
            ”
          </p>
          <strong>2. How Do I View My Upcoming Shipments?</strong>
          <p className="m-t-0">In the left nav, click on “Upcoming Shipments”.  This page will show all upcoming shipments for the next year.
            Each shipment will display the month and year the shipment is expected to ship, the kit in your practice that the replacement items are for,
            the items that are expected to be in the shipment and the estimated price.  Estimated pricing includes shipping, taxes and DSCSA service fees.
            Pricing can change throughout the year due to fluctuations in medication cost.
          </p>
          <strong>3. How Do I Look Up My Invoice History?</strong>
          <p className="m-t-0">Use the link in the left nav “Invoice History” to access your invoices.
            The summary list of previous invoices will display with their total amount and invoice date.  To see the details, including the items shipped and prices, click on an invoice number.
          </p>
          <strong>4. When Will My Backordered Items Ship?</strong>

          <p className="m-t-0">Backordered items will ship as soon as they become available.  All items that are on backorder are due to national shortage.
            This list of national drug shortages can be viewed at <a target="_blank" className="link" href="http://www.accessdata.fda.gov/scripts/drugshortages/default.cfm">http://www.accessdata.fda.gov/scripts/drugshortages/default.cfm</a>
          </p>
        </div>
        <Divider />
        <div className="flex flex-end">
          <a href="#top" className="f-s-13 link">Back to Top</a>
        </div>

        <h4 id="meds" className="m-b-8 m-t-8 f-w-500">Medications</h4>
        <div className="f-s-13">
          <strong>1. Why Don't I See Anything In The Medications Section?</strong>
          <p className="m-t-0">Medications purchased from HealthFirst will automatically display in this section. Recent purchase can take up to 72 hours to display.
            If you believe there are medications missing from the display on this page, please contact us at 1-800-833-1956.</p>

          <strong>2. What Is The Refill Service?</strong>
          <p className="m-t-0">The Refill Service automatically replenishes your medications just prior to expiration, maximizing value and guaranteeing you never have an outdated medication.
            Refills are sent directly to you along with an invoice for the items in your refill. </p>
        </div>
        <Divider />
        <div className="flex flex-end">
          <a href="#top" className="f-s-13 link">Back to Top</a>
        </div>

        <h4 id="otrs" className="m-b-8 m-t-8 f-w-500">Refill Service</h4>
        <div className="f-s-13">
          <strong>1. From the Medications page, a green button will display to “Set Up for Automatic Refill”</strong>
          <p className="m-t-0">After clicking on the button that says, you will be asked to select whether you wish to set your items up for auto renewal or make a one-time purchase.
            You will also be asked to select which items you want to apply the service to.  After completing the form, our compliance counselors will call you to confirm your selections.
          </p>
        </div>
        <Divider />
        <div className="flex flex-end">
          <a href="#top" className="f-s-13 link">Back to Top</a>
        </div>

        <h4 id="medicalDevices" className="m-b-8 m-t-8 f-w-500">Devices</h4>
        <div className="f-s-13">
          <strong>1. Why Don't I See Anything In The Devices Section?</strong>
          <p className="m-t-0">Devices purchased from HealthFirst will automatically display in this section. Recent purchase can take up to 72 hours to display.
            If you believe there are devices missing from the display on this page, please contact us at 1-800-833-1956.
          </p>
        </div>
        <Divider />
        <div className="flex flex-end">
          <a href="#top" className="f-s-13 link">Back to Top</a>
        </div>

        <h4 id="biological" className="m-b-8 m-t-8 f-w-500">Biological Monitoring</h4>
        <div className="f-s-13">
          <strong>1. Why Don't I See My BIOLogical Mail-In Test Results?</strong>
          <p className="m-t-0">Only Mail In Spore Test Kits purchased from HealthFirst display in this section
            If you have purchased the mail-in Biological 12 or Biological 52 product and you still do not see your results, check in the section at the top of the page titled “Unassigned Test Results”.
            To reassign test results to the correct autoclave, click on the icon under the column header "Assign" to move the test result to its correct autoclave. Select the Autoclave from the dropdown list and click on "Save Changes".
          </p>
          <p className="m-t-0">
            Test results can take up to 12 business days to appear.  If you do not see your results anywhere on the page, and it has been more than 12 business days, please call 1-800-331-1984.
          </p>

          <strong>2. How Do I Move A Test Result That Is In “Unassigned”</strong>
          <p className="m-t-0">To reassign test results listed in the “Unassigned” section, click on the icon under the column header “Assign” to move the test result to its correct autoclave.
            Select the Autoclave from the dropdown list and click on “Save Changes”.
          </p>
          <p className="m-t-0">
            If you do not see your test results anywhere on the page, please contact our Compliance Counselors via Live Chat or at 1800-833-1956.
          </p>

          <strong>3. How Do I Add An Autoclave?</strong>
          <p className="m-t-0">To set up your autoclaves, click on “New Autoclave” on the Biological Monitoring homepage. Each autoclave must be entered separately.
            Complete all required information and click on the “Save” button.  Repeat this step as many times as necessary to set up each of your practice’s autoclaves.
          </p>

          <strong>4. How Do I See A Biological Monitoring Compliance Report?</strong>
          <p className="m-t-0">To see your compliance reports, click on “Compliance Reports” in the left navigation bar.
            Click on either “Quarterly Reports” or “Annual Reports” to view your Biological Monitoring reports.
          </p>

          <strong>5. How Do I Download My Biological Testing Results?</strong>
          <p className="m-t-0">Test results can be downloaded for each autoclave. To download test results, click on the menu icon at the top right hand corner of the autoclave.
            Select “Download All Test Results For This Autoclave” from the menu. Choose the format for your report, either PDF or Excel, then click on the “Download Report” button.
          </p>

          <strong>6. I Have Set Up My Autoclaves, But My Test Results Are Still Displaying In The Unassigned Category. </strong>
          <p className="m-t-0">How Do I Purchase Scrap Amalgam Containers?
          </p>
          <p className="m-t-0">
            Please visit <a target="_blank" className="link" href="https://www.healthfirst.com/waste-management/dental/amalgam-waste-recovery/">https://www.healthfirst.com/waste-management/dental/amalgam-waste-recovery/</a> to purchase Scrap Amalgam Containers.
          </p>
        </div>
        <Divider />
        <div className="flex flex-end">
          <a href="#top" className="f-s-13 link">Back to Top</a>
        </div>

        <h4 id="unusedmeds" className="m-b-8 m-t-8 f-w-500">Unused Medications Recovery</h4>
        <div className="f-s-13">
          <strong>1. Why Don't I See Anything In The Unused Medications Recovery Section?</strong>
          <p className="m-t-0">Unused Medications Bag purchased from HealthFirst will automatically display in this section. Recent purchase can take up to 72 hours to display.
            If you believe there are Unused Meds Bags missing from the display on this page, please contact us at 1-800-833-1956.
          </p>

          <strong>2. Where Can I Purchase An Environmental Recovery Services Unused Medications Bag?</strong>
          <p className="m-t-0">Please call us at 1-800-833-1956 to purchase Unused Medications Bags.</p>

          <strong>3. How Do I Properly Use The Unused Medications Bag?</strong>
          <p className="m-t-0">Simply place expired or unused medications into the bag and seal the bag by removing the plastic strip over the adhesive tape.
            You can then place the bag in your outgoing mail or take it to your local post office for delivery (prepaid postage is included with the bag). Do not overfill or place controlled medications within the bag.
          </p>

          <strong>4. Why do I see a manifest for some of my unused medications bags and not others?</strong>
          <p className="m-t-0">It was cost prohibitive for our vendor to continue providing the online manifests. In order to avoid a price increase for our customers,
            HealthFirst recently to a new disposal vendor. Manifests are no longer available.  The historical record of your old manifest will remain online.
          </p>
        </div>
        <Divider />
        <div className="flex flex-end">
          <a href="#top" className="f-s-13 link">Back to Top</a>
        </div>

        <h4 id="mwaste" className="m-b-8 m-t-8 f-w-500">Sharps & Medical Waste</h4>
        <div className="f-s-13">
          <strong>1. Why Don't I See Anything In The Sharps & Medical Waste Section?</strong>
          <p className="m-t-0">Sharps & Medical Waste Containers purchased from HealthFirst will automatically display in this section. Recent purchase can take up to 72 hours to display.
            If you believe there are containers missing from the display on this page, please contact us at 1-800-833-1956.
          </p>

          <strong>2. How Do I Purchase Medical Waste Takeaway/Sharps Recovery Containers?</strong>
          <p className="m-t-0">Please visit <a target="_blank" className="link" href="https://www.healthfirst.com/ontraq/sharps-mailback-containers/">https://www.healthfirst.com/ontraq/sharps-mailback-containers/</a>
            to purchase Sharps & Medical Waste Containers.
          </p>
        </div>
        <Divider />
        <div className="flex flex-end">
          <a href="#top" className="f-s-13 link">Back to Top</a>
        </div>

        <h4 id="amalgam" className="m-b-8 m-t-8 f-w-500">Amalgam Waste</h4>
        <div className="f-s-13">
          <strong>1. Why Don't I See Anything In The Amalgam Waste Section?</strong>
          <p className="m-t-0">Amalgam Waste Containers purchased from HealthFirst will automatically display in this section. Recent purchase can take up to 72 hours to display.
            If you believe there are containers missing from the display on this page, please contact us at 1-800-833-1956.
          </p>
        </div>
        <Divider />
        <div className="flex flex-end">
          <a href="#top" className="f-s-13 link">Back to Top</a>
        </div>

        <h4 id="team" className="m-b-8 m-t-8 f-w-500">Team Members</h4>
        <div className="f-s-13">
          <strong>1. How Do I Add A Team Member?</strong>
          <p className="m-t-0">Click on “New Team Member” in the top right hand corner of the Team Members homepage. Fill out all required fields on the form and click on the “Save” button. </p>

          <strong>2. How Do I Delete A Team Member?</strong>
          <p className="m-t-0">Team Members can only be made “inactive, they cannot be deleted. If a Team Member is no longer in your practice,
            click on the icon in the “Action” column next to the Team Member's name. Select “Edit Team Member”. In the “Edit Team Member” window, unselect the checkbox “Staff Member Active”.
            Please note that Team Members with the role of “Owner” or “Administrator” cannot be made inactive.
            Please select a new Owner or Administrator, then change the role of the Team Member you wish to make Inactive to “User”. Once the Team Member has the role of “User”, you will be able to make him/her inactive.
          </p>

          <strong>3. How Do I Resend The Verification Link?</strong>
          <p className="m-t-0">Users must verify their email address prior to using OnTraq. For users who have not verified their account, you can resend the verification link.
            From the Team Members page, click on the icon in the “Action” column next to the Team Member's name. In the menu, click on the “Resend Verification Email”. Then, click on “OK”.
          </p>
        </div>
        <Divider />
        <div className="flex flex-end">
          <a href="#top" className="f-s-13 link">Back to Top</a>
        </div>

        <h4 id="tasks" className="m-b-8 m-t-8 f-w-500">Tasks</h4>
        <div className="f-s-13">
          <strong>1. How Do I Add A Task?</strong>
          <p className="m-t-0">Click on “New Task” in the top right hand corner of the “Tasks” homepage. Fill out all required fields and click on the “Save” button. </p>

          <strong>2. How Do I Make A Task Recurring?</strong>
          <p className="m-t-0">On the “Add a Task” form, select either “Repeat Daily” or “Repeat Weekly”. If you selected “Repeat Daily”, you will be asked to select which days of the week you wish the task to repeat.
            Make your selection. If you selected “Repeat Weekly”, you will be asked to select the weekly frequency you wish the task to repeat. Make your selection.
          </p>

          <strong>3. How Do I Create An Annually Recurring Task?</strong>
          <p className="m-t-0">On the “Add a Task” form, select “Repeat Weekly”. Enter “52” in the field “Repeat Every X Weeks”. Complete all required fields and click on the “Save” button. </p>

          <strong>4. How Do I Create A Monthly Recurring Task?</strong>
          <p className="m-t-0">On the “Add a Task” form, select “Repeat Weekly”. Enter “4” in the field “Repeat Every X Weeks”. Complete all required fields and click on the “Save” button. </p>

          <strong>5. How Do I Create A Task To Recurring Every Weekday?</strong>
          <p className="m-t-0">On the “Add a Task” form, select “Repeat Daily”. Click the checkbox for each weekday. Complete all required fields and click on the “Save” button. </p>

          <strong>6. What Can I Do If I Don't Have An Email Address?</strong>
          <p className="m-t-0">If you do not have an email address, you can create an email account for free at <a target="_blank" className="link" href="http://www.gmail.com">www.gmail.com</a>.
            You will be asked to select an email address, enter a password and a phone number. You may also be asked to type in a word or two to verify you are a live person and not a machine creating an email address.
            If you prefer, there are other sites besides gmail on the internet where you can create a free email account. Once you have created this account, you can use it to create your user account in OnTraq.
          </p>

          <strong>7. How Do I Assign A Task To Someone In My Practice?</strong>
          <p className="m-t-0">To assign a task to someone in your practice the team member must be set up in the Team Members section of OnTraq.
            After setting up the team, member, create a task on the Tasks page. When completing the “Add a Task” form, select the Team Member in the “Assign To” field. Complete all required fields on the form, then click on the “Save” button
          </p>

          <strong>8. How Do I Switch My Notifications From Email To Text?</strong>
          <p className="m-t-0">Click on “Welcome” arrow in the top right corner next to your name. Click on “Settings”. Scroll down to “Account Information and Preferences”,
            and then select the method by which you wish to receive your reminders. Be sure to click on “Save Changes” at the bottom of the page.
          </p>

          <strong>9. How Do I Switch My Notifications From Text To Email?</strong>
          <p className="m-t-0">Click on “Welcome” arrow in the top right corner next to your name. Click on “Settings”. Scroll down to “Account Information and Preferences”,
            and then select the method by which you wish to receive your reminders. Be sure to click on “Save Changes” at the bottom of the page.
          </p>

          <strong>10. Can I Designate The Time That I Receive My Task Notifications?</strong>
          <p className="m-t-0">Yes, you can globally designate the time you would like to receive your notifications in settings. Click on “Welcome” arrow in the top right corner next to your name.
            Click on “Settings”. Scroll down to “Reminder and Time Zone Preferences”, and then select the time you wish to receive your reminders. Be sure to click on “Save Changes” at the bottom of the page.
          </p>
          <p className="m-t-0">
            At this time, you cannot set the time for the notification for a specific task.
          </p>

          <strong>11. Can I Designate A Time For A Specific Task To Be Due?</strong>
          <p className="m-t-0">No, you cannot designate a time for a specific task at this time.</p>
        </div>
        <Divider />
        <div className="flex flex-end">
          <a href="#top" className="f-s-13 link">Back to Top</a>
        </div>

        <h4 id="other" className="m-b-8 m-t-8 f-w-500">Other</h4>
        <div className="f-s-13">
          <strong>1. What Items Display On My Compliance Calendar?</strong>
          <p className="m-t-0">All tasks you have created in OnTraq will display on your Compliance Calendar. </p>
          <strong>2. How Come Medication Expiration Dates Do Not Display On My Compliance Calendar?</strong>
          <p className="m-t-0">At this time, only Tasks display on the Compliance Calendar. </p>
        </div>
        <Divider />
        <div className="flex flex-end">
          <a href="#top" className="f-s-13 link">Back to Top</a>
        </div>
      </Box>
    </>
  )
}

export default Faq;