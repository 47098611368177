import React from 'react';
import WaterlineDashboard from '../../../views/practice/waterline/Waterline';

const waterlineRoutes = [
  {
    path: '/practice/waterline-management',
    element: <WaterlineDashboard />
  }
];

export default waterlineRoutes;
