import {
  PayloadAction,
  createAsyncThunk,
  createListenerMiddleware,
  createSelector,
  createSlice,
  isAnyOf,
  isFulfilled,
  isPending,
  isRejected
} from '@reduxjs/toolkit';
import * as R from 'remeda';

import { selectCurrentCustomerNumber, selectCustomerNumber } from '../../../redux/customer';
import type { RootState } from '../../../redux/store';
import { selectUserId } from '../../../redux/user';
import { postChecklistTaskInit } from '../../../services/customerChecklistComplianceService';
import * as oshahippaService from '../../../services/oshaHipaaService';
import { ComplianceStatus, CustomerOshaHipaaMetadata } from '../../../types';

import * as service from './services';
import { componentTypeNameToId, compositeComplianceStatus, taskIsCompliant } from './utils';
import {
  ChecklistCertification,
  CustomerTrainingWithCompliance,
  PracticeInfo,
  Program,
  ProgramComponentSection,
  ProgramComponentTypeName,
  TrainingChecklist,
  TrainingChecklistSection,
  TrainingChecklistTask,
  TrainingChecklistTasksMetadata,
  TrainingChecklistType,
  TrainingProgramForm,
  TrainingProgramFormExemption,
  TrainingProgramFormUserPayload
} from './types';

export type LoadingState = 'not-asked' | 'loading' | 'done' | 'error';

interface State {
  // If these goes beyond set or not set, an algebraic data type would be best
  programs: null | Program[];
  checklists: Record<number, TrainingChecklist>;
  currentYear: number;
  taskMetadata: Record<number, TrainingChecklistTasksMetadata>;
  completedChecklists: Record<number, ChecklistCertification>;
  checklistSections: Record<number, TrainingChecklistSection>;
  checklistTasks: Record<number, TrainingChecklistTask>;
  loadingState: LoadingState;
  customerTraining: CustomerTrainingWithCompliance[];
  initTaskMetadataState: LoadingState;
  oshaHipaaMetadata: CustomerOshaHipaaMetadata[];
  trainingProgramForms: TrainingProgramForm[];
  trainingProgramFormExemptions: TrainingProgramFormExemption[];
  practiceInfo?: PracticeInfo;
  snackBarMessage: SnackbarMessage | null;
}

const initialState: State = {
  programs: [],
  checklists: {},
  // As we keep doing things based off of current year, if we make it a shared variable, it allows us to change it
  // for testing
  currentYear: new Date().getFullYear(),
  taskMetadata: {},
  completedChecklists: {},
  checklistSections: {},
  checklistTasks: {},
  loadingState: 'not-asked',
  customerTraining: [],
  initTaskMetadataState: 'not-asked',
  oshaHipaaMetadata: [],
  trainingProgramForms: [],
  trainingProgramFormExemptions: [],
  snackBarMessage: null
};

const SLICE_PREFIX = 'practiceDE';

interface SnackbarMessage {
  message: string;
  type: 'normal' | 'error';
}

interface EditTrainingProgramFormUserPayload {
  form_id: number;
  user_id: number;
}

export const certifyChecklistComplete = createAsyncThunk(`${SLICE_PREFIX}/certifyChecklistComplete`, async (checklistId: number, { getState }) => {
  const state = getState() as RootState;

  const user_id = selectUserId(state);

  if (!user_id) {
    return Promise.reject(new Error('user id was not set!'));
  }

  const customerNumber = selectCurrentCustomerNumber(state);

  const certification = await service.certifyChecklistComplete(customerNumber, checklistId);

  const certified_at = new Date().toString();

  return {
    checklistId,
    certification
  };
});

const decertifyChecklist = createAsyncThunk(`${SLICE_PREFIX}/decertifyChecklist`, async (checklistId: number, { getState }) => {
  const state = getState() as RootState;

  const customerNumber = selectCustomerNumber(state);

  await service.decertifyChecklist(customerNumber, checklistId);

  return checklistId;
});

export const initTaskMetadata = createAsyncThunk<Record<number, TrainingChecklistTasksMetadata>, { customerNumber: string; year: string }>(
  `${SLICE_PREFIX}/initTaskMetadata`,
  async ({ customerNumber, year }) => {
    const tasklist = await postChecklistTaskInit(customerNumber, year);

    return R.indexBy(tasklist, R.prop('training_checklist_task_id'));
  }
);

export const updateTaskMetadata = createAsyncThunk(
  `${SLICE_PREFIX}/updateTaskMetadata`,
  async ({
    id,
    status,
    customerNumber,
    meta
  }: {
    id: number;
    status: ComplianceStatus;
    customerNumber: string;
    // Data from the route we need for checking certification status
    meta?: {
      year?: string;
      componentTypeName?: ProgramComponentTypeName;
      checklistType?: TrainingChecklistType;
    };
  }): Promise<
    [
      TrainingChecklistTasksMetadata,
      (
        | {
            year?: string;
            componentTypeName?: ProgramComponentTypeName;
            checklistType?: TrainingChecklistType;
          }
        | undefined
      )
    ]
  > => {
    const taskMetadata = await oshahippaService.setTaskMetadata(customerNumber, id, status);

    return [taskMetadata, meta];
  }
);

export const getTaskMetadata = createAsyncThunk(
  `${SLICE_PREFIX}/setTaskMetadata`,
  async (customerNumber: string): Promise<Record<number, TrainingChecklistTasksMetadata>> => {
    const training_checklist_task_compliance = await oshahippaService.getTaskCompliances(customerNumber);

    return R.indexBy(training_checklist_task_compliance, R.prop('training_checklist_task_id'));
  }
);

export const getCustomerProgramsAndChecklists = createAsyncThunk(
  `${SLICE_PREFIX}/setCustomerProgramsAndChecklists`,
  async (
    customerNumber: string
  ): Promise<{
    training_checklists: TrainingChecklist[];
    training_programs: Program[];
    customer_osha_hipaa_metadata: CustomerOshaHipaaMetadata[];
    practice_info?: PracticeInfo;
    training_program_forms: TrainingProgramForm[];
    training_program_form_exemptions: TrainingProgramFormExemption[];
  }> => {
    const trainingData = await oshahippaService.getProgramsAndChecklists(customerNumber);

    return trainingData;
  }
);

export const deleteUploadedForm = createAsyncThunk(
  `${SLICE_PREFIX}/deleteUploadedForm`,
  async ({
    customerNumber,
    formId
  }: {
    customerNumber: string;
    formId: number | undefined;
  }): Promise<{
    training_program_forms: TrainingProgramForm[];
  }> => {
    const data = await oshahippaService.deleteUploadedForm(formId, customerNumber);
    return data;
  }
);

export const deleteTrainingProgramForm = createAsyncThunk(
  `${SLICE_PREFIX}/deleteTrainingProgramForm`,
  async ({
    customerNumber,
    formId
  }: {
    customerNumber: string;
    formId: number | undefined;
  }): Promise<{
    training_program_forms: TrainingProgramForm[];
  }> => {
    const data = await oshahippaService.deleteTrainingProgramForm(formId, customerNumber);
    return data;
  }
);

export const uploadForm = createAsyncThunk(
  `${SLICE_PREFIX}/uploadForm`,
  async ({
    customerNumber,
    formId,
    file,
    trainingFormId,
    sectionId,
    programId
  }: {
    customerNumber: string;
    formId: number;
    file: File;
    trainingFormId: number | null;
    sectionId: number;
    programId?: number;
  }): Promise<{
    training_program_forms: TrainingProgramForm[];
    success: boolean;
  }> => {
    const data = await oshahippaService.uploadForm(formId, customerNumber, file, trainingFormId, sectionId, programId);
    return data;
  }
);

export const addTrainingProgramForm = createAsyncThunk(
  `${SLICE_PREFIX}/addTrainingProgramForm`,
  async ({
    customerNumber,
    formId,
    sectionId,
    programId
  }: {
    customerNumber: string;
    formId: number;
    sectionId: number;
    programId?: number;
  }): Promise<{
    training_program_forms: TrainingProgramForm[];
    success: boolean;
  }> => {
    const data = await oshahippaService.addTrainingProgramForm(formId, customerNumber, sectionId, programId);
    return data;
  }
);

export const updateTrainingProgramFormUsers = createAsyncThunk(
  `${SLICE_PREFIX}/updateTrainingProgramFormUsers`,
  async ({
    formId,
    customerNumber,
    trainingProgramForms
  }: {
    formId: number;
    customerNumber: string;
    trainingProgramForms: TrainingProgramFormUserPayload[];
  }): Promise<{
    training_program_forms: TrainingProgramForm[];
    success: boolean;
  }> => {
    const data = await oshahippaService.updateTrainingProgramFormUsers(formId, customerNumber, trainingProgramForms);
    return data;
  }
);

export const updateExemptProgramForm = createAsyncThunk(
  `${SLICE_PREFIX}/updateExemptProgramForm`,
  async ({
    formId,
    customerNumber,
    exempt,
    programId,
    sectionId
  }: {
    formId: number;
    customerNumber: string;
    exempt: boolean;
    programId?: number;
    sectionId: number;
  }): Promise<{
    training_program_form_exemptions: TrainingProgramFormExemption[];
    success: boolean;
  }> => {
    const data = await oshahippaService.updateExemptProgramForm(formId, customerNumber, exempt, sectionId, programId);
    return data;
  }
);

export const updateExemptTrainingProgramForm = createAsyncThunk(
  `${SLICE_PREFIX}/updateExemptTrainingProgramForm`,
  async ({
    trainingFormId,
    customerNumber,
    exempt,
    programId,
    sectionId
  }: {
    trainingFormId: number;
    customerNumber: string;
    exempt: boolean;
    programId: number;
    sectionId: number;
  }): Promise<{
    training_program_forms: TrainingProgramForm[];
    success: boolean;
  }> => {
    const data = await oshahippaService.updateExemptTrainingProgramForm(trainingFormId, customerNumber, exempt, programId, sectionId);
    return data;
  }
);

export const getCompletedChecklists = createAsyncThunk(`${SLICE_PREFIX}/getCompletedChecklists`, (customerNumber: string) => {
  return service.getCompletedChecklists(customerNumber);
});

export const getTraining = createAsyncThunk(
  `${SLICE_PREFIX}/getTraining`,
  async (customerNumber: string): Promise<CustomerTrainingWithCompliance[]> => {
    const teamMembers = await service.getTeamMembers(customerNumber);
    const teamMembersIndex = R.indexBy(teamMembers, R.prop('login_user_id'));
    const customerTraining = await service.getCustomerTraining(customerNumber);
    const userTrainings = await service.getUserTraining(customerNumber);
    const activeUserTrainings = userTrainings.filter((training) => teamMembersIndex[training.user_id]?.account_active);
    const groupedUserTraining = R.groupBy(activeUserTrainings, (ut) => ut.training.id);

    return customerTraining.map((ct) => ({
      ...ct,
      status: R.pipe(groupedUserTraining[ct.training_id] ?? [], R.map(R.prop('compliance_status')), compositeComplianceStatus)
    }));
  }
);

export const getData = createAsyncThunk(`${SLICE_PREFIX}/getData`, async (customerNumber: string, { dispatch }) => {
  await Promise.all([
    dispatch(getCustomerProgramsAndChecklists(customerNumber)),
    dispatch(getTaskMetadata(customerNumber)),
    dispatch(getCompletedChecklists(customerNumber)),
    dispatch(getTraining(customerNumber))
  ]);
  return { customerNumber };
});

export const setCustomerNotesForTask = createAsyncThunk(
  `${SLICE_PREFIX}/setCustomerNotesForTask`,
  ({
    customerNumber,
    taskId,
    newNotes
  }: {
    customerNumber: string;
    taskId: number;
    newNotes: string;
  }): Promise<{
    checklist_id: number;
    section_id: number;
    task_id: number;
    notes: string;
  }> => oshahippaService.setCustomerNotesForTask(customerNumber, taskId, newNotes)
);

export const practiceOshaHipaaSlice = createSlice({
  name: SLICE_PREFIX,
  initialState,
  reducers: {
    setCurrentYear: (state, action: PayloadAction<number>) => {
      state.currentYear = action.payload;
    },
    addCustomerTask: (state, { payload }: PayloadAction<TrainingChecklistTasksMetadata>) => {
      state.taskMetadata[payload.training_checklist_task_id] = payload;
    },
    hideSnackBar(state) {
      state.snackBarMessage = null;
    }
  },
  extraReducers(builder) {
    builder.addCase(certifyChecklistComplete.fulfilled, (state, { payload: { checklistId, certification } }) => {
      state.completedChecklists[checklistId] = certification;
    });

    builder.addCase(decertifyChecklist.fulfilled, (state, { payload }) => {
      delete state.completedChecklists[payload];
    });

    builder.addCase(initTaskMetadata.fulfilled, (state, { payload }) => {
      state.initTaskMetadataState = 'done';
      state.taskMetadata = payload;
    });

    builder.addCase(initTaskMetadata.pending, (state) => {
      state.initTaskMetadataState = 'loading';
    });

    builder.addCase(initTaskMetadata.rejected, (state) => {
      state.initTaskMetadataState = 'error';
    });

    builder.addCase(updateTaskMetadata.fulfilled, (state, { payload: [taskMetaData] }) => {
      state.taskMetadata[taskMetaData.training_checklist_task_id] = taskMetaData;
    });

    builder.addCase(getTaskMetadata.fulfilled, (state, { payload }) => {
      state.taskMetadata = payload;
    });

    builder.addCase(
      setCustomerNotesForTask.fulfilled,
      (
        state,
        {
          payload: { checklist_id, section_id, task_id, notes }
        }: {
          payload: {
            checklist_id: number;
            section_id: number;
            task_id: number;
            notes: string;
          };
        }
      ) => {
        const checklist = state.checklists[checklist_id];
        const section = checklist.sections.find(({ id }) => id === section_id);
        const task = section?.tasks.find(({ id }) => id === task_id);

        if (task) {
          task.customer_notes = notes;
        }
      }
    );

    builder.addCase(getCustomerProgramsAndChecklists.fulfilled, (state, { payload }) => {
      const checklists = payload.training_checklists;
      const sections = checklists.flatMap((c) => c.sections);
      const tasks = sections.flatMap((s) => s.tasks);
      state.checklists = R.indexBy(checklists, R.prop('id'));
      state.checklistSections = R.indexBy(sections, R.prop('id'));
      state.checklistTasks = R.indexBy(tasks, R.prop('id'));

      state.programs = payload.training_programs;
      state.oshaHipaaMetadata = payload.customer_osha_hipaa_metadata;
      state.trainingProgramForms = payload.training_program_forms;
      state.trainingProgramFormExemptions = payload.training_program_form_exemptions;

      state.practiceInfo = payload.practice_info;
    });

    builder.addCase(getCompletedChecklists.fulfilled, (state, { payload }) => {
      state.completedChecklists = R.indexBy(payload, (c) => c.training_checklist_id);
    });

    builder.addCase(getTraining.fulfilled, (state, { payload }) => {
      state.customerTraining = payload;
    });

    builder.addCase(deleteUploadedForm.fulfilled, (state, { payload }) => {
      state.trainingProgramForms = payload.training_program_forms;
    });

    builder.addCase(deleteTrainingProgramForm.fulfilled, (state, { payload }) => {
      state.trainingProgramForms = payload.training_program_forms;
    });

    builder.addCase(uploadForm.fulfilled, (state, { payload }) => {
      state.trainingProgramForms = payload.training_program_forms.length > 0 ? payload.training_program_forms : state.trainingProgramForms;
    });

    builder.addCase(updateTrainingProgramFormUsers.fulfilled, (state, { payload }) => {
      state.trainingProgramForms = payload.training_program_forms;
    });

    builder.addCase(updateExemptProgramForm.fulfilled, (state, { payload }) => {
      state.trainingProgramFormExemptions = payload.training_program_form_exemptions;
    });

    builder.addCase(updateExemptTrainingProgramForm.fulfilled, (state, { payload }) => {
      state.trainingProgramForms = payload.training_program_forms;
    });

    builder.addCase(addTrainingProgramForm.fulfilled, (state, { payload }) => {
      if (payload.success) {
        state.trainingProgramForms = payload.training_program_forms;
      }
    });

    builder.addMatcher(isPending(getData), (state) => {
      state.loadingState = 'loading';
    });

    builder.addMatcher(isRejected(getData), (state) => {
      state.loadingState = 'error';
    });

    builder.addMatcher(isFulfilled(getData), (state, { payload }) => {
      state.loadingState = 'done';
    });

    builder.addMatcher(isAnyOf(getTraining.rejected), (state) => {
      state.snackBarMessage = {
        type: 'error',
        message: 'Something went wrong. Please try again'
      };
    });
  }
});

export const selectSnackbarMessage = (state: RootState) => state.practiceDE.snackBarMessage;

export const { setCurrentYear, addCustomerTask, hideSnackBar } = practiceOshaHipaaSlice.actions;

export const selectCurrentYear = (state: RootState) => state.practiceDE.currentYear;

export const selectPrograms = (state: RootState) => state.practiceDE.programs;

export const selectChecklists = (state: RootState) => Object.values(state.practiceDE.checklists);
export const selectChecklistMap = (state: RootState) => state.practiceDE.checklists;

export const selectTaskMetadata = (state: RootState) => state.practiceDE.taskMetadata;

const selectCurrentYearPrograms = createSelector(
  [selectPrograms, selectCurrentYear],
  (programs, currentYear) => programs?.filter((p) => p.year === currentYear) ?? []
);

export const selectCurrentYearDirections = createSelector(selectCurrentYearPrograms, (programs) =>
  R.pipe(
    programs,
    R.filter((p) => !!p.directions_url),
    R.map(R.pick(['program_id', 'directions_url', 'name'])),
    R.sortBy(R.prop('name'))
  )
);

export const selectProgramsByYear = createSelector(
  [selectPrograms, (state: RootState, year?: string) => year],
  (programs, year) => (year ? programs?.filter((p) => p.year + '' === year) : programs) ?? []
);

const selectComponentsByYear = createSelector(selectProgramsByYear, (programs) => programs.flatMap((p) => p.components));

const selectGroupedComponentsByYear = createSelector(
  selectComponentsByYear,
  R.groupBy((c) => c.component_type?.name)
);

export const selectGroupedSectionsByYear = createSelector(
  selectGroupedComponentsByYear,
  R.mapValues((cs) => cs.flatMap((c) => c.sections))
);

const selectComponentsByYearAndComponentTypeName = createSelector(
  [selectGroupedComponentsByYear, (state: RootState, year?: string, componentTypeName?: string) => componentTypeName],
  (groupedComponents, componentTypeName) => groupedComponents[componentTypeName ?? ''] ?? []
);

export const selectSectionsByYearAndComponentTypeName = createSelector(
  selectComponentsByYearAndComponentTypeName,
  R.flatMap((c) => c.sections)
);

export const selectProgramComponentForms = createSelector(selectSectionsByYearAndComponentTypeName, (sections) =>
  sections
    .map((s) => {
      const uploadFormTypes = s.section_form_relations.filter(
        (s) =>
          s.deleted_date === null &&
          s.form.deleted_date === null &&
          (s.form.form_type === 'Upload Required' || s.form.form_type === 'Upload Optional')
      );
      return { ...s, section_form_relations: uploadFormTypes };
    })
    .filter((s) => s.section_form_relations.length > 0)
);

export const selectProgramByComponentNameAndYear = createSelector(
  [selectCurrentYearPrograms, (state: RootState, year?: string, componentTypeName?: string) => componentTypeName],
  (programs, componentTypeName) => programs.find((p) => p.components.filter((c) => c.component_type.name === componentTypeName))
);

export const selectTrainingProgramForms = (state: RootState) => state.practiceDE.trainingProgramForms;

export const selectTrainingProgramFormExemptions = (state: RootState) => state.practiceDE.trainingProgramFormExemptions;

export const selectComponentSectionCompliance = (state: RootState, { checklist_section_relations }: ProgramComponentSection) => {
  if (!checklist_section_relations.length) {
    return;
  }

  const checklistSectionCompliance = checklist_section_relations.map(({ checklist_section_id }) =>
    selectChecklistSectionComplianceById(state, checklist_section_id)
  );

  return compositeComplianceStatus(checklistSectionCompliance);
};

const selectChecklistsByYear = createSelector(
  [selectChecklists, (state: RootState, year?: string) => year],
  (checklists, year) => checklists?.filter((c) => JSON.stringify(new Date(c.year).getUTCFullYear()) === year) ?? []
);

export const selectChecklistsByYearAndComponentTypeId = createSelector(
  [selectChecklistsByYear, (state: RootState, year?: string, componentTypeId?: number) => componentTypeId],
  (checklists, componentTypeId) =>
    R.pipe(
      checklists,
      R.filter((checklist) => checklist.component_type_id === componentTypeId)
    )
);

export const selectChecklistByYearAndComponentTypeIdAndType = createSelector(
  [selectChecklistsByYearAndComponentTypeId, (state: RootState, year?: string, componentTypeId?: number, type?: TrainingChecklistType) => type],
  (checklists, type) => checklists?.find((checklist) => checklist.type === type)
);

const selectChecklistSections = (state: RootState) => state.practiceDE.checklistSections;

const selectChecklistSectionsByYear = createSelector([selectChecklistsByYear, selectChecklistSections], (checklists, sections) => {
  const checklistIdMap = R.indexBy(checklists, R.prop('id'));

  return R.pipe(
    sections,
    R.values,
    R.filter((s) => !!checklistIdMap[s.checklist_id])
  );
});

export const selectChecklistSectionsByYearAndComponentTypeId = createSelector(
  [selectChecklistsByYearAndComponentTypeId, selectChecklistSections],
  (checklists, sections) => {
    const checklistIdMap = R.indexBy(checklists, R.prop('id'));

    return R.pipe(
      sections,
      R.values,
      R.filter((s) => !!checklistIdMap[s.checklist_id])
    );
  }
);

const selectChecklistTasks = (state: RootState) => state.practiceDE.checklistTasks;

export const selectChecklistSectionsByChecklistId = createSelector(
  [selectChecklistSections, (state: RootState, checklistId: number) => checklistId],
  (sections, checklistId) =>
    R.pipe(
      sections,
      R.values,
      R.filter((s) => s.checklist_id === checklistId),
      R.indexBy(R.prop('id'))
    )
);

const selectChecklistTasksByChecklistId = createSelector([selectChecklistSectionsByChecklistId, selectChecklistTasks], (sections, tasks) => {
  return R.pipe(
    tasks,
    R.values,
    R.filter((t) => !!sections[t.section_id])
  );
});

export const selectCustomerChecklistTaskMap = createSelector(
  selectTaskMetadata,
  (taskMetadata): Record<number, TrainingChecklistTasksMetadata> => R.indexBy(R.values(taskMetadata), R.prop('training_checklist_task_id'))
);

export const selectChecklistTasksBySectionId = createSelector(
  [selectChecklistTasks, (state: RootState, checklistSectionId: number) => checklistSectionId],
  (tasks, id) =>
    R.pipe(
      tasks,
      R.values,
      R.filter((t) => t.section_id === id)
    )
);

export const selectChecklistTasksByYear = createSelector([selectChecklistSectionsByYear], (sections) => sections.flatMap(R.prop('tasks')));

export const selectChecklistTasksByYearAndComponentTypeId = createSelector([selectChecklistSectionsByYearAndComponentTypeId], (sections) =>
  sections.flatMap(R.prop('tasks'))
);

export const isSelectedYearStarted = createSelector(
  [selectChecklistTasksByYear, selectCustomerChecklistTaskMap],
  (checklistTasks, checklistTaskComplianceMap) => {
    return checklistTasks.some(({ id }) => !!checklistTaskComplianceMap[id]);
  }
);

export const selectChecklistSectionComplianceById = createSelector(
  [selectChecklistTasksBySectionId, selectCustomerChecklistTaskMap],
  (tasks, customerTaskMap) =>
    R.pipe(
      tasks,
      R.map(({ id }) => customerTaskMap[id]?.compliance_status ?? ComplianceStatus.NotStarted),
      compositeComplianceStatus
    )
);

export const selectChecklistIsInCompliance = createSelector(
  [selectChecklistTasksByChecklistId, selectCustomerChecklistTaskMap],
  (tasks, customerTaskMap) => tasks.every((t) => taskIsCompliant(customerTaskMap[t.id]))
);

export const selectCustomerChecklistTaskById = createSelector(
  [selectCustomerChecklistTaskMap, (state: RootState, taskId?: number) => taskId],
  (taskMap, taskId) => taskMap[taskId ?? 0]
);

export const selectCustomerChecklistTaskComplianceById = createSelector(
  [selectCustomerChecklistTaskById],
  (task) => task?.compliance_status ?? ComplianceStatus.NotStarted
);

const selectCompletedChecklists = (state: RootState) => state.practiceDE.completedChecklists;

export const selectChecklistCertificationByChecklistId = createSelector(
  [selectCompletedChecklists, selectChecklistMap, (state: RootState, checklistId?: number) => checklistId],
  (completedChecklists, checklistMap, checklistId): ChecklistCertification | undefined => {
    if (!checklistId) {
      return;
    }
    return (
      completedChecklists[checklistId] && {
        ...completedChecklists[checklistId],
        checklistType: checklistMap[checklistId]?.type
      }
    );
  }
);

export const selectChecklistCertifications = createSelector(
  [selectCompletedChecklists, (state: RootState, checklists: TrainingChecklist[]) => checklists],
  (certifications, checklists): ChecklistCertification[] =>
    checklists
      .map(
        (c) =>
          certifications[c.id] && {
            ...(certifications[c.id] || {}),
            checklistType: c.type
          }
      )
      .filter((c) => c)
);

export const selectChecklistCompliance = createSelector(
  [
    (state: RootState, checklistId: number) => checklistId,
    selectCompletedChecklists,
    selectChecklistTasksByChecklistId,
    selectCustomerChecklistTaskMap
  ],
  (checklistId, completedChecklists, checklistTasks, checklistTaskComplianceMap) => {
    if (completedChecklists[checklistId]) {
      return ComplianceStatus.Complete;
    }

    const compositeStatus = R.pipe(
      checklistTasks,
      R.map(({ id }) => checklistTaskComplianceMap[id]?.compliance_status ?? ComplianceStatus.NotStarted),
      compositeComplianceStatus
    );

    // checklist is not complete until certified, even if all tasks are complete
    if (compositeStatus === ComplianceStatus.Complete) {
      return ComplianceStatus.InProgress;
    }

    return compositeStatus;
  }
);

export const selectComponentCompliance = createSelector(
  [selectChecklistsByYearAndComponentTypeId, selectCompletedChecklists, selectChecklistTasksByYearAndComponentTypeId, selectCustomerChecklistTaskMap],
  (checklists, completedChecklists, checklistTasks, checklistTaskComplianceMap) => {
    if (checklists.every(({ id }) => completedChecklists[id])) {
      return ComplianceStatus.Complete;
    }

    const compositeStatus = R.pipe(
      checklistTasks,
      R.map(({ id }) => checklistTaskComplianceMap[id]?.compliance_status ?? ComplianceStatus.NotStarted),
      compositeComplianceStatus
    );

    // component is not complete until checklists are certified, even if all tasks are complete
    if (compositeStatus === ComplianceStatus.Complete) {
      return ComplianceStatus.InProgress;
    }

    return compositeStatus;
  }
);

// composite status of all tasks in a year - EXCLUDES certifications
export const selectYearTaskCompliance = createSelector(
  [selectChecklistTasksByYear, selectCustomerChecklistTaskMap],
  (checklistTasks, checklistTaskComplianceMap) =>
    R.pipe(
      checklistTasks,
      R.map(({ id }) => checklistTaskComplianceMap[id]?.compliance_status ?? ComplianceStatus.NotStarted),
      compositeComplianceStatus
    )
);

// composite status of all components in a year, INCLUDES certifications
export const selectYearComponentCompliance = (state: RootState, year?: string) =>
  R.pipe(
    selectGroupedComponentsByYear(state, year),
    Object.keys,
    R.map((component) => selectComponentCompliance(state, year, componentTypeNameToId(component))),
    compositeComplianceStatus
  );

export const selectLoadingState = (state: RootState) => state.practiceDE.loadingState;

export const selectCustomerTraining = (state: RootState) => state.practiceDE.customerTraining;

export const selectCustomerTrainingByYear = createSelector(
  [selectCustomerTraining, (state: RootState) => state.practiceDE.currentYear],
  (trainings, currentYear) => {
    return R.pipe(
      trainings,
      R.filter((training) => training?.training?.year === currentYear)
    );
  }
);

export const selectTrainingCompliance = createSelector(selectCustomerTrainingByYear, (trainings) =>
  R.pipe(trainings, R.map(R.prop('status')), compositeComplianceStatus)
);

export const selectComplianceForDonut = createSelector(
  [selectTaskMetadata, selectCustomerTrainingByYear, selectChecklists, (state: RootState) => state.practiceDE.currentYear],
  (tasks, training, checklists, selectCurrentYear) => {
    const getChecklistTaskIds = checklists
      .filter((checklist) => new Date(checklist.year).getUTCFullYear() === selectCurrentYear)
      .flatMap((checklist) => checklist.sections)
      .flatMap((section) => section.tasks)
      .map((task) => task.id);

    const filteredTasks = Object.values(tasks).filter((task) => getChecklistTaskIds.includes(task.training_checklist_task_id));

    return [
      ...filteredTasks.map((value) => ({
        compliance: {
          status: value.compliance_status
        }
      })),
      ...training.map((t) => ({
        compliance: {
          status: t.status
        }
      }))
    ];
  }
);

export const selectYearOverallCompliance = createSelector(
  [selectYearComponentCompliance, selectTrainingCompliance],
  (componentCompliance, trainingCompliance) => compositeComplianceStatus([componentCompliance, trainingCompliance])
);
export const selectInitMetadataState = (state: RootState) => state.practiceDE.initTaskMetadataState;

export const selectCustomerOshaHipaaMetadata = (state: RootState, year: string) =>
  state.practiceDE.oshaHipaaMetadata.find((metadata) => metadata.year === Number(year));

export const selectPracticeInfo = (state: RootState) => state.practiceDE.practiceInfo;

export default practiceOshaHipaaSlice.reducer;

export const oshaHippaMiddleware = createListenerMiddleware();

const keepCertifiedStatuses = new Set([ComplianceStatus.Complete, ComplianceStatus.NotApplicable]);

// Listen for changes that can decertify a checklist
oshaHippaMiddleware.startListening({
  actionCreator: updateTaskMetadata.fulfilled,
  effect: ({ payload }, { getState, dispatch }) => {
    const [taskMetaData, meta] = payload;

    if (keepCertifiedStatuses.has(taskMetaData.compliance_status) || !meta) {
      return;
    }

    const { year, componentTypeName, checklistType } = meta;
    const componentTypeId = componentTypeNameToId(componentTypeName);

    const state = getState() as RootState;

    const checklist = selectChecklistByYearAndComponentTypeIdAndType(state, year, componentTypeId, checklistType);
    if (!checklist) {
      return;
    }

    const certification = selectChecklistCertificationByChecklistId(state, checklist.id);
    if (!certification) {
      return;
    }

    dispatch(decertifyChecklist(checklist.id));
  }
});

// Check for newly-created/missing items and update their status
oshaHippaMiddleware.startListening({
  actionCreator: getData.fulfilled,
  effect: async (_, { getState, dispatch }) => {
    const state = getState() as RootState;

    // check the compliance for the current year.
    const year = selectCurrentYear(state)?.toString();
    const isYearStarted = isSelectedYearStarted(state, year);
    const compliance_status = selectYearOverallCompliance(state, year);

    // if either of these states bail.
    if (isYearStarted == false || compliance_status === ComplianceStatus.NotApplicable) return;

    // add new states if the year has started and tasks were-added/are-missing after starting.
    const tasks = selectChecklistTasks(state);
    const task_metas = selectCustomerChecklistTaskMap(state);
    const customer_number = selectCurrentCustomerNumber(state);

    // go through each task and make sure there is a compliance status entry for the task
    Object.values(tasks).forEach((task) => {
      if (!task_metas[task.id]) {
        dispatch(
          updateTaskMetadata({
            id: task.id,
            customerNumber: customer_number,
            status: ComplianceStatus.InProgress
          })
        );
      }
    });
  }
});
