import { addYears, format } from "date-fns";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { User, UserAccessLevels } from "../../../redux/user";

export const formatStandardDate = (date: string | undefined | null | Date) => {
  if (!date) return '';

  const cleanDate = typeof date === 'string' ? date.replace(/Z$/, '') : date;

  return dayjs(cleanDate).format('MM/DD/YYYY');
};

export const formatUtcDate = (utcDate: string | undefined | null | Date) => {
  if (!utcDate) return '';

  dayjs.extend(utc);
  dayjs.extend(localizedFormat);

  return dayjs.utc(utcDate).local().format('MM/DD/YYYY');
};

export const getReplacementDate = (date: string | undefined) => {
  if (!date) return '';

  return dayjs(date).add(1, 'year').format('MM/DD/YYYY');
};

export const getUserPermission = (roles: UserAccessLevels[], user: User | null) => {
  const userRole = user?.access_level
  if (!userRole) return false
  const hasPermission = roles.some(role => role === userRole)
  return hasPermission
}

export const getWaterLinePermissions = (user: User | null) => {
  const permissions = {
    inOffice: {
      add: [UserAccessLevels.Owner, UserAccessLevels.Admin, UserAccessLevels.TeamMember],
      edit: [UserAccessLevels.Owner, UserAccessLevels.Admin, UserAccessLevels.TeamMember],
      delete: [UserAccessLevels.Owner, UserAccessLevels.Admin],
    },
    roomChair: {
      add: [UserAccessLevels.Owner, UserAccessLevels.Admin, UserAccessLevels.TeamMember],
      assign: [UserAccessLevels.Owner, UserAccessLevels.Admin, UserAccessLevels.TeamMember],
      edit: [UserAccessLevels.Owner, UserAccessLevels.Admin, UserAccessLevels.TeamMember],
      delete: [UserAccessLevels.Owner, UserAccessLevels.Admin, UserAccessLevels.TeamMember],
      view: [UserAccessLevels.Owner, UserAccessLevels.Admin, UserAccessLevels.TeamMember],
    },
  }

  const userPermissions = {
    inOffice: {
      add: getUserPermission(permissions.inOffice.add, user),
      edit: getUserPermission(permissions.inOffice.edit, user),
      delete: getUserPermission(permissions.inOffice.delete, user),
    },
    roomChair: {
      add: getUserPermission(permissions.roomChair.add, user),
      assign: getUserPermission(permissions.roomChair.assign, user),
      edit: getUserPermission(permissions.roomChair.edit, user),
      delete: getUserPermission(permissions.roomChair.delete, user),
      view: getUserPermission(permissions.roomChair.view, user),
    },
  }

  return userPermissions
}