import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../../redux/user';
import axios from 'axios';
import PageHeader from '../../../components/PageHeader';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Snackbar from '@mui/material/Snackbar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TrainingComplianceReport from './TrainingComplianceReport';
import TrainingDue from './TrainingDue';
import TrainingPastDue from './TrainingPastDue';
import { trackPromise } from 'react-promise-tracker';
import constants from '../../../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { standardDateFormat } from '../../../helpers/date';
import sortObject from '../../../helpers/sort';

const initUserTrainingStatus = {
  pastDue: 0,
  dueSoon: 0,
  completed: 0
};

function TrainingDashboard() {
  const currentUser = useSelector(selectCurrentUser);
  const pageTabs = ['Compliance Report', 'Past Due', 'Training Due'];
  const [courses, setCourses] = useState([]);
  const [customerTrainings, setCustomerTrainings] = useState([]);
  const [exportOpenEl, setExportOpenEl] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [userTrainingStatus, setUserTrainingStatus] = useState(initUserTrainingStatus);
  const exportOpen = Boolean(exportOpenEl);

  const getComplianceStatus = (code) => {
    if (code === 1) {
      return 'Completed';
    } else if (code === 2) {
      return 'Training Due';
    } else if (code === 3) {
      return 'Past Due';
    } else {
      return 'Inactive';
    }
  };

  const getCustomers = async () => {
    const customerNumber = currentUser.customer_number;
    if (!customerNumber) {
      return false;
    }

    const query = {
      include: {
        relation: 'children',
        scope: {
          order: 'name',
          where: {
            inactive: false,
          },
          include: [
            {
              relation: 'customer_training_metadata'
            },
            {
              relation: 'users_training',
              scope: {
                where: {
                  is_active: 1
                },
                order: ['user_id ASC', 'enrollment_date DESC'],
                include: [
                  {
                    relation: 'training'
                  }
                ]
              },
            },
          ],
        },
      },
    };

    return await axios
      .get(`${constants.ONTRAQ_API_URL}/api/Customers/${customerNumber}?filter=${JSON.stringify(query)}`)
      .then((res) => {
        const customers = res.data.children;
        const trainingStatus = { ...initUserTrainingStatus };
        let courses = {};
        customers.forEach((customer) => {
          customer.training_compliance_status = customer?.customer_training_metadata?.training_compliance_status || 4;
          customer.training_completed_count = 0;
          customer.training_due_soon_count = 0;
          customer.training_past_due_count = 0;

          if (customer.users_training.length > 0) {
            customer.users_training.forEach((course) => {
              if (!courses.hasOwnProperty(course.course_id)) {
                courses[course.course_id] = course?.training?.name || 'Unknown';
              }

              if (course.compliance_status === 1) {
                customer.training_completed_count++;
              } else if (course.compliance_status === 2) {
                customer.training_due_soon_count++;
              } else if (course.compliance_status === 3) {
                customer.training_past_due_count++;
              }
            });
          }
          trainingStatus.completed += customer.training_completed_count;
          trainingStatus.dueSoon += customer.training_due_soon_count;
          trainingStatus.pastDue += customer.training_past_due_count;
        });

        courses = Object.keys(courses).map((key) => {
          return {
            name: courses[key],
            value: key
          };
        }).sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0);

        setCourses([...courses]);
        setUserTrainingStatus({ ...trainingStatus });
        setCustomerTrainings([...customers]);
      })
      .catch((error) => {
        console.error(error);
        return false;
      });
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleAllTraining = async () => {
    handleExportClose();
    const customerNumber = currentUser.customer_number;
    const wb = XLSX.utils.book_new();
    let data = [];
    let trainingCourses = {};

    const query = {
      include: {
        relation: 'children',
        scope: {
          order: 'name',
          where: {
            inactive: false,
          },
          include: [
            {
              relation: 'users_training',
              scope: {
                order: ['user_id ASC', 'enrollment_date DESC'],
                include: [
                  {
                    relation: 'user'
                  },
                  {
                    relation: 'training'
                  }
                ]
              },
            },
          ],
        },
      },
    };

    const trainings = await axios
      .get(`${constants.ONTRAQ_API_URL}/api/Customers/${customerNumber}?filter=${JSON.stringify(query)}`)
      .then((res) => {
        return res.data.children;
      })
      .catch((error) => {
        console.error(error);
        return false;
      });

    trainings.forEach((customer) => {
      if (customer.users_training) {
        customer.users_training.forEach((ut) => {
          const trainingName = ut?.training?.name || 'Unknown';
          if (trainingName && !trainingCourses.hasOwnProperty(trainingName)) {
            trainingCourses[trainingName] = [];
          }

          if (ut.compliance_status !== 4) {
            const userName = ut?.user?.firstname ? `${ut.user.firstname} ${ut.user.lastname}` : ut.user.email;
            const year = ut?.training?.year;

            const formattedTraining = {
              Status: getComplianceStatus(ut.compliance_status),
              'Customer Number': customer.customer_number,
              'Practice Name': customer.name.trim(),
              Street: customer.street.trim(),
              City: customer.city.trim(),
              State: customer.state.trim(),
              Zip: customer.zip.trim(),
              'Team Member': userName,
              Year: year ? `${year}` : '',
              'Course Name': trainingName,
              Enrolled: standardDateFormat(ut.enrollment_date),
              Completed: standardDateFormat(ut.completion_date),
              'CE Credits Earned': ut.completion_date && ut?.training?.ce_credits || ''
            };

            trainingCourses[trainingName] = [...trainingCourses[trainingName], formattedTraining];

            data = [...data, formattedTraining];
          }
        });
      }
    });

    if (data.length) {
      const ws = XLSX.utils.json_to_sheet(data);

      XLSX.utils.book_append_sheet(wb, ws, 'All Training');

      trainingCourses = sortObject(trainingCourses);

      for (let course in trainingCourses) {
        const ws = XLSX.utils.json_to_sheet(trainingCourses[course]);
        XLSX.utils.book_append_sheet(wb, ws, course.replace(/[^a-zA-Z0-9 ]/g, '').slice(0, 15));
      }

      XLSX.writeFile(wb, 'All_Training.xlsx');
    } else {
      handleOpenAlert();
    }
  };

  const handleTrainingByPractice = async () => {
    handleExportClose();
    const customerNumber = currentUser.customer_number;
    const wb = XLSX.utils.book_new();
    let customerTrainingCount = 0;

    const query = {
      include: {
        relation: 'children',
        scope: {
          order: 'name',
          where: {
            inactive: false,
          },
          include: [
            {
              relation: 'users_training',
              scope: {
                order: ['user_id ASC', 'enrollment_date DESC'],
                include: [
                  {
                    relation: 'user'
                  },
                  {
                    relation: 'training'
                  }
                ]
              },
            },
          ],
        },
      },
    };

    const trainings = await axios
      .get(`${constants.ONTRAQ_API_URL}/api/Customers/${customerNumber}?filter=${JSON.stringify(query)}`)
      .then((res) => {
        return res.data.children;
      })
      .catch((error) => {
        console.error(error);
        return false;
      });

    trainings.forEach((customer) => {
      if (customer.users_training.length > 0) {
        customerTrainingCount++;
        let customerTrainings = [];
        const customerDetails = [
          {
            A: customer.name.trim(),
          },
          {
            A: customer.doctor_name,
          },
          {
            A: customer.street.trim(),
          },
          {
            A: `${customer.city.trim()} ${customer.state.trim()} ${customer.zip.trim()}`,
          },
          {
            A: `Customer #: ${customer.customer_number}`,
          },
          {
            A: '',
          },
        ];

        const ws = XLSX.utils.json_to_sheet(customerDetails, { skipHeader: true });

        customer.users_training.forEach((ut) => {
          const trainingName = ut?.training?.name || 'Unknown';

          if (ut.compliance_status !== 4) {
            const userName = ut?.user?.firstname ? `${ut.user.firstname} ${ut.user.lastname}` : ut.user.email;
            const year = ut?.training?.year;

            const formattedTraining = {
              Status: getComplianceStatus(ut.compliance_status),
              'Team Member': userName,
              Year: year ? `${year}` : '',
              'Course Name': trainingName,
              Enrolled: standardDateFormat(ut.enrollment_date),
              Completed: standardDateFormat(ut.completion_date),
              'CE Credits Earned': ut.completion_date && ut?.training?.ce_credits || ''
            };

            customerTrainings = [...customerTrainings, formattedTraining];
          }
        });
        XLSX.utils.sheet_add_json(ws, customerTrainings, { origin: -1 });

        const sheetName = `${customer.name.trim().slice(0, 13)}_${customer.customer_number}`;
        XLSX.utils.book_append_sheet(wb, ws, sheetName);
      }
    });

    if (customerTrainingCount) {
      XLSX.writeFile(wb, 'Training_By_Practice.xlsx');
    } else {
      handleOpenAlert();
    }
  };

  const handleExportClick = (event) => {
    setExportOpenEl(event.currentTarget);
  };

  const handleExportClose = () => {
    setExportOpenEl(null);
  };

  useEffect(() => {
    trackPromise(getCustomers());
  }, [currentUser]);

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const tabPages = [
    <TrainingComplianceReport key="0" customerTrainings={customerTrainings} userTrainingStatus={userTrainingStatus} courses={courses} />,
    <TrainingPastDue key="1" />,
    <TrainingDue key="2" />
  ];

  return (
    <div>
      <Snackbar open={openAlert} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={5000} onClose={handleCloseAlert}>
        <Alert severity="info">There are no trainings to download</Alert>
      </Snackbar>

      <PageHeader iconName={'graduation-cap'} headerName={'Training'} />
      <div>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="flex flex-space-between flex-align-center">
            <Tabs value={tabIndex} onChange={handleChange} aria-label="basic tabs example">
              {
                pageTabs.length && pageTabs.map((tab, index) => (
                  <Tab key={index} label={tab} {...index} />
                ))
              }
            </Tabs>
            <div className="dropdown">
              <Button
                id="export-button"
                className='m-r-12'
                aria-haspopup="true"
                variant="small"
                name="export"
                onClick={handleExportClick}
              >
                Export
                <FontAwesomeIcon icon="caret-down" size="sm" className="m-l-8" />
              </Button>
              <Menu
                id="account-menu"
                anchorEl={exportOpenEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                open={exportOpen}
                onClose={handleExportClose}
                MenuListProps={{
                  'aria-labelledby': 'export-button'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
              >
                <MenuItem onClick={handleAllTraining}>
                  All Training
                </MenuItem>
                <MenuItem onClick={handleTrainingByPractice}>
                  Training by Course Type
                </MenuItem>
              </Menu>
            </div>
          </Box>
          <div className='m-12'>
            {
              tabPages.length && tabPages.filter((page, index) => index === tabIndex)
            }
          </div>
        </Box>
      </div>
    </div>
  );
}

export default TrainingDashboard;
