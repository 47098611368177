import axios from 'axios';
import { Button, Dialog } from '@mui/material';
import CloseModalButton from '../../../../components/CloseModalButton';
import { StyledDialogContent, StyledDialogFooter, StyledDialogTitle } from '../../../../components/Dialog.styled';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { WaterlineTestResult } from '../types';
import constants from '../../../../constants';
import { useState } from 'react';

interface DeleteTestResultModalProps {
  onClose: (refresh?: boolean) => void;
  onAlert: (message: string) => void;
  isOpen: boolean;
  testResult: WaterlineTestResult | null;
}

const DeleteTestResultModal: React.FC<DeleteTestResultModalProps> = ({ onClose, onAlert, isOpen, testResult }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async () => {
    setLoading(true);

    const utcTimeStamp = new Date().toUTCString();
    const testResultCopy = { ...structuredClone(testResult), deleted_date: utcTimeStamp };

    try {
      await axios.put(`${constants.ONTRAQ_API_URL}/api/Waterline_test_results/${testResult?.test_result_id}/inOfficeWaterlineTestResult`, {
        ...testResultCopy
      });
      onClose(true);
    } catch (error) {
      const message = 'There was an error deleting the In-Office Result';
      console.error(message);
      onAlert(message);
      onClose(false);
    }

    setLoading(false);
  };

  return (
    <Dialog open={isOpen} onClose={() => onClose(false)} fullWidth>
      <CloseModalButton handleClose={() => onClose(false)} />
      <StyledDialogTitle>Delete In-Office Result</StyledDialogTitle>
      <StyledDialogContent>
        <div className="flex flex-center m-b-16">
          <DeleteIcon fontSize="large" />
        </div>
        <div className="m-b-16 center">
          Are you sure you want to delete the logged In-Office Result?
        </div>
      </StyledDialogContent>
      <StyledDialogFooter>
        <Button onClick={() => onClose(false)}>Cancel</Button>
        <Button variant="contained" onClick={handleSubmit} disabled={loading}>
          Delete
        </Button>
      </StyledDialogFooter>
    </Dialog>
  );
};

export default DeleteTestResultModal;
