import { Button } from '@mui/material'
import { Dispatch, SetStateAction } from 'react'
import { OpenGroupModal } from '../../../types'

type NoGroupsProps = {
  setOpenModal: Dispatch<SetStateAction<OpenGroupModal>>,
}

const NoGroups = (props: NoGroupsProps) => {
  const { setOpenModal } = props

  const handleOpenCreate = () => {
    setOpenModal({ open: true, type: 'create' })
  }

  return (
    <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
      <div className="m-b-16" style={{textAlign: 'center', flexBasis: '100%' }}>
        You have not created any groups yet. Grouping your practices allows you to filter OnTraq's corporate reports by region, specialty, or however else you choose.
      </div>
      <Button
        variant="contained"
        onClick={handleOpenCreate}
      >
        Create a Group
      </Button>
    </div>
  )
}

export default NoGroups
