import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchUserTimezone, login } from './redux/user';
import { setCorporateView, selectCustomerNumber, setCurrentCustomerNumber, setCustomer, getTeamMembers } from './redux/customer';
import { clearChildCustomer } from './redux/childCustomer';
import './App.css';
import globalTheme from './themes/theme';
import { LiveChatWidget } from '@livechat/widget-react';
import { RouterProvider, useLocation, createBrowserRouter, Outlet, useParams } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import * as icons from './icons/fontawesome';
import Navbar from './components/Navbar';
import PracticeSidebar from './components/PracticeSidebar';
import CorporateSidebar from './components/CorporateSideBar';
import Footer from './components/Footer';
import AuthService from './services/authorization';
import { getCustomer } from './services/customerService';
import constants from './constants';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import practiceRoutes from './routes/practice';
import corporateRoutes from './routes/corporate';
import { useAppSelector } from './redux/store';

import manualRoutes from './routes/manuals';
import pdfRoutes from './routes/pdf';
import Faq from './views/common/Faq';
import Terms from './views/common/Terms';

library.add(fab, far, icons);

const queryClient = new QueryClient();

function SidebarWrapper() {
  const location = useLocation();
  return (
    <>
      {location.pathname.includes('/practice') && !location.pathname.includes('/corp') && <PracticeSidebar />}
      {location.pathname.includes('/corp') && <CorporateSidebar />}
    </>
  );
}

const LayoutWrapper = () => {
  const { promiseInProgress } = usePromiseTracker();

  let { customerNumber: routeCustomerNumber } = useParams();
  const dispatch = useDispatch();
  const storeCustomerNumber = useAppSelector(selectCustomerNumber);

  useEffect(() => {
    if (routeCustomerNumber) {
      dispatch(setCurrentCustomerNumber(routeCustomerNumber));
      dispatch(setCorporateView(true));
    } else {
      dispatch(clearChildCustomer());
      dispatch(setCurrentCustomerNumber(storeCustomerNumber));
      dispatch(setCorporateView(false));
    }
  }, [routeCustomerNumber, storeCustomerNumber]);

  return (
    <div className="App">
      <Navbar></Navbar>
      <div className="content-container">
        {/* Sidebar rendering based on route url */}
        <SidebarWrapper />
        <div className="body-header-container">
          <div>
            {promiseInProgress && <LinearProgress sx={{ width: '100%', position: 'fixed', top: '50px', left: '0', right: '0', zIndex: '1000' }} />}
            <Outlet />
          </div>
          <Footer></Footer>
        </div>
      </div>
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: '/faq',
    element: <Faq />
  },
  {
    path: '/terms',
    element: <Terms />
  },
  // Manual routes need to be without the layout
  ...manualRoutes,
  ...pdfRoutes,
  {
    path: '/',
    element: <LayoutWrapper />,
    children: [
      {
        index: true,
        Component: () => window.location.replace(`${constants.ONTRAQ_WEB_URL}/webapp/#/`)
      },
      ...practiceRoutes,
      ...corporateRoutes
    ]
  }
]);

function App() {
  const Auth = new AuthService();
  const dispatch = useDispatch();

  const checkSession = async () => {
    let currentUser = Auth.getCurrentUser();

    if (!currentUser || currentUser.customer_number !== Auth.getCurrentCustomerNumber() || parseInt(currentUser.login_user_id) !== parseInt(Auth.getUserId())) {
      currentUser = await trackPromise(Auth.createSession());
    }

    dispatch(login(currentUser));
    dispatch(fetchUserTimezone());
    const customer = await trackPromise(getCustomer(currentUser));
    dispatch(getTeamMembers(customer.customer_number));
    dispatch(setCustomer(customer));
  };

  useEffect(() => {
    if (Auth.loggedIn()) {
      checkSession();
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={globalTheme}>
        <RouterProvider router={router} />
      </ThemeProvider>
      {constants.ENV === 'production' && <LiveChatWidget license={constants.LIVE_CHAT_LICENSE} group={constants.LIVE_CHAT_GROUP} />}
      {constants.ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
  );
}

export default App;
