import ManualWrapper from './ManualWrapper';
import { getHipaaManual } from './services';
import DownloadFormButton from './DownloadFormButton';
import { useYearAndCustomerNumber } from './hooks';

const Hipaa = () => {
  const { year, customerNumber } = useYearAndCustomerNumber();

  return (
    <ManualWrapper>
      <h1>Required HIPAA Manual</h1>
      <h2 className="center">Download and complete all items in your manual.</h2>
      <p className="center">
        Download one copy. If you have purchased a premium package, you will walk through this document with your trainer. Each Section in the manual
        must be completed.
      </p>
      <p className="center color-danger bold">To mark this status as Complete. Please go to the "Checklist" in your OSHA portion. Then check off all items you have completed in the Tab that says HIPAA E-Manual.</p>
      <hr />
      <ul>
        <li>
          Federal Annual HIPAA Manual
          <DownloadFormButton disabled={!customerNumber} getFileHandler={() => getHipaaManual(customerNumber ?? '', year)} />
        </li>
        <hr />
      </ul>
    </ManualWrapper>
  );
};

export default Hipaa;
