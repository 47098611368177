import { KeyboardArrowRight, KeyboardArrowUp } from "@mui/icons-material"
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material"
import React, { useState } from "react"
import { WaterlineTestResult } from "../types"

const NextStepsAccordion = (props: {
  testResults: WaterlineTestResult[]
}) => {

  const [expanded, setExpanded] = useState<boolean>(false);
  const { testResults } = props;

  return (
    <Accordion
      className="next-steps-accordion"
      sx={{
        margin: 0,
        border: expanded ? '1px solid #b94a48' : 'unset',
      }}
      onChange={() => setExpanded(!expanded)}
    >
      <AccordionSummary
        sx={{
          flexDirection: 'row-reverse',
          color: '#c86148',
          backgroundColor: '#f2dede',
          border: expanded ? 'unset' : '1px solid #b94a48',
          fontSize: '13px',
          padding: '7px 12px',
          height: '30px',
          boxShadow: 'unset'
        }}
        expandIcon={
          expanded ?
          <KeyboardArrowUp sx={{ color: '#c86148' }} /> :
          <KeyboardArrowRight sx={{ color: '#c86148' }} />
        }
      >
        Recommended Next Steps:
      </AccordionSummary>
      <AccordionDetails
        sx={{
          color: '#c86148',
          backgroundColor: '#f2dede',
          fontSize: '13px',
          borderTop: '1px solid #fff'
        }}
      >
        {
          testResults.map(test => (
            <React.Fragment key={test.test_result_id}>
              {test.next_steps ? <span style={{ display: 'block' }}>{test.location}: {test.next_steps}</span> : null}
            </React.Fragment>
          ))
        }
      </AccordionDetails>
    </Accordion>
  )
}

export default NextStepsAccordion;