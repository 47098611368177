import axios from 'axios';
import { Button, Dialog, Stack, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import CloseModalButton from '../../../components/CloseModalButton';
import { StyledDialogContent, StyledDialogFooter, StyledDialogTitle } from '../../../components/Dialog.styled';
import { selectCurrentCustomerNumber } from '../../../redux/customer';
import { useAppSelector } from '../../../redux/store';
import { Room } from './types';
import constants from '../../../constants';

interface RoomModalProps {
  onClose: (refresh?: boolean) => void;
  onAlert: (message: string) => void;
  isOpen: boolean;
  room?: Room;
  rooms?: Room[];
}

const defaultFormState: Partial<Room> = {
  name: '',
  description: '',
  customer_number: ''
};

const RoomModal: React.FC<RoomModalProps> = ({ onClose, onAlert, isOpen, room, rooms }) => {
  const [formData, setFormData] = useState(defaultFormState);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [loading, setLoading] = useState<boolean>(false);
  const customerNumber = useAppSelector(selectCurrentCustomerNumber);

  useEffect(() => {
    if (room) {
      setFormData(room);
    } else {
      setFormData(defaultFormState);
    }
  }, [room]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};
    const trimmedName = formData?.name?.toLowerCase().trim();
    const matchingRoom = (!room || room.name.toLowerCase() !== trimmedName) && rooms?.find(({ name }) => name.toLowerCase() === trimmedName);

    if (matchingRoom) {
      newErrors.name = 'This Room/Chair Name already exists';
    } else if (!trimmedName) {
      newErrors.name = 'Room/Chair Name is required';
    }

    return newErrors;
  };

  const handleClose = (refresh: boolean) => {
    resetForm();
    onClose(refresh);
  };

  const handleSubmit = async () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    } else {
      setErrors({});
    }

    if (!formData.customer_number) {
      formData.customer_number = customerNumber;
    }

    try {
      setLoading(true);
      if (room) {
        await axios.put(`${constants.ONTRAQ_API_URL}/api/WaterlineRooms/${formData.room_id}`, formData);
      } else {
        await axios.post(`${constants.ONTRAQ_API_URL}/api/WaterlineRooms`, formData);
      }
      handleClose(true);
    } catch (error) {
      const message = `There was an error ${room ? 'updating' : 'adding'} the room`;
      console.error(message);
      onAlert(message);
      handleClose(false);
    }

    setLoading(false);
  };

  const resetForm = () => {
    setFormData(defaultFormState);
    setErrors({});
  };

  return (
    <Dialog open={isOpen} onClose={() => handleClose(false)} fullWidth>
      <CloseModalButton handleClose={() => handleClose(false)} />
      <StyledDialogTitle>{room ? 'Edit' : 'Add'} Room/Chair</StyledDialogTitle>
      <StyledDialogContent>
        <div>For consistent reporting, please use the same description name of Room/Chair as used in the mail in testing.</div>
        <Stack spacing={'16px'} marginTop={'16px'} marginBottom={'8px'}>
          <TextField
            size="small"
            inputProps={{ maxLength: 50 }} 
            label="Room/Chair Name *"
            error={!!errors.name}
            onChange={handleChange}
            name="name"
            value={formData.name}
            helperText={errors.name ?? ''}
          />
          <TextField
            size="small"
            inputProps={{ maxLength: 50 }}
            label="Description"
            onChange={handleChange}
            name="description"
            value={formData.description ?? ''}
          />
        </Stack>
      </StyledDialogContent>
      <StyledDialogFooter>
        <Button onClick={() => handleClose(false)}>Cancel</Button>
        <Button variant="contained" onClick={handleSubmit} disabled={loading}>
          Save
        </Button>
      </StyledDialogFooter>
    </Dialog>
  );
};

export default RoomModal;
