import axios from "axios"
import { useState, useEffect, Dispatch, SetStateAction, useMemo } from "react"
import { ChildPracticeMetadata, Groups, OpenGroupAlert, PickList, PickListOption } from "../../../types"
import { Box, Button } from "@mui/material"
import constants from "../../../constants"
import GroupTransfer from "./GroupTransfer"
import { useAppDispatch } from "../../../redux/store"
import { getCustomerGroups } from "../../../redux/customer"

type GroupTransferWrapperProps = {
  selectedGroup: Groups | null,
  setOpenAlert: Dispatch<SetStateAction<OpenGroupAlert>>,
  childPractices: ChildPracticeMetadata[],
  customerNumber: string,
}

const getPickList = (selectedGroup: Groups | null, childPractices: ChildPracticeMetadata[]) => {
  const pickList: PickList = { available: [], members: [] }
  
  if (selectedGroup) {
    const groupMembers = new Set()
    selectedGroup?.members?.forEach(member => groupMembers.add(member.customer_number))
  
    childPractices.forEach(child => {
      const childCustomerNumber = child?.customer_number.trim()

      const option: PickListOption = {
        customerNumber: childCustomerNumber,
        value: `${child?.name.trim()} # ${childCustomerNumber} - ${child?.city.trim()}, ${child?.state.trim()}`,
      }

      if (groupMembers.has(childCustomerNumber)) {
        pickList.members.push(option)
      } else {
        pickList.available.push(option)
      }
    })
  }

  return pickList
}

const getHasMemberChange = (pickList: PickList, originalPickList: PickList) => {
  if (originalPickList.members.length === pickList.members.length) {
    const originalMemberSet = new Set()
    originalPickList.members.forEach(member => originalMemberSet.add(member.customerNumber))
    const hasSameMembers = pickList.members.every(member => originalMemberSet.has(member.customerNumber))

    return !hasSameMembers
  } else {
    return true
  }
}

const GroupTransferWrapper = (props: GroupTransferWrapperProps) => {
  const {
    selectedGroup,
    setOpenAlert,
    childPractices,
    customerNumber,
  } = props

  const groupName = selectedGroup?.name ?? ''
  const dispatch = useAppDispatch()

  const originalPickList = useMemo(() => {
    return getPickList(selectedGroup, childPractices)
  }, [selectedGroup])

  const [pickList, setPickList] = useState<PickList>(originalPickList)
  const [disableSave, setDisableSave] = useState(true)

  useEffect(() => {
    const hasMemberChange = getHasMemberChange(pickList, originalPickList)
    setDisableSave(!hasMemberChange)
  }, [pickList])

  const resetHandler = () => {
    setPickList(originalPickList)
  }

  const saveHandler = async () => {
    setDisableSave(true)

    const groupId = selectedGroup?.group_id

    const members = pickList.members.map(option => {
      return {
        group_id: groupId,
        customer_number: option.customerNumber,
      }
    })

    const url = `${constants.ONTRAQ_API_URL}/api/GroupMembers/updateGroupMembers`
    const payload = {
      groupDetails: {
        customer_number: selectedGroup?.customer_number,
        group_id: groupId,
        members,
        name: selectedGroup?.name,
      }
    }

    try {
      await axios.post(url, payload)
      dispatch(getCustomerGroups(customerNumber))
      setOpenAlert({ open: true, type: 'success', message: 'Group members updated' })
    } catch (err) {
      setDisableSave(false)
      setOpenAlert({ open: true, type: 'error', message: '' })
    }
  }

  return (
    <Box sx={{ flexBasis: '100%' }} className="m-t-16">
      <GroupTransfer
        pickList={pickList}
        setPickList={setPickList}
        groupName={groupName}
      />
      <Box sx={{ color: '#BFC6C8' }} className="f-s-13 m-t-16">
        Place practices in the selected group by selecting them from the "available" list and using the blue arrow buttons.
      </Box>
      <Box sx={{ width: '100%' }} className="flex flex-end m-t-16">
        <Button onClick={resetHandler}>Reset</Button>
        <Button className="m-l-16" variant="contained" onClick={saveHandler} disabled={disableSave}>Save</Button>
      </Box>
    </Box>
  )
}

export default GroupTransferWrapper
