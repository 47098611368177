import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import './PageHeader.css';

interface PageHeaderProps {
  iconName?: IconProp;
  headerName: string;
  src?: string;
  size?: string;
}

const PageHeader: React.FC<PageHeaderProps> = ({ iconName, headerName, src, size = '1.5rem' }) => {
  return (
    <div className="page-header-container">
      {iconName && <FontAwesomeIcon className='page-header-icon' icon={iconName} />}
      {src && <img src={src} style={{ width: size, height: size }} alt="header image" className="custom-image" />}
      <span className="page-header-name">{headerName}</span>
    </div>
  );
};

export default PageHeader;
