
import { ChangeEvent, useState, useEffect, KeyboardEvent } from 'react'
import { Button, Dialog, TextField } from '@mui/material'
import { useAppDispatch } from '../../../redux/store'
import { getCustomerGroups } from '../../../redux/customer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { Groups } from '../../../types'
import CloseModalButton from '../../../components/CloseModalButton';
import { StyledDialogContent, StyledDialogFooter, StyledDialogTitle } from '../../../components/Dialog.styled'

type GroupDialogProps = {
  open: boolean,
  customerNumber: string,
  title: string,
  saveText: string,
  defaultGroupName: string,
  icon: IconProp,
  handleClose: () => void,
  handleError: () => void,
  handleSuccess: (group: Groups) => void,
  service: (groupName: string, customerNumber: string) => Promise<Groups>,
}

const validateGroupName = (groupName: string, originalGroupName: string) => {
  let isValid = true
  let message = ''

  const length = groupName?.length ?? 0

  if (length === 0) {
    isValid = false
    message = 'Name Required'
  } else if (length < 3) {
    isValid = false
    message = 'Name Too Short'
  } else if (length > 25) {
    isValid = false
    message = 'Name Too Long'
  } else if (groupName === originalGroupName) {
    isValid = false
    message = 'Name Change Required To Edit'
  }

  return { isValid, message }
}

const GroupDialog = (props: GroupDialogProps) => {
  const {
    open,
    handleClose,
    customerNumber,
    handleError,
    handleSuccess,
    title,
    saveText,
    service,
    defaultGroupName,
    icon,
   } = props
  
  const [groupName, setGroupName] = useState(defaultGroupName)
  const [disableCreate, setDisableCreate] = useState(true)
  const [validationMessage, setValidationMessage] = useState('') 

  const dispatch = useAppDispatch()

  const handleGroupName = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const groupName = event.target.value.trim()
    const { isValid, message } = validateGroupName(groupName, defaultGroupName)
    
    if (isValid) {
      setDisableCreate(false)
      setValidationMessage('')
    } else {
      setDisableCreate(true)
      setValidationMessage(message)
    }
    
    setGroupName(groupName)
  }

  const handleSave = async () => {
    try {
      setDisableCreate(true)
      const group = await service(groupName, customerNumber)
      dispatch(getCustomerGroups(customerNumber))
      handleClose()
      handleSuccess(group)
    } catch (err) {
      handleClose()
      handleError()
    }
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && !disableCreate) {
      handleSave()
    }
  }

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <CloseModalButton handleClose={handleClose} />
        <StyledDialogTitle>
          <FontAwesomeIcon className="m-l-8 m-r-16" icon={icon} size="1x" />
          {title}
        </StyledDialogTitle>
        <StyledDialogContent>
          <TextField
            sx={{ minWidth: 400 }}
            size="small"
            label="Enter Group Name"
            onChange={handleGroupName}
            error={Boolean(validationMessage)}
            helperText={validationMessage}
            defaultValue={groupName}
            onKeyDown={handleKeyDown}
          />
        </StyledDialogContent>
        <StyledDialogFooter>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" onClick={handleSave} disabled={disableCreate}>
            {saveText}
          </Button>
        </StyledDialogFooter>
      </Dialog>
    </>
  )
}

export default GroupDialog
