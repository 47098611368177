import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as R from 'remeda';
import axios from 'axios';

import { RootState } from './store';
import { ChildPracticeMetadata, CustomerDetails, TeamMember } from '../types';
import constants from '../constants';
import { getGroups } from '../services/customerService';

export interface CustomerState
  extends Pick<
    CustomerDetails,
    | 'city'
    | 'corporate_parent'
    | 'customer_number'
    | 'customerService'
    | 'doctor_name'
    | 'email'
    | 'groups'
    | 'name'
    | 'phone'
    | 'state'
    | 'street'
    | 'street2'
    | 'zip'
  > {
  currentCustomerNumber: string;
  corporateView: boolean;
  teamMembers: TeamMember[];
  childPractices: ChildPracticeMetadata[];
  error: string | null;
}

const initialState: CustomerState = {
  city: '',
  corporate_parent: false,
  customer_number: '',
  customerService: [],
  doctor_name: '',
  email: '',
  groups: [],
  name: '',
  phone: '',
  state: '',
  street: '',
  street2: '',
  zip: '',
  currentCustomerNumber: '',
  corporateView: false,
  teamMembers: [],
  childPractices: [],
  error: null
};

export const getChildPractices = createAsyncThunk('setChildPractices', async (customerNumber: string) => {
  const response = await axios.get(`${constants.ONTRAQ_API_URL}/api/customers/${customerNumber}/getCorporateChildren`);
  return response.data.children;
});

export const getTeamMembers = createAsyncThunk('setTeamMembers', async (customerNumber: string) => {
  const query = {
    include: [
      {
        relation: 'groups',
        scope: {
          include: 'name'
        }
      }
    ],
    where: {
      customer_number: customerNumber
    }
  };
  try {
    const response = await axios.get(`${constants.ONTRAQ_API_URL}/api/UsersPractices?filter=${JSON.stringify(query)}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
});

export const getCustomerGroups = createAsyncThunk('setCustomerGroups', getGroups)

export const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    setCustomer: (state, action: PayloadAction<CustomerState>) => {
      return {
        ...state,
        ...R.pick(action.payload, [
          'city',
          'corporate_parent',
          'customer_number',
          'customerService',
          'doctor_name',
          'email',
          'groups',
          'name',
          'phone',
          'state',
          'street',
          'street2',
          'zip'
        ])
      };
    },
    setCurrentCustomerNumber: (state, action: PayloadAction<string>) => {
      state.currentCustomerNumber = action.payload;
    },
    setCorporateView: (state, action: PayloadAction<boolean>) => {
      state.corporateView = action.payload;
    },
    clearCustomer: () => {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder.addCase(getTeamMembers.fulfilled, (state, action) => {
      if (!state) {
        return;
      }
      state.teamMembers = action.payload;
    });
    builder.addCase(getChildPractices.fulfilled, (state, action) => {
      if (!state) {
        return;
      }
      state.childPractices = action.payload;
    })
    builder.addCase(getChildPractices.pending, (state) => {
      state.error = null;
    });
    builder.addCase(getChildPractices.rejected, (state) => {
      state.error = "The practices could not be loaded, try again later.";
    });
    builder.addCase(getCustomerGroups.fulfilled, (state, action) => {
      if (!state) return
      state.groups = action.payload
    })
  }
});

export const { setCustomer, setCurrentCustomerNumber, setCorporateView, clearCustomer } = customerSlice.actions;

export const selectGroups = (state: RootState) => state.customer.groups;

export const selectChildPractices = (state: RootState) => state.customer.childPractices;

export const selectCustomerNumber = (state: RootState) => state.customer.customer_number;

export const selectCurrentCustomerNumber = (state: RootState) => state.customer.currentCustomerNumber;

export const selectCorporateView = (state: RootState) => state.customer.corporateView;

export const selectHasDE = (state: RootState) => state.customer.customerService?.some((service) => service.serviceType === 'DE' && service.isActive) ?? false;

export const selectPracticeDetails = (customerState: RootState) => {
  const {city, customer_number, doctor_name, name, phone, state, street, street2, zip } = customerState.customer;
  return {city, customer_number, doctor_name, name, phone, state, street, street2, zip };
}

export const selectTeamMembers = (state: RootState) => state.customer.teamMembers;

export const selectCustomerError = (state: RootState) => state.customer.error;

export default customerSlice.reducer;
