import React, { useMemo, ReactNode, useState, useEffect } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { Box, Button, Link, Stack, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import * as R from 'remeda';
import { P, match } from 'ts-pattern';
import { zonedTimeToUtc } from 'date-fns-tz';

import ComplianceDonut from '../../../components/ComplianceDonut';
import { selectCurrentCustomerNumber, selectHasDE } from '../../../redux/customer';
import constants from '../../../constants';
import { oldDeCutoffYear } from './constants';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import CompliancePlip from '../../../components/CompliancePlip';
import { ComplianceStatus } from '../../../types';
import { ChecklistCertification, ProgramComponentSection, TrainingChecklist } from './types';
import { checklistTypeToSlug, componentTypeIdToName, componentTypeNameToId, getCertificationMessage, getChecklistTypeDisplayName } from './utils';
import {
  initTaskMetadata,
  selectChecklistCertifications,
  selectComponentSectionCompliance,
  selectComponentCompliance,
  selectComplianceForDonut,
  selectCustomerOshaHipaaMetadata,
  selectGroupedSectionsByYear,
  selectProgramsByYear,
  selectCustomerTrainingByYear,
  isSelectedYearStarted,
  selectTrainingCompliance,
  selectChecklistCertificationByChecklistId,
  selectInitMetadataState,
  selectChecklistsByYearAndComponentTypeId,
  selectChecklistCompliance,
  selectProgramComponentForms
} from './slice';
import useDeExtras from './hooks/useDeExtras';

import styles from './Programs.module.css';
import ComplianceAlertBox from '../../../components/ComplianceAlertBox';
import ComplianceAlertMessage from '../../../components/ComplianceAlertMessage';

import { ErrorNotify, GenericNotify, WarningNotify } from '../../../components/Notify.styled';
import { selectUserTimezone } from '../../../redux/user';

const CompositeProgramComponent = ({
  name,
  status,
  children,
  certifications = []
}: {
  name: string;
  status?: ComplianceStatus;
  children?: ReactNode;
  certifications?: ChecklistCertification[];
}) => (
  <div className={styles['component-container']}>
    <h2 className={styles['component-details']}>
      {status && <CompliancePlip className="m-r-8" status={status} large />}
      {name}{' '}
      <span>
        {certifications.map((c, index) => (
          <span className={styles['checklist-certification-message']} key={c.certification_id}>
            {getCertificationMessage(c, false)}
            {certifications.length > 1 && index !== certifications.length - 1 && ', '}
          </span>
        ))}
      </span>
    </h2>
    <div className={styles['component-section-container']}>{children}</div>
  </div>
);

const ComponentSection = ({
  complianceStatus,
  name,
  link,
  isExternalLink,
  showCompliance = true
}: {
  complianceStatus?: ComplianceStatus;
  name: string;
  link: string;
  isExternalLink?: boolean;
  showCompliance?: boolean;
}) => (
  <div className={styles['component-section']}>
    {isExternalLink ? (
      <Link href={link} className={styles['component-section__name']}>
        {name}
      </Link>
    ) : (
      <Link component={RouterLink} to={link} className={styles['component-section__name']}>
        {name}
      </Link>
    )}
    {showCompliance && <span className={styles['component-section__compliance']}> {complianceStatus && <CompliancePlip status={complianceStatus} medium />}</span>}
  </div>
);

const OshaHipaaComponentSection = ({ componentName, section }: { componentName: string; section: ProgramComponentSection }) => {
  const complianceStatus = useAppSelector((state) => selectComponentSectionCompliance(state, section));

  return (
    <ComponentSection name={section.name} complianceStatus={complianceStatus} link={`component/${componentName}/section/${section.section_id}`} />
  );
};

const OshaHipaaComponentChecklist = ({ checklist }: { checklist: TrainingChecklist }) => {
  const componentType = componentTypeIdToName(checklist.component_type_id);
  const displayName = getChecklistTypeDisplayName(checklist.type);

  const complianceStatus = useAppSelector((state) => selectChecklistCompliance(state, checklist.id));

  const link = match(checklist.type)
    .with('security risk assessment', () => `component/${componentType}/sra`)
    .otherwise((checklistType) => `component/${componentType}/checklists/${checklistTypeToSlug(checklistType)}`);

  return <ComponentSection name={displayName} link={link} complianceStatus={complianceStatus} />;
};

const OshaHipaaComponentFormUploads = ({ componentName }: { componentName: string }) => {
  const displayName = `${componentName} Uploads`;
  const link = `component/${componentName}/forms`;
  const complianceStatus = 1; // Temporary until we implement compliance

  return <ComponentSection name={displayName} link={link} complianceStatus={complianceStatus} showCompliance={false} />;
}

const OshaHipaaUpsaleComponent = ({ name, linkText = name }: { name: string; linkText?: string }) => {
  const link = (
    <Link href={constants.OSHA_HIPAA_TRAINING_SALES_PAGE_URL} target="_blank">
      {linkText}
    </Link>
  );

  return (
    <CompositeProgramComponent name={name}>
      <Typography className={styles['component-upsale-message']}>
        <FontAwesomeIcon icon="circle-info" className="compliance-success m-r-8" />
        Learn more about our {link} offerings
      </Typography>
    </CompositeProgramComponent>
  );
};

const OshaHipaaComponent = ({ componentName, sections }: { componentName: 'OSHA' | 'HIPAA'; sections: ProgramComponentSection[] }) => {
  const { year } = useParams();

  const componentTypeId = componentTypeNameToId(componentName);

  const checklists = useAppSelector((state) => selectChecklistsByYearAndComponentTypeId(state, year, componentTypeId));

  const checklistsByType = R.indexBy(checklists, R.prop('type'));

  const programComponentForms = useAppSelector((state) => selectProgramComponentForms(state, year, componentName));

  const certifications = useAppSelector((state) => selectChecklistCertifications(state, checklists));

  const componentStatus = useAppSelector((state) => {
    if (!checklists.length) {
      return;
    }

    if (certifications.length === checklists.length) {
      return ComplianceStatus.Complete;
    }

    return selectComponentCompliance(state, year, componentTypeId);
  });

  return (
    <CompositeProgramComponent name={componentName} status={componentStatus} certifications={certifications}>
      {sections.map(
        (section) => section.content_url && <OshaHipaaComponentSection key={section.section_id} componentName={componentName} section={section} />
      )}
      {checklistsByType['security risk assessment'] && <OshaHipaaComponentChecklist checklist={checklistsByType['security risk assessment']} />}
      {checklistsByType['facility report'] && <OshaHipaaComponentChecklist checklist={checklistsByType['facility report']} />}
      {checklistsByType['checklist'] && <OshaHipaaComponentChecklist checklist={checklistsByType['checklist']} />}
      {programComponentForms.length > 0 && <OshaHipaaComponentFormUploads componentName={componentName} />}
    </CompositeProgramComponent>
  );
};

const TrainingComponent = () => {
  const training = useSelector(selectCustomerTrainingByYear);

  const complianceStatus = useSelector(selectTrainingCompliance);

  if (R.isEmpty(training)) {
    return <OshaHipaaUpsaleComponent name="TRAINING" linkText="OSHA/HIPAA training" />;
  }

  return (
    <CompositeProgramComponent name="TRAINING" status={complianceStatus}>
      {training.map((t) => (
        <ComponentSection
          key={t.id}
          link={`/practice/training`}
          isExternalLink
          name={t.training.name}
          complianceStatus={t.status}
        />
      ))}
    </CompositeProgramComponent>
  );
};

const Programs = () => {
  const dispatch = useAppDispatch();

  const { year } = useParams();

  const hasDE = useSelector(selectHasDE);
  const customerNumber = useSelector(selectCurrentCustomerNumber);

  const [deLink, nextAppointment] = useDeExtras();

  const programYear = year ?? String(new Date().getFullYear());

  const groupedSectionsByYear = useAppSelector((state) => selectGroupedSectionsByYear(state, year));

  const programs = useAppSelector((state) => selectProgramsByYear(state, year));

  const donutCompliance = useSelector(selectComplianceForDonut);

  const handleStartClick = () => {
    if (year) {
      setAlertEnabled(true);
      dispatch(initTaskMetadata({ customerNumber, year }));
    }
  };

  const isYearStarted = useAppSelector((state) => isSelectedYearStarted(state, year));

  const showStartButton = useAppSelector((state) => year && Number(year) > Number(oldDeCutoffYear) && !isSelectedYearStarted(state, year));

  const initTaskMetadataState = useAppSelector(selectInitMetadataState);

  const customerOshaHipaaMetadata = useAppSelector((state) => (selectCustomerOshaHipaaMetadata(state, programYear)));
  const overallComplianceStatus = customerOshaHipaaMetadata?.compliance?.compliance_status ?? ComplianceStatus.NotApplicable;

  const timezone = useAppSelector(selectUserTimezone);

  const refreshDate = useMemo(() => {
    if (!customerOshaHipaaMetadata?.updated_date) {
      return new Date();
    }
    // FIXME: This is just a hacky stop gap for the date coming back wrong from the api even though it's correct in the DB
    // TL;DR database is saving dates in PST, loopback is returning them like they are UTC (i.e. it's adding a 'z' to the end of the date string)
    return new Date(zonedTimeToUtc(customerOshaHipaaMetadata?.updated_date.slice(0, -1), 'America/Los_Angeles'));
  }, [customerOshaHipaaMetadata?.updated_date]);

  const complianceStatusMessage = customerOshaHipaaMetadata?.compliance?.message_long ?? '';

  const [alertShow, setAlertShow] = useState(false);
  const [alertEnabled, setAlertEnabled] = useState(false);
  const handleAlertDismiss = () => {
    setAlertShow(false);
    setAlertEnabled(false);
  };
  useEffect(() => {
    if (!alertEnabled) return; // alert only needs to be present after a start click
    if (initTaskMetadataState === 'not-asked' || initTaskMetadataState === 'loading') return;
    setAlertShow(true);
  }, [initTaskMetadataState, isYearStarted]);

  return (
    <Stack direction="column">
      <Stack direction="row" alignItems="center" justifyContent="center" paddingY={2}>
        {programYear !== oldDeCutoffYear && !!programs.length && (
          <>
            <Box data-testid="programs-donut-box">
              <ComplianceDonut image="chart-osha-hipaa" label="OSHA/HIPAA" values={donutCompliance} />
            </Box>
            <Stack direction="row" alignItems="flex-start">
              <Stack>
                <Stack direction="row" spacing={1}>
                  <ComplianceAlertBox compliance_status={overallComplianceStatus} data-testid="compliance-alert-box"></ComplianceAlertBox>
                  <ComplianceAlertMessage
                    compliance_status={overallComplianceStatus}
                    message={complianceStatusMessage}
                    data-testid="compliance-alert-message"
                  ></ComplianceAlertMessage>
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography variant="utility">Date refreshed {refreshDate.toLocaleString('en', { timeZone: timezone })}</Typography>
                  {/* <Link component="button" variant="utility" onClick={refreshClick}>
                    Refresh
                  </Link> */}
                </Stack>
              </Stack>
              <Box data-testid="programs-start-button" className="m-l-16">
                {showStartButton && (
                  <Button variant="default" onClick={handleStartClick} data-testid="button-start-compliance">
                    <Typography variant="button">start</Typography>
                  </Button>
                )}
                {match({ initTaskMetadataState, isYearStarted })
                  .with({ initTaskMetadataState: 'error' }, () => <ErrorNotify open={alertShow} onClose={() => handleAlertDismiss()} />)
                  .with({ initTaskMetadataState: 'done', isYearStarted: false }, () => (
                    <WarningNotify open={alertShow} onClose={() => handleAlertDismiss()}>
                      Year initialized but no items available
                    </WarningNotify>
                  ))
                  .with({ initTaskMetadataState: 'done', isYearStarted: true }, () => (
                    <GenericNotify
                      open={alertShow}
                      onClose={() => handleAlertDismiss()}
                      message="Your program has been successfully turned on. Click on each section below and follow the directions on the page. When you are done with a section, complete the items in your checklist"
                    ></GenericNotify>
                  ))
                  .otherwise(() => null)}
              </Box>
            </Stack>
          </>
        )}
      </Stack>
      <Stack direction="column">
        {hasDE && (
          <Box className={styles['appointment']} data-testid="appointment-reminder">
            <FontAwesomeIcon icon={['far', 'clock']} size="lg" className="compliance-success m-r-8" />
            {nextAppointment ? <Box>Upcoming Appointment: {nextAppointment}</Box> : <Box>No Upcoming Appointments</Box>}
          </Box>
        )}
        {programYear === oldDeCutoffYear && (
          <Box className="flex flex-align-center flex-column">
            <Box className={styles['osha-hipaa-label']}>OSHA and HIPAA</Box>
            <Button variant="contained" disabled={deLink.length < 1} onClick={() => window.open(deLink, '_blank')}>
              View Programs
            </Button>
          </Box>
        )}
        {programYear > oldDeCutoffYear && (
          <Box sx={{ position: 'relative', opacity: showStartButton ? 0.25 : 1 }}>
            {R.isEmpty(groupedSectionsByYear.OSHA) ? (
              <OshaHipaaUpsaleComponent name="OSHA" />
            ) : (
              <OshaHipaaComponent componentName="OSHA" sections={groupedSectionsByYear.OSHA} />
            )}
            {R.isEmpty(groupedSectionsByYear.HIPAA) ? (
              <OshaHipaaUpsaleComponent name="HIPAA" />
            ) : (
              <OshaHipaaComponent componentName="HIPAA" sections={groupedSectionsByYear.HIPAA} />
            )}
            <TrainingComponent />
            {showStartButton && <Box className={styles['component-scrim']} />}
          </Box>
        )}
      </Stack>
    </Stack>
  );
};

export default Programs;
