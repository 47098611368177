import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';

const CloseModalButton = ({ handleClose }: { handleClose: () => void }) => (
  <IconButton
    aria-label="close"
    onClick={handleClose}
    sx={{
      position: 'absolute',
      right: 12,
      top: 12,
      color: (theme) => theme.palette.color50555b.main,
      padding: 0
    }}
  >
    <CloseIcon fontSize="small" />
  </IconButton>
);

export default CloseModalButton;
