import axios from "axios"
import { Dispatch, SetStateAction } from "react"
import { Button, Dialog } from "@mui/material"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Groups, OpenGroupModal, OpenGroupAlert } from "../../../types"
import { getCustomerGroups } from "../../../redux/customer"
import { useAppDispatch } from "../../../redux/store"
import constants from "../../../constants"
import CloseModalButton from '../../../components/CloseModalButton';
import { StyledDialogContent, StyledDialogFooter, StyledDialogTitle } from '../../../components/Dialog.styled'

type GroupDeleteDialogProps = {
  open: boolean,
  setOpenModal: Dispatch<SetStateAction<OpenGroupModal>>,
  customerNumber: string,
  setOpenAlert: Dispatch<SetStateAction<OpenGroupAlert>>,
  selectedGroup: Groups | null,
  setSelectedGroup: Dispatch<SetStateAction<Groups | null>>,
}

const deleteGroup = async (selectedGroup: Groups | null) => {
  const groupId = selectedGroup?.group_id
  const url = `${constants.ONTRAQ_API_URL}/api/groups/${groupId}`
  
  await axios.delete(url)
}

const deleteUsersGroups = async (selectedGroup: Groups | null) => {
  const groupId = selectedGroup?.group_id
  const url = `${constants.ONTRAQ_API_URL}/api/UsersGroups/${groupId}`

  await axios.delete(url)
}

const GroupDeleteDialog = (props: GroupDeleteDialogProps) => {
  const {
    open,
    setOpenModal,
    customerNumber,
    setOpenAlert,
    selectedGroup,
    setSelectedGroup,
  } = props

  const dispatch = useAppDispatch()

  const handleClose = () => {
    setOpenModal({ open: false, type: 'delete' })
  }

  const handleError = () => {
    setOpenAlert({ open: true, type: 'error', message: '' })
  }

  const handleSuccess = () => {
    const groupName = selectedGroup?.name
    setOpenAlert({ open: true, type: 'success', message: `Group ${groupName} deleted` })
  }

  const handleDelete = async () => {
    try {
      await deleteGroup(selectedGroup)
      await deleteUsersGroups(selectedGroup)
      dispatch(getCustomerGroups(customerNumber))
      handleClose()
      handleSuccess()
      setSelectedGroup(null)
    } catch (err) {
      handleClose()
      handleError()
    }
  }

  return selectedGroup ? (
    <>
      <Dialog open={open} onClose={handleClose}>
        <CloseModalButton handleClose={handleClose} />
        <StyledDialogTitle>
          <FontAwesomeIcon className="m-l-8 m-r-16" icon="trash-can" size="1x" />
          Delete Group
        </StyledDialogTitle>
        <StyledDialogContent>
          Are you sure you want to delete this group?
        </StyledDialogContent>
        <StyledDialogFooter>
          <Button onClick={handleClose}>No</Button>
          <Button variant="contained" onClick={handleDelete}>Yes</Button>
        </StyledDialogFooter>
      </Dialog>
    </>
  ) : null
}

export default GroupDeleteDialog
