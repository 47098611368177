import { useState } from 'react';
import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { DisplayResultsBy } from '../types';

const toggleButtonStyle = {
  textTransform: 'capitalize',
  height: '35px',
  borderRadius: '5px',
  fontSize: '13px'
};

const DisplayByToggleButton = (props: {
  setDisplayResultsBy: (displayBy: DisplayResultsBy) => void;
  displayResultsBy: DisplayResultsBy;
}) => {
  const { setDisplayResultsBy, displayResultsBy } = props;

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    displayBy: DisplayResultsBy,
  ) => {
    setDisplayResultsBy(displayBy);
  }

  return (
    <>
      <Typography
        style={{
          display: 'inline',
          marginRight: '5px',
          fontWeight: 'bold',
          fontSize: '13px'
        }}
      >
        Display By:
      </Typography>
      <ToggleButtonGroup
        value={displayResultsBy}
        exclusive
        onChange={handleChange}
        color="primary"
      >
        <ToggleButton
          sx={toggleButtonStyle}
          value="roomChair"
        >
          Room Chair
        </ToggleButton>
        <ToggleButton
          sx={toggleButtonStyle}
          value="testDate"
        >
          Test Date
        </ToggleButton>
      </ToggleButtonGroup>
    </>
  );
}

export default DisplayByToggleButton;