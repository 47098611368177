import { RouteObject } from 'react-router-dom';

import Osha from '../../views/manuals/Osha';
import Hipaa from '../../views/manuals/Hipaa';

export default [
  {
    path: 'manuals',
    children: [
      {
        path: 'osha/year?/:year?/customerNumber?/:customerNumber?',
        element: <Osha />
      },
      {
        path: 'hipaa/year?/:year?/customerNumber?/:customerNumber?',
        element: <Hipaa />
      }
    ]
  }
] satisfies RouteObject[];
