import { SyntheticEvent, useMemo } from 'react';
import { Typography, Box, Button } from '@mui/material';
import { GetApp as GetAppIcon } from '@mui/icons-material';
import { groupBy } from 'remeda';
import DisplayByToggleButton from './DisplayByToggleButton';
import LogInOfficeResultModal from './LogInOfficeResultModal';
import { WaterlineTestReport } from '../WaterlineReports';
import { useAppSelector } from '../../../../redux/store';
import { selectCorporateView, selectPracticeDetails } from '../../../../redux/customer';
import { selectChildPracticeDetails } from '../../../../redux/childCustomer';
import { DisplayResultsBy, Room, WaterlineTestResult } from '../types';
import { TeamMember } from '../../../../types';
import TestResultsTableWrapper from './TestResultsTable';
import { selectCurrentUser } from '../../../../redux/user';
import { getWaterLinePermissions } from '../utils';

const TestResults = (props: {
  testResults: WaterlineTestResult[];
  getTestResults: () => void;
  displayResultsBy: DisplayResultsBy;
  setDisplayResultsBy: (displyBy: DisplayResultsBy) => void;
  rooms: Room[];
  teamMembersIndex: { [Identifier: number]: TeamMember }
  handleOpenAlert: (message: string) => void;
  getCompliance: () => void;
  hasInOfficeTesting: boolean;
  setTab: (_event: SyntheticEvent, newValue: number) => void;
}) => {
  const currentUser = useAppSelector(selectCurrentUser);
  const isCorpView = useAppSelector(selectCorporateView);
  const practiceDetails = useAppSelector(selectPracticeDetails);
  const childPracticeDetails = useAppSelector(selectChildPracticeDetails);
  const currentPracticeDetails = isCorpView ? childPracticeDetails : practiceDetails;
  const waterLinePermissions = getWaterLinePermissions(currentUser);

  const { testResults, getTestResults, displayResultsBy, setDisplayResultsBy, rooms, teamMembersIndex, handleOpenAlert, getCompliance, setTab, hasInOfficeTesting } = props;

  const groupedTestResults = useMemo(() => {
    return groupBy(testResults, testResult => {
      const roomId: number = testResult.room?.room_id ?? 0;
      return displayResultsBy === 'roomChair' ? roomId : testResult.test_date;
    })
  }, [displayResultsBy]);

  return (
    <Box>
      <Box sx={{ marginBottom: '15px', display: 'flex', justifyContent: 'space-between' }}>
        <Box>
          <DisplayByToggleButton
            setDisplayResultsBy={setDisplayResultsBy}
            displayResultsBy={displayResultsBy}
          />
        </Box>
        <Box>
          {
            hasInOfficeTesting
            && waterLinePermissions.inOffice.add
            && (
              <LogInOfficeResultModal
                rooms={rooms}
                handleOpenAlert={handleOpenAlert}
                getTestResults={getTestResults}
                displayBy={displayResultsBy}
                getCompliance={getCompliance}
              />
            )
          }
          <Button onClick={() => WaterlineTestReport(testResults, currentPracticeDetails)} startIcon={<GetAppIcon />} variant="contained">
            Export All
          </Button>
        </Box>
      </Box>
      {Object.values(groupedTestResults)
        .sort((a, b) => {
          const roomA = a[0];
          const roomB = b[0];

          if (displayResultsBy === 'roomChair') {
            const roomNameA = roomA?.room?.name ?? roomA.location ?? '';
            const roomNameB = roomB?.room?.name ?? roomB.location ?? '';

            const roomAExisting = roomA?.room?.name;
            const roomBExisting = roomB?.room?.name;

            if (!roomAExisting && roomBExisting) {
              return -1;
            }
            if (roomAExisting && !roomBExisting) {
              return 1;
            }

            return roomNameA.localeCompare(roomNameB);
          } else {
            return new Date(roomA.test_date) < new Date(roomB.test_date) ? 1 : -1;
          }
        })
        .map(groupedResults => (
          <TestResultsTableWrapper
            key={displayResultsBy === 'roomChair' ? (groupedResults[0].room_id ?? groupedResults[0].location) : groupedResults[0].test_date}
            testResults={groupedResults}
            displayResultsBy={displayResultsBy}
            getTestResults={getTestResults}
            teamMembersIndex={teamMembersIndex}
            rooms={rooms}
            handleOpenAlert={handleOpenAlert}
            getCompliance={getCompliance}
            customerDetails={currentPracticeDetails}
            setTab={setTab}
          />
        )
        )}
      <Box sx={{ border: '1px solid #e0e0e0' }}>
        <Typography
          style={{
            fontSize: '13px',
            fontWeight: 'bold',
            padding: '16px 16px 16px 46px'
          }}
        >
          Notes: A failed test occurs when there is 500CFL/mL or greater of HPC.<br />
          Total Dissolved Solids (TDS): Inorganic salts and some organic matter that is dissolved in water. <br />
          pH: A measure of the acidity or alkalinity of a solution. Method check and control are added in by the lab for quality control purposes.
        </Typography>
      </Box>
    </Box>
  )
};

export default TestResults;