import { Dispatch, SetStateAction } from "react"
import GroupCreateDialog from "./GroupCreateDialog"
import GroupDeleteDialog from "./GroupDeleteDialog"
import GroupEditDialog from "./GroupEditDialog"
import { Groups, OpenGroupAlert, OpenGroupModal } from "../../../types"

type GroupDialogsProps = {
  openModal: OpenGroupModal
  setOpenModal: Dispatch<SetStateAction<OpenGroupModal>>,
  setOpenAlert: Dispatch<SetStateAction<OpenGroupAlert>>,
  selectedGroup: Groups | null,
  customerNumber: string,
  setSelectedGroup: Dispatch<SetStateAction<Groups | null>>,
  setGroupId: Dispatch<SetStateAction<number | null>>,
}

const GroupDialogs = (props: GroupDialogsProps) => {
  const {
    openModal,
    customerNumber,
    selectedGroup,
    setOpenModal,
    setOpenAlert,
    setGroupId,
    setSelectedGroup,
  } = props
  
  const { open, type } = openModal

  return (
    <>
      {
        open && type === 'create' ?
          <GroupCreateDialog
            open={open}
            setOpenModal={setOpenModal}
            customerNumber={customerNumber}
            setOpenAlert={setOpenAlert}
            setGroupId={setGroupId}
          /> : null
      }
      {
        open && type === 'edit' ?
          <GroupEditDialog
            open={open}
            setOpenModal={setOpenModal}
            customerNumber={customerNumber}
            setOpenAlert={setOpenAlert}
            selectedGroup={selectedGroup}
        /> : null
      }
      {
        open && type === 'delete' ?
          <GroupDeleteDialog
            open={open}
            setOpenModal={setOpenModal}
            customerNumber={customerNumber}
            setOpenAlert={setOpenAlert}
            selectedGroup={selectedGroup}
            setSelectedGroup={setSelectedGroup}
          /> : null
      }    
    </>
  )
}

export default GroupDialogs
