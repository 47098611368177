import React, { useEffect, useMemo, useState } from 'react';
import { useParams, Link, Outlet, useOutletContext, useNavigate, useMatch, useMatches } from 'react-router-dom';
import { Tabs, Tab, Button } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import urlJoin from 'url-join';
import * as R from 'remeda';

import styles from './ComponentTypeDetails.module.css';
import { ProgramComponentSection, TrainingChecklistType } from './types';
import { selectChecklistsByYearAndComponentTypeId, selectProgramComponentForms, selectSectionsByYearAndComponentTypeName } from './slice';
import { useAppSelector, type RootState } from '../../../redux/store';
import { componentTypeNameToId, getChecklistTypeDisplayName, checklistTypeToSlug } from './utils';
import { selectCurrentCustomerNumber } from '../../../redux/customer';

type ContextType = { sections: ProgramComponentSection[] };

type TabOptions = {
  value: string;
  to: string;
  label: string;
};

const useProgramDetailsContext = () => useOutletContext<ContextType>();

export const IFrameWrapper = () => {
  const { sectionId, year } = useParams();
  const { sections } = useProgramDetailsContext();

  const section = useMemo(() => sections.find((s) => s.section_id + '' === sectionId), [sectionId, sections]);

  const customerNumber = useSelector(selectCurrentCustomerNumber);

  // This hack only exists to support corporate users being able to download a child practice's osha/hipaa forms, which requires
  // a URL rewrite to include the child practice's customer number
  const iframeURL = useMemo(() => {
    const contentUrl = section?.content_url;
    if (!contentUrl) {
      return '';
    }
    return /\/manuals\/(osha|hipaa)/.test(contentUrl) ? urlJoin(contentUrl, `/year/${year}/customerNumber/${customerNumber}`) : contentUrl;
  }, [section?.content_url]);

  console.log('iframe', iframeURL);

  return (
    section && (
      <iframe
        className={styles.iframe}
        src={iframeURL}
        sandbox="allow-downloads allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
      />
    )
  );
};

const checklistTypeToTab = (checklistType: TrainingChecklistType): TabOptions => {
  const displayName = getChecklistTypeDisplayName(checklistType);
  const typeSlug = checklistTypeToSlug(checklistType);

  return {
    value: typeSlug,
    to: checklistType === 'security risk assessment' ? typeSlug : `checklists/${typeSlug}`,
    label: displayName
  };
};

const ComponentTypeDetails = () => {
  const matches = useMatches();
  const { componentTypeName, checklistTypeSlug, sectionId, year } = useParams();

  const navigate = useNavigate();

  const [tabsValue, setTabsValue] = useState('');

  const checklists = useSelector((state: RootState) =>
    selectChecklistsByYearAndComponentTypeId(state, year, componentTypeNameToId(componentTypeName))
  );

  const programComponentForms = useAppSelector((state) => selectProgramComponentForms(state, year, componentTypeName));

  const sections = useSelector((state: RootState) => selectSectionsByYearAndComponentTypeName(state, year, componentTypeName));

  const tabs = useMemo(() => {
    const existingChecklistTypes = R.reduce(
      checklists,
      (typeMap, checklist) => ({
        ...typeMap,
        [checklist.type]: true
      }),
      {} as Record<TrainingChecklistType, boolean>
    );

    const checklistTabs = R.compact([
      existingChecklistTypes['security risk assessment'] && checklistTypeToTab('security risk assessment'),
      existingChecklistTypes['facility report'] && checklistTypeToTab('facility report'),
      existingChecklistTypes['checklist'] && checklistTypeToTab('checklist')
    ]);

    const formUploads = {
      value: 'formUploads',
      to: 'forms',
      label: `${componentTypeName} Uploads`,
    }

    return [
      ...(programComponentForms.length > 0 ? [formUploads] : []),
      ...checklistTabs,
      ...sections
        .filter((s) => s.content_url)
        .map(({ section_id, name }) => ({
          value: String(section_id),
          to: `section/${section_id}`,
          label: name
        }))
    ];
  }, [checklists, sections]);

  // Mirror the selected section, or default to the first tab
  useEffect(() => {
    if (checklistTypeSlug) {
      return setTabsValue(checklistTypeSlug);
    }

    if (sectionId) {
      return setTabsValue(sectionId);
    }

    const sraSlug = checklistTypeToSlug('security risk assessment');

    // SRA routes are not the normal `checklist/:slug` routes we have to check them separately
    if (matches.some((match) => match.pathname.endsWith(sraSlug))) {
      return setTabsValue(sraSlug);
    }

    const [firstTab] = tabs;

    if (firstTab) {
      setTabsValue(firstTab.value);
      navigate(firstTab.to);
    }
  }, [checklistTypeSlug, sectionId]);

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.heading}>{componentTypeName}</h1>
      <div className={styles.tabRow}>
        <Button to=".." component={Link} startIcon={<ArrowBack />}>
          Back
        </Button>
        <Tabs value={tabsValue || false} variant="scrollable" scrollButtons="auto">
          {tabs.map((tab) => (
            <Tab key={tab.value} component={Link} {...tab} />
          ))}
        </Tabs>
      </div>
      <Outlet context={{ sections } satisfies ContextType} />
    </div>
  );
};

export default ComponentTypeDetails;
