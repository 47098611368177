import axios from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { Button, Dialog, FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent, Stack } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { selectCurrentCustomerNumber } from '../../../redux/customer';
import CloseModalButton from '../../../components/CloseModalButton';
import { StyledDialogContent, StyledDialogFooter, StyledDialogTitle } from '../../../components/Dialog.styled';
import dayjs, { Dayjs } from 'dayjs';
import { useAppSelector } from '../../../redux/store';
import { InventoryDate, Room, WaterlineItem } from './types';
import constants from '../../../constants';
import { ToolTip } from '../dentalenhancements/components/ToolTip';

interface AssignRoomModalProps {
  isOpen: boolean;
  item: WaterlineItem | undefined;
  rooms: Room[];
  onClose: (refresh: boolean) => void;
  onAlert: (error: string) => void;
  inventoryType: string;
}

const AssignRoomModal: React.FC<AssignRoomModalProps> = ({ isOpen, item, rooms, onClose, onAlert, inventoryType }) => {
  const inventroyDateField = useMemo(() => {
    return InventoryDate[inventoryType as 'Water Straws' | 'Water Treatment' | 'Water Testing'];
  }, [inventoryType]);

  const defaultFormState: Partial<WaterlineItem> = useMemo(() => ({
    room_id: null,
    activated_date: null
  }), [inventroyDateField])

  const [formData, setFormData] = useState(defaultFormState);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [loading, setLoading] = useState<boolean>(false);
  const customerNumber = useAppSelector(selectCurrentCustomerNumber);

  useEffect(() => {
    if (item) {
      setFormData(item);
    } else {
      setFormData(defaultFormState);
    }
  }, [item]);

  const handleChange = (event: SelectChangeEvent) => {
    if (!event) return;

    const { name, value } = event.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleDateChange = (newDate: Dayjs | null) => {
    setFormData((prevState) => ({
      ...prevState,
      activated_date: newDate ? newDate.toString() : undefined,
    }));
  };

  const handleClose = (refresh: boolean) => {
    resetForm();
    onClose(refresh);
  };

  const resetForm = () => {
    setFormData(defaultFormState);
    setErrors({});
  };

  const validateForm = () => {
    const errors: { [key: string]: string } = {};

    const fieldsToValidate = [
      { field: 'room_id', label: 'Room/Chair', value: formData?.room_id },
      { field: 'activated_date', label: inventroyDateField, value: formData?.activated_date }
    ];

    fieldsToValidate.forEach(({ field, label, value }) => {
      if (!value) {
        errors[field] = `${label} is required`;
      }
    });

    return errors;
  };

  const handleSubmit = async () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    } else {
      setErrors({});
    }

    if (!formData.customer_number) {
      formData.customer_number = customerNumber;
    }

    try {
      setLoading(true);

      await axios.put(`${constants.ONTRAQ_API_URL}/api/WaterlineInventory/updateItem/${formData.id}`, { data: formData } );

      handleClose(true);
    } catch (error) {
      const message = `There was an error updating the item`;
      console.error(message);
      onAlert(message)
      handleClose(false);
    }

    setLoading(false);
  };

  return (
    <Dialog open={isOpen} onClose={() => handleClose(false)} fullWidth>
      <CloseModalButton handleClose={() => handleClose(false)} />
      <StyledDialogTitle>Assign Room/Chair</StyledDialogTitle>
      <StyledDialogContent>
        <Stack spacing={'16px'} marginTop={'16px'} marginBottom={'8px'}>
          <div>
            <ToolTip
              helpText='To select a room/chair combination, please add a room/chair under manage room chair section.'
              style={{ display: 'inline-block', marginRight: '12px' }}
            />
            <FormControl fullWidth size="small" sx={{ width: "95%" }}>
              <InputLabel id="room-id">Select a Room/Chair *</InputLabel>
              <Select
                id="room-id"
                value={formData?.room_id?.toString() ?? ''}
                name="room_id"
                label="Select a Room/Chair *"
                onChange={handleChange}
                size="small"
                error={!!errors.room_id}
              >
                {rooms.map(({ room_id, name }) => (
                  <MenuItem key={room_id} value={room_id}>
                    {name}
                  </MenuItem>
                ))}
                {rooms.length === 0 && (
                  <MenuItem value="">No rooms to select from</MenuItem>
                )}
              </Select>
              {errors.room_id && <FormHelperText error>{errors.room_id}</FormHelperText>}
            </FormControl>
          </div>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
            <DatePicker
              label={`${inventroyDateField} *`}
              slotProps={{ textField: { size: 'small', error: errors && !!errors.activated_date } }}
              value={dayjs(formData?.activated_date) ?? null}
              onChange={(newDate) => handleDateChange(newDate)}
              format="MM-DD-YYYY"
            />
            {errors.activated_date && <FormHelperText sx={{ marginTop: "4px" }} error>{errors.activated_date}</FormHelperText>}
          </LocalizationProvider>
        </Stack>
      </StyledDialogContent>
      <StyledDialogFooter>
        <Button onClick={() => handleClose(false)}>Cancel</Button>
        <Button variant="contained" onClick={handleSubmit} disabled={loading}>
          Save
        </Button>
      </StyledDialogFooter>
    </Dialog>
  );
};

export default AssignRoomModal;
