import axios from "axios"
import { Dispatch, SetStateAction } from "react"
import constants from "../../../constants"
import GroupDialog from "./GroupDialog"
import { Groups, OpenGroupModal, OpenGroupAlert } from '../../../types'

type GroupEditDialogProps = {
  open: boolean,
  customerNumber: string,
  setOpenModal: Dispatch<SetStateAction<OpenGroupModal>>,
  setOpenAlert: Dispatch<SetStateAction<OpenGroupAlert>>,
  selectedGroup: Groups | null,
}

const getEditCustomerGroup = (selectedGroup: Groups | null ) => {
  const editCustomerGroup = async (groupName: string, customerNumber: string) => {
    const groupId = selectedGroup?.group_id
    const url = `${constants.ONTRAQ_API_URL}/api/groups/${groupId}`
    const payload = {
      group_id: groupId,
      name: groupName.trim(),
      customer_number: customerNumber,
    }
    const response = await axios.put(url, payload)

    return response.data
  }

  return editCustomerGroup
}

const GroupEditDialog = (props: GroupEditDialogProps) => {
  const { open, setOpenModal, customerNumber, setOpenAlert, selectedGroup } = props

  const handleSuccess = (group: Groups) => {
    const groupName = group?.name
    setOpenAlert({ open: true, type: 'success', message: `Group name updated to ${groupName}`})
  }

  const handleError = () => {
    setOpenAlert({ open: true, type: 'error', message: ''})
  }

  const handleClose = () => {
    setOpenModal({ open: false, type: 'edit' })
  }

  const service = getEditCustomerGroup(selectedGroup)
  const groupName = selectedGroup?.name ?? ''

  return selectedGroup ? (
    <GroupDialog
      open={open}
      customerNumber={customerNumber}
      title="Edit Group"
      saveText="Save"
      defaultGroupName={groupName}
      icon="trash-can"
      handleClose={handleClose}
      handleSuccess={handleSuccess}
      handleError={handleError}
      service={service}
    />
  ) : null
}

export default GroupEditDialog
