import { Button, FormControl, TextField, Dialog, Stack, FormHelperText } from '@mui/material';
import { Check } from '@mui/icons-material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { WaterlineTestResult } from '../types';
import { useMemo, useState } from 'react';
import { useAppSelector } from '../../../../redux/store';
import { selectCurrentUser } from '../../../../redux/user';
import axios from 'axios';
import constants from '../../../../constants';
import CloseModalButton from '../../../../components/CloseModalButton';
import { StyledDialogContent, StyledDialogFooter, StyledDialogTitle } from '../../../../components/Dialog.styled';

interface FormData {
  addressed_on: Dayjs | null;
  addressed_note: string | null;
  addressed_by: number | null;
};

interface Errors {
  dateError: string | null;
  noteError: string | null;
  saveError: string | null;
}

const initialErrorState = {
  dateError: null,
  noteError: null,
  saveError: null
};

const initialFromDataState = {
  addressed_on: null,
  addressed_note: null,
  addressed_by: null
}

const MarkAsAddressedModal = (props: {
  testResult: WaterlineTestResult | null;
  open: boolean;
  handleClose: (saved?: boolean) => void;
  getTestResults: () => void;
}) => {
  const { testResult, open, handleClose, getTestResults } = props;

  const currentUser = useAppSelector(selectCurrentUser);

  const [formData, setFormData] = useState<FormData>(initialFromDataState);
  const [errors, setErrors] = useState<Errors>(initialErrorState);

  useMemo(() => {
    setFormData({
      addressed_on: testResult?.addressed_on ? dayjs(testResult?.addressed_on) : null,
      addressed_note: testResult?.addressed_note ?? '',
      addressed_by: Number(testResult?.addressed_by)
    });
  }, [testResult])

  const handleDateChange = (date: Dayjs | null) => {
    setErrors({
      ...errors,
      dateError: null
    });
    setFormData({
      ...formData,
      addressed_on: date,
      addressed_by: Number(currentUser?.login_user_id)
    });
  }

  const handleNoteChange = (note: string) => {
    setErrors({
      ...errors,
      noteError: null
    });
    setFormData({
      ...formData,
      addressed_note: note,
      addressed_by: Number(currentUser?.login_user_id)
    });
  }

  const checkErrors = () => {
    let newErrors = errors;

    if(formData.addressed_on === null) {
      newErrors = {
        ...newErrors,
        dateError: 'Addressed On is required'
      };
      setErrors(newErrors);
    }
    if(formData.addressed_note === null || !formData.addressed_note.length) {
      newErrors = {
        ...newErrors,
        noteError: 'Corrective Action is required'
      }
      setErrors(newErrors);
    }
    return newErrors.dateError || newErrors.noteError;
  }

  const markAsAddressed = async () => {
    if(checkErrors()) return;

    try {
      await axios.put(`${constants.ONTRAQ_API_URL}/api/waterline_test_results/${testResult?.test_result_id}`, {
        ...testResult,
        ...formData
      });
      getTestResults();
      setErrors(initialErrorState);
      handleClose(true);
    } catch(err) {
      console.error(err);
      setErrors({
        ...errors,
        saveError: 'Failed to mark as addressed.'
      });
    }
  }

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={() => {
        setErrors(initialErrorState);
        handleClose();
      }}
    >
      <CloseModalButton handleClose={handleClose} />
      <StyledDialogTitle>
        <Check sx={{ color: '#4ddb6f', verticalAlign: 'middle', marginRight: '2px' }}/>
        Mark As Addressed
      </StyledDialogTitle>
      <StyledDialogContent>
        <Stack spacing={'16px'} marginTop={'16px'} marginBottom={'8px'}>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="en"
          >
            <DatePicker
              label="Addressed On"
              slotProps={{ textField: { size: 'small', error: errors.dateError !== null } }}
              value={dayjs(formData?.addressed_on) ?? null}
              onChange={(newDate) => handleDateChange(newDate)}
              format="MM-DD-YYYY"
            />
          </LocalizationProvider>
          {errors.dateError ? <FormHelperText error>{errors.dateError}</FormHelperText> : null}
          <FormControl fullWidth size="small">
            <TextField
              label="Corrective Action"
              error={errors.noteError !== null}
              value={formData.addressed_note}
              onChange={(event) => handleNoteChange(event?.target.value)}
              rows={4}
            />
          </FormControl>
          {errors.noteError ? <FormHelperText error>{errors.noteError}</FormHelperText> : null}
          {errors.saveError ? <FormHelperText error>{errors.saveError}</FormHelperText> : null}
        </Stack>
        </StyledDialogContent>
        <StyledDialogFooter>
          <Button onClick={() => {
            setErrors(initialErrorState);
            handleClose();
          }}>
            Cancel
          </Button>
          <Button
            variant='contained'
            onClick={markAsAddressed}
          >
            Save
          </Button>
        </StyledDialogFooter>
    </Dialog>
  );
}

export default MarkAsAddressedModal;