import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, Checkbox, LinearProgress, Skeleton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { usePromiseTracker } from 'react-promise-tracker';
import { oshaHipaaIcon } from '../../../icons';

import { selectCurrentCustomerNumber } from '../../../redux/customer';
import { useAppDispatch } from '../../../redux/store';
import { getPractices, selectPracticeComplianceCounts, selectPracticeCompliancePercentages, selectPracticeStats } from './slice';
import PracticesTable from './PracticesTable';
import { ComplianceStatus } from '../../../types';
import { ComplianceFilterOptions } from './types';
import PageHeader from '../../../components/PageHeader';


function DentalEnhancements() {
  const { promiseInProgress } = usePromiseTracker();
  const practiceComplianceCounts = useSelector(selectPracticeComplianceCounts);
  const practiceCompliancePercentages = useSelector(selectPracticeCompliancePercentages);

  const userTrainingStatus = useSelector(selectPracticeStats);
  const [complianceFilter, setComplianceFilter] = useState<ComplianceFilterOptions>('');

  const customerNumber = useSelector(selectCurrentCustomerNumber);
  const dispatch = useAppDispatch();

  const handleComplianceFilter = (filter: ComplianceFilterOptions) => {
    if (filter === complianceFilter) {
      setComplianceFilter('');
    } else {
      setComplianceFilter(filter);
    }
  };

  useEffect(() => {
    if (customerNumber) {
      dispatch(getPractices(customerNumber));
    }
  }, [customerNumber]);

  return (
    <>
      <PageHeader src={oshaHipaaIcon} headerName={'OSHA/HIPAA'} />
      <div className="m-12">
        <div className="flex flex-space-between flex-align-start m-b-16">
          <Card variant="outlined" className="m-r-25 flex-1">
            <div className="box-header">Compliance Status</div>
            <Table size="small" data-testid="compliance-status-table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: '10%' }}>Practices</TableCell>
                  <TableCell sx={{ width: '30%' }}></TableCell>
                  <TableCell sx={{ width: '40%' }}>Status</TableCell>
                  <TableCell sx={{ width: '20%' }}>Filter by</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {!promiseInProgress && (
                    <>
                      <TableCell>
                        <span className="compliance-box background-danger">{practiceComplianceCounts[ComplianceStatus.NotStarted]}</span>
                      </TableCell>
                      <TableCell>
                        <LinearProgress
                          sx={{ height: '8px' }}
                          variant="determinate"
                          color="danger"
                          value={practiceCompliancePercentages[ComplianceStatus.NotStarted]}
                        />
                      </TableCell>
                    </>
                  )}
                  {promiseInProgress && (
                    <>
                      <TableCell>
                        <Skeleton variant="rounded" width={24} height={23} />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="rectangular" height={8}></Skeleton>
                      </TableCell>
                    </>
                  )}
                  <TableCell>Action Required</TableCell>
                  <TableCell>
                    <Checkbox
                      value="not-started"
                      checked={complianceFilter === 'not-started' ? true : false}
                      onChange={() => handleComplianceFilter('not-started')}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  {!promiseInProgress && (
                    <>
                      <TableCell>
                        <span className="compliance-box background-warning">{practiceComplianceCounts[ComplianceStatus.InProgress]}</span>
                      </TableCell>
                      <TableCell>
                        <LinearProgress
                          sx={{ height: '8px' }}
                          variant="determinate"
                          color="warning"
                          value={practiceCompliancePercentages[ComplianceStatus.InProgress]}
                        />
                      </TableCell>
                    </>
                  )}
                  {promiseInProgress && (
                    <>
                      <TableCell>
                        <Skeleton variant="rounded" width={24} height={23} />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="rectangular" height={8}></Skeleton>
                      </TableCell>
                    </>
                  )}
                  <TableCell>In Progress</TableCell>
                  <TableCell>
                    <Checkbox
                      value="in-progress"
                      checked={complianceFilter === 'in-progress' ? true : false}
                      onChange={() => handleComplianceFilter('in-progress')}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  {!promiseInProgress && (
                    <>
                      <TableCell>
                        <span className="compliance-box background-success">{practiceComplianceCounts[ComplianceStatus.Complete]}</span>
                      </TableCell>
                      <TableCell>
                        <LinearProgress
                          sx={{ height: '8px' }}
                          variant="determinate"
                          color="success"
                          value={practiceCompliancePercentages[ComplianceStatus.Complete]}
                        />
                      </TableCell>
                    </>
                  )}
                  {promiseInProgress && (
                    <>
                      <TableCell>
                        <Skeleton variant="rounded" width={24} height={23} />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="rectangular" height={8}></Skeleton>
                      </TableCell>
                    </>
                  )}
                  <TableCell>Completed</TableCell>
                  <TableCell>
                    <Checkbox
                      value="complete"
                      checked={complianceFilter === 'complete' ? true : false}
                      onChange={() => handleComplianceFilter('complete')}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  {!promiseInProgress && (
                    <>
                      <TableCell>
                        <span className="compliance-box background-inactive">{practiceComplianceCounts[ComplianceStatus.NotApplicable]}</span>
                      </TableCell>
                      <TableCell>
                        <LinearProgress
                          sx={{ height: '8px' }}
                          variant="determinate"
                          color="inactive"
                          value={practiceCompliancePercentages[ComplianceStatus.NotApplicable]}
                        />
                      </TableCell>
                    </>
                  )}
                  {promiseInProgress && (
                    <>
                      <TableCell>
                        <Skeleton variant="rounded" width={24} height={23} />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="rectangular" height={8}></Skeleton>
                      </TableCell>
                    </>
                  )}
                  <TableCell>Service Inactive</TableCell>
                  <TableCell>
                    <Checkbox
                      value="not-applicable"
                      checked={complianceFilter === 'not-applicable' ? true : false}
                      onChange={() => handleComplianceFilter('not-applicable')}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Card>
          <Card variant="outlined" className="flex-1">
            <div className="box-header">Stats</div>
            <Table size="small" data-testid="stats-table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: '28%' }}>Category</TableCell>
                  <TableCell align="center">Action Required</TableCell>
                  <TableCell align="center">In Progress</TableCell>
                  <TableCell align="center">Completed</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>OSHA</TableCell>
                  {!promiseInProgress && (
                    <TableCell align="center">
                      <span className={`${userTrainingStatus?.osha?.notStarted && 'color-danger'}`}>{userTrainingStatus?.osha?.notStarted ?? 0}</span>
                    </TableCell>
                  )}
                  {!promiseInProgress && (
                    <TableCell align="center">
                      <span className={`${userTrainingStatus?.osha?.inProgress && 'color-warning'}`}>
                        {userTrainingStatus?.osha?.inProgress ?? 0}
                      </span>
                    </TableCell>
                  )}
                  {!promiseInProgress && (
                    <TableCell align="center">
                      <span className={`${userTrainingStatus?.osha?.completed && 'color-success'}`}>{userTrainingStatus?.osha?.completed ?? 0}</span>
                    </TableCell>
                  )}
                  {promiseInProgress && (
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                  )}
                  {promiseInProgress && (
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                  )}
                  {promiseInProgress && (
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                  )}
                </TableRow>
                <TableRow>
                  <TableCell>HIPAA</TableCell>
                  {!promiseInProgress && (
                    <TableCell align="center">
                      <span className={`${userTrainingStatus?.hipaa?.notStarted && 'color-danger'}`}>
                        {userTrainingStatus?.hipaa?.notStarted ?? 0}
                      </span>
                    </TableCell>
                  )}
                  {!promiseInProgress && (
                    <TableCell align="center">
                      <span className={`${userTrainingStatus?.hipaa?.inProgress && 'color-warning'}`}>
                        {userTrainingStatus?.hipaa?.inProgress ?? 0}
                      </span>
                    </TableCell>
                  )}
                  {!promiseInProgress && (
                    <TableCell align="center">
                      <span className={`${userTrainingStatus?.hipaa?.completed && 'color-success'}`}>
                        {userTrainingStatus?.hipaa?.completed ?? 0}
                      </span>
                    </TableCell>
                  )}
                  {promiseInProgress && (
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                  )}
                  {promiseInProgress && (
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                  )}
                  {promiseInProgress && (
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                  )}
                </TableRow>
                <TableRow>
                  <TableCell>Training Courses</TableCell>
                  {!promiseInProgress && (
                    <TableCell align="center">
                      <span className={`${userTrainingStatus?.trainingCourses?.notStarted && 'color-danger'}`}>
                        {userTrainingStatus?.trainingCourses?.notStarted ?? 0}
                      </span>
                    </TableCell>
                  )}
                  {!promiseInProgress && (
                    <TableCell align="center">
                      <span className={`${userTrainingStatus?.trainingCourses?.inProgress && 'color-warning'}`}>
                        {userTrainingStatus?.trainingCourses?.inProgress ?? 0}
                      </span>
                    </TableCell>
                  )}
                  {!promiseInProgress && (
                    <TableCell align="center">
                      <span className={`${userTrainingStatus?.trainingCourses?.completed && 'color-success'}`}>
                        {userTrainingStatus?.trainingCourses?.completed ?? 0}
                      </span>
                    </TableCell>
                  )}
                  {promiseInProgress && (
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                  )}
                  {promiseInProgress && (
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                  )}
                  {promiseInProgress && (
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                  )}
                </TableRow>
              </TableBody>
            </Table>
          </Card>
        </div>
        <PracticesTable complianceFilter={complianceFilter} handleComplianceFilter={handleComplianceFilter} />
      </div>
    </>
  );
}

export default DentalEnhancements;
