import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../../redux/user';
import axios from 'axios';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import Card from '@mui/material/Card';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import constants from '../../../constants';
import { standardDateFormat } from '../../../helpers/date';

function TrainingPastDue() {
  const { promiseInProgress } = usePromiseTracker();
  const currentUser = useSelector(selectCurrentUser);
  const [pastDue, setPastDue] = useState([]);

  const getPastDue = async () => {
    const customerNumber = currentUser.customer_number;

    const query = {
      include: {
        relation: 'children',
        scope: {
          order: 'name',
          where: {
            inactive: false,
          },
          include: [
            {
              relation: 'users_training',
              scope: {
                where: {
                  compliance_status: 3
                },
                order: ['user_id ASC', 'enrollment_date DESC'],
                include: [
                  {
                    relation: 'user'
                  },
                  {
                    relation: 'training'
                  }
                ]
              },
            },
          ],
        },
      },
    };

    await axios
      .get(`${constants.ONTRAQ_API_URL}/api/Customers/${customerNumber}?filter=${JSON.stringify(query)}`)
      .then((res) => {
        const customers = res.data.children;
        let pastDueUsers = [];
        customers.forEach((customer) => {
          if (customer.users_training.length > 0) {
            customer.users_training.forEach((ut) => {
              const userName = ut?.user?.firstname ? `${ut.user.firstname} ${ut.user.lastname}` : 'No Name';
              const year = ut?.training?.year;
              const row = {
                practiceName: customer.name,
                userName,
                courseName: ut?.training.name || 'Unknown',
                dueDate: standardDateFormat(ut.due_date),
                year: year ?? '',
              };
              pastDueUsers = [...pastDueUsers, row];
            });

            pastDueUsers = pastDueUsers.sort((a, b) => a.dueDate < b.dueDate ? -1 : a.dueDate > b.dueDate ? 1 : 0);
          }
        });

        setPastDue(pastDueUsers);
      })
      .catch((error) => {
        console.error(error);
        return false;
      });
  };

  useEffect(() => {
    trackPromise(getPastDue());
  }, [currentUser]);

  return (
    <Card variant="outlined">
      <div className="box-header">
        {!promiseInProgress && (
          <>{pastDue.length} Result{pastDue.length > 1 ? 's' : ''}</>
        )}
        {promiseInProgress && (
          <Skeleton variant="text" width={80} />
        )}
      </div>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{}}>Practice Name</TableCell>
            <TableCell sx={{}}>Name</TableCell>
            <TableCell sx={{}}>Year</TableCell>
            <TableCell sx={{}}>Course Name</TableCell>
            <TableCell sx={{}}>Due Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!promiseInProgress && pastDue.map((row, index) => {
            return (
              <TableRow key={index}>
                <TableCell>{row.practiceName}</TableCell>
                <TableCell>{row.userName}</TableCell>
                <TableCell>{row.year}</TableCell>
                <TableCell>{row.courseName}</TableCell>
                <TableCell>{row.dueDate}</TableCell>
              </TableRow>
            );
          })}
          {promiseInProgress && (
            [...Array(10)].map((a, index) => {
              return (
                <TableRow key={index}>
                  <TableCell><Skeleton /></TableCell>
                  <TableCell><Skeleton /></TableCell>
                  <TableCell><Skeleton /></TableCell>
                  <TableCell><Skeleton /></TableCell>
                  <TableCell><Skeleton /></TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
      {!promiseInProgress && pastDue.length === 0 && (
        <div className='p-16 center'>
          There are no past due courses
        </div>
      )}
    </Card>
  );
}

export default TrainingPastDue;
