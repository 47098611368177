import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import * as R from 'remeda';
import { match, P } from 'ts-pattern';

import { getCorpChildren } from './services';
import { ChildCustomerDetails, ComplianceStatus } from '../../../types';
import { RootState } from '../../../redux/store';
import { PracticeCompliance, PracticeWithCompliance } from './types';

type LoadingState = 'not-asked' | 'loading' | 'done' | 'error';

interface State {
  practices: ChildCustomerDetails[];
  practiceCompliance: Record<string, PracticeCompliance>;
  loadingState: LoadingState;
}

const initialState: State = {
  practices: [],
  loadingState: 'not-asked',
  practiceCompliance: {}
};

const SLICE_PREFIX = 'corpPracticeDE';

export const getPractices = createAsyncThunk(`${SLICE_PREFIX}/getPractices`, async (customerNumber: string) => {
  return await getCorpChildren(customerNumber);
});

const mapCompliance = (practice: ChildCustomerDetails): PracticeCompliance => {
  const metadata = practice?.customer_osha_hipaa_metadata;

  if (!metadata?.length) {
    return {
      osha_compliance_status: ComplianceStatus.NotStarted,
      hipaa_compliance_status: ComplianceStatus.NotStarted,
      training_compliance_status: ComplianceStatus.NotStarted,
      percentage_complete: 0,
      overall_compliance_status: ComplianceStatus.NotStarted
    };
  }

  const latestMetadata = metadata.sort((a, b) => b.year - a.year)[0];

  const {
    osha_compliance_status,
    hipaa_compliance_status,
    training_compliance_status,
    percentage_complete,
    compliance: { compliance_status: overall_compliance_status }
  } = latestMetadata;

  return {
    osha_compliance_status,
    hipaa_compliance_status,
    training_compliance_status,
    percentage_complete,
    overall_compliance_status
  };
};

export const slice = createSlice({
  name: SLICE_PREFIX,
  initialState,
  reducers: {
    clearPractices: () => {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder.addCase(getPractices.pending, (state) => {
      state.loadingState = 'loading';
    });

    builder.addCase(getPractices.fulfilled, (state, { payload }) => {
      state.practices = payload;

      state.practiceCompliance = R.mapValues(R.indexBy(state.practices, R.prop('customer_number')), mapCompliance);

      state.loadingState = 'done';
    });

    builder.addCase(getPractices.rejected, (state) => {
      state.loadingState = 'error';
    });
  }
});

export const selectPractices = (state: RootState) => state.corpPracticeDE.practices;

const selectPracticeCompliance = (state: RootState) => state.corpPracticeDE.practiceCompliance;

export const selectPracticeStats = createSelector(selectPracticeCompliance, (compliance) =>
  Object.values(compliance).reduce(
    (stats, c) => {
      match(c.hipaa_compliance_status)
        .with(P.union(ComplianceStatus.Complete), () => (stats.hipaa.completed = stats.hipaa.completed + 1))
        .with(ComplianceStatus.NotStarted, () => (stats.hipaa.notStarted = stats.hipaa.notStarted + 1))
        .with(ComplianceStatus.InProgress, () => (stats.hipaa.inProgress = stats.hipaa.inProgress + 1));

      match(c.osha_compliance_status)
        .with(P.union(ComplianceStatus.Complete), () => (stats.osha.completed = stats.osha.completed + 1))
        .with(ComplianceStatus.NotStarted, () => (stats.osha.notStarted = stats.osha.notStarted + 1))
        .with(ComplianceStatus.InProgress, () => (stats.osha.inProgress = stats.osha.inProgress + 1));

      match(c.training_compliance_status)
        .with(P.union(ComplianceStatus.Complete), () => (stats.trainingCourses.completed = stats.trainingCourses.completed + 1))
        .with(ComplianceStatus.NotStarted, () => (stats.trainingCourses.notStarted = stats.trainingCourses.notStarted + 1))
        .with(ComplianceStatus.InProgress, () => (stats.trainingCourses.inProgress = stats.trainingCourses.inProgress + 1));

      return stats;
    },
    {
      osha: {
        notStarted: 0,
        completed: 0,
        inProgress: 0
      },
      hipaa: {
        notStarted: 0,
        completed: 0,
        inProgress: 0
      },
      trainingCourses: {
        notStarted: 0,
        completed: 0,
        inProgress: 0
      }
    }
  )
);

export const selectPracticeComplianceCounts = createSelector(
  [selectPracticeCompliance],
  (compliance): Record<ComplianceStatus, number> =>
    Object.values(compliance).reduce(
      (totalsMap, c) => ({
        ...totalsMap,
        [c.overall_compliance_status]: totalsMap[c.overall_compliance_status] + 1
      }),
      {
        [ComplianceStatus.Complete]: 0,
        [ComplianceStatus.InProgress]: 0,
        [ComplianceStatus.NotApplicable]: 0,
        [ComplianceStatus.NotStarted]: 0
      }
    )
);

export const selectPracticeCompliancePercentages = createSelector(
  [selectPractices, selectPracticeComplianceCounts],
  (practices, counts): Record<ComplianceStatus, number> => {
    const totalPractices = practices.length;

    return {
      [ComplianceStatus.Complete]: (counts[ComplianceStatus.Complete] / totalPractices) * 100,
      [ComplianceStatus.InProgress]: (counts[ComplianceStatus.InProgress] / totalPractices) * 100,
      [ComplianceStatus.NotApplicable]: (counts[ComplianceStatus.NotApplicable] / totalPractices) * 100,
      [ComplianceStatus.NotStarted]: (counts[ComplianceStatus.NotStarted] / totalPractices) * 100
    };
  }
);

export const selectPracticesWithCompliance = createSelector(
  [selectPractices, selectPracticeCompliance],
  (practices, compliance): PracticeWithCompliance[] =>
    practices.map((p) => ({
      ...p,
      ...compliance[p.customer_number]
    }))
);

export const selectLoadingState = (state: RootState) => state.corpPracticeDE.loadingState;

export default slice.reducer;
