import { DialogActions, DialogContent, DialogTitle, styled } from '@mui/material';

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  color: theme.palette.color50555b.main,
  fontSize: '18px',
  padding: '12px',
  borderBottom: `1px solid #e5e5e5`
}));

export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  color: theme.palette.color50555b.main,
  padding: '12px !important'
}))

export const StyledDialogFooter = styled(DialogActions)(({ theme }) => ({
  borderTop: `1px solid #e5e5e5`,
  color: theme.palette.color50555b.main,
  padding: '12px !important'
}))