import { useState, useEffect, ChangeEvent, FormEvent } from "react"
import axios from "axios"
import { Box, useTheme, DialogTitle, DialogContent, Dialog, DialogActions, Button } from "@mui/material"
import PageHeader from "../../components/PageHeader"
import { useAppSelector } from "../../redux/store"
import { getEPayLink } from "../../services/epayService"
import { selectCustomerNumber, selectPracticeDetails } from "../../redux/customer"
import { UserAccessLevels, selectCurrentUser } from "../../redux/user"
import constants from "../../constants"
import { ErrorNotify } from "../../components/Notify.styled"

const MessageSubmitDialog = (props: { open: boolean, handleClose: () => void }) => {
  const {
    open,
    handleClose,
  } = props

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Message Sent</DialogTitle>
      <DialogContent>
        Your request has been sent to our customer support team. You will be contacted shortly.
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>Ok</Button>
      </DialogActions>
    </Dialog>
  )
}

const Contact = () => {
  const theme = useTheme()

  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [contactByEmail, setContactByEmail] = useState(true)
  const [contactByPhone, setContactByPhone] = useState(false)
  const [message, setMessage] = useState('')
  const [disableSubmit, setDisableSubmit] = useState(false)
  const [formError, setFormError] = useState(false)
  const [submitError, setSubmitError] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [alertShow, setAlertShow] = useState(false)

  const currentUser = useAppSelector(selectCurrentUser)
  const customerNumber = useAppSelector(selectCustomerNumber)
  const practiceDetails = useAppSelector(selectPracticeDetails)
  
  const defaultEmail = currentUser?.email ?? ''
  const defaultPhone = practiceDetails?.phone ?? ''

  const hasPermission = (permissions: UserAccessLevels[]): boolean => permissions.some((perm) => perm === currentUser?.access_level)
  
  useEffect(() => {
    if (defaultEmail) setEmail(defaultEmail)
  }, [defaultEmail])

  useEffect(() => {
    if (defaultPhone) setPhone(defaultPhone)
  }, [defaultPhone])

  const openLinkToEPayPayments = async (): Promise<void> => {
    try {
      const link = await getEPayLink(customerNumber, 'payments')
      window.open(link, '_blank')
    } catch {
      setAlertShow(true)
    }
  }

  const handleOpenChat = () => {
    if (parent.LC_API?.open_chat_window) {
      parent.LC_API.open_chat_window()
    }
  }

  const handleEmail = (event: ChangeEvent<HTMLInputElement>) => {
    const email = event.target.value
    setEmail(email)
  }

  const handlePhone = (event: ChangeEvent<HTMLInputElement>) => {
    const phone = event.target.value
    setPhone(phone)
  }

  const handleContactByEmail = () => {
    setContactByEmail(!contactByEmail)
  }

  const handleContactByPhone = () => {
    setContactByPhone(!contactByPhone)
  }

  const handleMessage = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const message = event.target.value
    if (message) setFormError(false)
    setMessage(message)
  }

  const handleClose = () => {
    setOpenDialog(false)
  }

  const handleAlertDismiss = () => setAlertShow(false)

  const resetForm = () => {
    setEmail(defaultEmail)
    setPhone(defaultPhone)
    setContactByEmail(true)
    setContactByPhone(false)
    setMessage('')
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    setSubmitError(false)

    if (message) {
      setDisableSubmit(true)

      const emailDetails = {
        customer_number: customerNumber,
        notificationName: 'contactUs',
        firstname: currentUser?.firstname,
        lastname: currentUser?.lastname,
        userPhone: phone,
        userEmail: email,
        contactOptions: {
          phone: contactByPhone,
          email: contactByEmail,
        },
        message,
      }
  
      try {
        const notificationUrl = `${constants.ONTRAQ_API_URL}/api/notifications/sendEmail`
        await axios.post(notificationUrl, { emailDetails })
  
        resetForm()
        setDisableSubmit(false)
        setOpenDialog(true)
      } catch (err) {
        setSubmitError(true)
        setDisableSubmit(false)
      }
    } else {
      setFormError(true)
    }
  }

  return (
    <>
      <PageHeader iconName="phone" headerName="Contact Us" />
      <Box sx={{color: theme.palette.color50555b.main}}  className="m-16">
        <p className="m-l-16 m-b-0">
          We are here to answer any questions you may have about HealthFirst or OnTraq.
          <br/> Reach out to us and we’ll respond as soon as we can.
        </p>
        <div className="contact-us flex m-l-16">
          <div className="contact-us-section item">
            <h2 className="m-b-8 f-s-26 f-w-500">Customer Service</h2>
              <div className="m-b-8 f-s-13">
                Call
                <span className="text-info text-u-l m-l-8">{constants.HEALTHFIRST_PHONE_NUMBER}</span>
                <br/>
                <span>Monday-Friday 9:00AM to 8PM (EST)</span>
              </div>
              <div className="m-b-8 f-s-13">
                Fax
                <span className="text-info text-u-l m-l-8">{constants.HEALTHFIRST_FAX_NUMBER}</span>
              </div>
              <div className="m-b-8 f-s-13">
                Email
                <span className="text-info text-u-l m-l-8">{constants.HEALTHFIRST_EMAIL}</span>
              </div>
              <div className="m-b-8 f-s-13">
                Chat with us
                <span className="link text-u-l m-l-8" onClick={handleOpenChat}>Click Here</span>
                <br/>
                <span>Monday – Friday 10:30AM to 7:30PM (EST)</span>
              </div>
              <div className="m-b-8 f-s-13">
                Mailing Address
                <address>
                  {constants.HEALTHFIRST_COMPANY_NAME}
                  <br/> 11629 49th PL W
                  <br/> Mukilteo, WA 98275
                </address>
              </div>            
          </div>
          <div className="contact-us-section item">
            <h2 className="m-b-8 f-s-26 f-w-500">Payment</h2>
            {
              hasPermission([UserAccessLevels.Admin, UserAccessLevels.Owner]) && (
                <div className="m-b-8 f-s-13">
                  Online
                  <span className="link text-u-l m-l-8" onClick={openLinkToEPayPayments}>
                    Click Here
                  </span>
                </div>
              )
            }
            <div className="m-b-8 f-s-13">
              By phone
              <span className="text-info text-u-l m-l-8">{constants.HEALTHFIRST_PHONE_NUMBER}</span>
            </div>
            <div className="m-b-8 f-s-13">
              <span>By mail</span>
              <br/>
              <address className="">
                Dept CH 14330
                <br/>
                Palatine, IL 60055-433
              </address>
            </div>
          </div>
          <div className="contact-us-section blank-row"></div>
          <div className="contact-us-section item">
            <form role="form" name="contactForm" onSubmit={handleSubmit}>
              {
                submitError && (
                  <span className="contact-form-submit-error f-s-13">
                    An error has occured while processing your request.
                    <br/> Please try again later.
                  </span>
                )
              }
              <div className="m-b-8">
                <h2 className="m-b-8 f-s-26 f-w-500">Send us a message</h2>
                <div className="m-b-8">
                  <label>
                    <strong className="f-s-13">Email</strong>
                  </label>
                  <input type="text" placeholder="Email" className="form-control m-t-4" value={email} onChange={handleEmail} aria-required="true" aria-invalid="true"/>
                </div>
                <div className="m-b-8">
                  <label>
                    <strong className="f-s-13">Phone</strong>
                  </label>
                  <input type="text" placeholder="Phone" className="form-control m-t-4" value={phone} onChange={handlePhone} aria-required="true" aria-invalid="true"/>
                </div>
                <div className="m-b-8">
                  <label>
                    <strong className="f-s-13">Contact me by</strong>
                  </label>
                  <br/>
                  <div className="m-t-4">
                    <label>
                      <input checked={contactByEmail} onChange={handleContactByEmail} type="checkbox"/>
                      Email
                    </label>
                    <label className="m-l-8">
                      <input checked={contactByPhone} onChange={handleContactByPhone} type="checkbox"/>
                      Phone
                    </label>
                  </div>
                </div>
                <div>
                  <label>
                    <strong className="f-s-13">Message</strong>
                  </label>
                  <textarea rows={4} placeholder="Type your message" className={`form-control m-t-4 ${formError ? 'contact-form-message-error' : ''}`}  value={message} onChange={handleMessage}  /*ng-className="{'textbox-error': formError}"*/></textarea>
                </div>
              </div>
              <footer>
                <Button variant="contained" type="submit" size="small" disabled={disableSubmit}>
                  Submit
                </Button>
              </footer>
            </form>
          </div>
        </div>
      </Box>
      <MessageSubmitDialog open={openDialog} handleClose={handleClose} />
      <ErrorNotify open={alertShow} onClose={() => handleAlertDismiss()} />
    </>
  )
}

export default Contact
