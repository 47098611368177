import axios from 'axios';
import { Button, Dialog } from '@mui/material';
import CloseModalButton from '../../../components/CloseModalButton';
import { StyledDialogContent, StyledDialogFooter, StyledDialogTitle } from '../../../components/Dialog.styled';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { Room } from './types';
import constants from '../../../constants';
import { useState } from 'react';

interface RoomModalProps {
  onClose: (refresh?: boolean, refreshAll?: boolean) => void;
  onAlert: (message: string) => void;
  isOpen: boolean;
  room?: Room;
}

const DeleteRoomModal: React.FC<RoomModalProps> = ({ onClose, onAlert, isOpen, room }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async () => {
    setLoading(true);

    try {
      await axios.delete(`${constants.ONTRAQ_API_URL}/api/WaterlineRooms/deleteRoom/${room?.room_id}`);
      onClose(false, true);
    } catch (error) {
      const message = 'There was an error deleting the room';
      console.error(message);
      onAlert(message);
      onClose(false);
    }

    setLoading(false);
  };

  return (
    <Dialog open={isOpen} onClose={() => onClose(false)} fullWidth>
      <CloseModalButton handleClose={() => onClose(false)} />
      <StyledDialogTitle>Delete Room/Chair</StyledDialogTitle>
      <StyledDialogContent>
        <div className="flex flex-center m-b-16">
          <DeleteIcon fontSize="large" />
        </div>
        <div className="m-b-16 center">
          Deleting an existing room/chair will lead to unassigning the test results from that room/chair if already assigned. Are you sure you delete
          the existing room/chair?
        </div>
        <div className="bold">Room/Chair: {room?.name}</div>
      </StyledDialogContent>
      <StyledDialogFooter>
        <Button onClick={() => onClose(false)}>Cancel</Button>
        <Button variant="contained" onClick={handleSubmit} disabled={loading}>
          Delete
        </Button>
      </StyledDialogFooter>
    </Dialog>
  );
};

export default DeleteRoomModal;
