import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { KeyboardArrowDown, Check, Edit, Settings } from '@mui/icons-material';
import { WaterlineTestResult } from '../types';
import { Box } from '@mui/material';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: 'rgb(55, 65, 81)',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
    ...theme.applyStyles('dark', {
      color: theme.palette.grey[300],
    }),
  },
}));

const MarkAsAddressedButton = (props: {
  testResult: WaterlineTestResult,
  markAsAddressed: (testResult: WaterlineTestResult) => void
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { testResult, markAsAddressed } = props;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickMenuItem = () => {
    markAsAddressed(testResult);
    handleClose();
  }

  return (
    <Box>
      <Button
        sx={{
          backgroundColor: '#fff',
          border: '1px solid #dee5e7',
          color: 'black',
          width: '45px',
          height: '25px',
          padding: '1px 5px',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          }
        }}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDown sx={{ width: '13px' }}/>}
      >
        <Settings fontSize="small" sx={{ width: '13px' }}/>
      </Button>
      <StyledMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {
          testResult.addressed_on ?
            <MenuItem onClick={handleClickMenuItem} disableRipple>
              <Edit />
              Edit Notes
            </MenuItem>
          :
            <MenuItem onClick={handleClickMenuItem} disableRipple>
              <Check
                sx={{
                  color: '#2dc7ac'
                }}
              />
              Mark As Addressed
            </MenuItem>
        }
      </StyledMenu>
    </Box>
  );
}

export default MarkAsAddressedButton;