import { RefItem } from "../../../types";

export interface Room {
  room_id: number;
  customer_number: string;
  name: string;
  description: string;
  created_date: string;
  deleted_date: string;
}

export interface NonKitItem {
  nonkit_item_id: number;
  customer_number: string;
  item_number: string;
  invoice_number: string;
  shipped_date: string;
  is_tbr: number;
  active: boolean;
  status: number;
  used_date: string;
  quantity: number;
  category: string;
  ref_item: RefItem;
  waterline_inventory: WaterlineItem[];
}

export interface WaterlineItem {
  id: number;
  customer_number: string;
  nonkit_item_id: number;
  room_id: number | null;
  active: boolean;
  status: number;
  activated_date: string | null;
  created_date: string;
  name?: string;
  subcategory?: string;
  item_number?: string;
  shipped_date?: string;
  invoice_number?: string;
  is_tbr?: string;
  room?: Room;
}

export interface WaterlineTestResult {
  test_result_id: number;
  hpc_method: string;
  product: string;
  cfu: string;
  customer_number: string;
  test_operator: string;
  is_pass: boolean;
  test_date: string;
  result_description: string;
  location: string;
  sample_number: number;
  retest: boolean;
  addressed_on: string;
  addressed_by: string;
  addressed_note: string;
  pH: string;
  TDS: string;
  next_steps: string;
  receive_date: string;
  report_date: string;
  room_id: number | null;
  tested_by: number | null;
  in_office_test: boolean;
  room?: Room;
}

export type DisplayResultsBy = 'roomChair' | 'testDate';

export const InventoryDate = {
  'Water Testing': 'Test Date',
  'Water Treatment': 'Start Date',
  'Water Straws': 'Install Date',
}