import React from "react";
import DownloadFormLink from "./DownloadFormLink";
import { getTrainingForm } from "../../services";
import { useAppSelector } from "../../../../../redux/store";
import { selectCurrentCustomerNumber } from "../../../../../redux/customer";
import { TrainingProgramForm } from "../../types";
import { IconButton } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { TeamMember } from "../../../../../types";

interface UploadedFormPropTypes {
  trainingProgramForm: TrainingProgramForm;
  isAdminOrOwner: boolean;
  handleDeleteClick: (id: number) => void;
  searchValue: string;
  teamMemberIndex: {[Identifier: string]: TeamMember};
}

const UploadedForm = (props: UploadedFormPropTypes) => {
  const {
    trainingProgramForm,
    isAdminOrOwner,
    handleDeleteClick,
    searchValue,
    teamMemberIndex,
  } = props;

  const customerNumber = useAppSelector(selectCurrentCustomerNumber);
  
  const teamMember = trainingProgramForm?.user_id
    ? teamMemberIndex[trainingProgramForm.user_id]
    : null;

  const downloadLink = (
    <DownloadFormLink
      searchValue={searchValue}
      customerNumber={customerNumber}
      getFileHandler={getTrainingForm}
      item={trainingProgramForm}
      teamMember={teamMember}
    />
  );

  return (
    <React.Fragment>
      { trainingProgramForm.file_id ? downloadLink : '' }
      {isAdminOrOwner && trainingProgramForm.file_id ?
        <IconButton
          aria-label="delete"
          sx={{
            color: '#50555b',
            height: '20px',
            width: '20px',
            marginLeft: '5px'
          }}
          onClick={() => handleDeleteClick(trainingProgramForm.id)}
        >
          <Delete fontSize="small"/>
        </IconButton>
      : null}
    </React.Fragment>
  )
}

export default UploadedForm